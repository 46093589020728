import {
  Flex,
  Image,
  useBreakpointValue,
  useOutsideClick,
  useTheme,
  Link as ChakraLink,
  IconButton,
  Text,
  Box,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import sirdabLogo from 'assets/images/Sirdab_Logo-01.png';
import { useRef } from 'react';

import styled from '@emotion/styled';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as SidebarIcon } from 'assets/icons/sidebar.svg';
import { ReactComponent as ReferIcon } from 'assets/icons/refer.svg';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/shared/Tooltip';
import { getLocalStorage } from 'utils/localStorage';

const navActiveStyle = {
  bg: '#F1F5F8',
  color: 'primary.500',
  fontWeight: '600',
};

const NavItem = ({
  icon: Icon,
  children,
  path,
  isOpen,
  isActive,
  isStrokeIcon,
  target,
  ...rest
}) => {
  const theme = useTheme();
  const primaryColor = theme.colors.primary[500];

  const ActiveIcon =
    Icon &&
    styled(Icon)`
      path {
        stroke: ${isStrokeIcon ? primaryColor : ''};
        fill: ${isStrokeIcon ? '' : primaryColor};
      }
    `;
  const NavItemContent = (
    <Flex
      align="center"
      padding={3}
      gap={isOpen ? 4 : 0}
      borderRadius="xl"
      role="group"
      cursor="pointer"
      color="gray.600"
      fontWeight="500"
      width="full"
      _hover={navActiveStyle}
      sx={isActive ? navActiveStyle : undefined}
      {...rest}
    >
      {Icon && <Box>{isActive ? <ActiveIcon /> : <Icon />}</Box>}

      <Text hidden={!isOpen} noOfLines={1} textTransform="capitalize">
        {children}
      </Text>
    </Flex>
  );

  return (
    <ChakraLink
      as={ReactRouterLink}
      to={path}
      _hover={{ textDecoration: 'none' }}
      target={target}
    >
      {isOpen ? (
        NavItemContent
      ) : (
        <Tooltip label={children} placement="left">
          {NavItemContent}
        </Tooltip>
      )}
    </ChakraLink>
  );
};

const Sidebar = ({
  size,
  navbarHeight,
  isOpen,
  onClose,
  onToggle,
  linkItems,
  activeLink,
  ...rest
}) => {
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const user = getLocalStorage('user') ?? {};

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const clickOutsideHandler = useBreakpointValue({
    md: undefined,
    base: onClose,
  });

  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: clickOutsideHandler,
  });

  const handleSignOut = () => {
    signOut();
  };

  const topLinkItems = linkItems.filter((item) => !item.bottom);
  const bottomLinkItems = linkItems.filter((item) => item.bottom);

  return (
    <Flex
      bg="white"
      w={isOpen ? size : 20}
      transition=".2s"
      h="full"
      direction="column"
      position={{ base: 'fixed', md: 'static' }}
      display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
      zIndex={3}
      px={4}
      ref={ref}
      {...rest}
    >
      <Flex
        height={navbarHeight}
        alignItems="center"
        justifyContent={isOpen ? 'space-between' : 'center'}
        borderBottom="1px"
        borderBottomColor="gray.200"
        flexShrink="0"
      >
        <Image
          src={sirdabLogo}
          alt="Sirdab Logo"
          height={{ base: '25px', md: '30px' }}
          hidden={!isOpen}
        />

        <IconButton
          size="lg"
          onClick={onToggle}
          icon={<SidebarIcon />}
          variant="ghost"
        />
      </Flex>

      <Flex
        direction="column"
        flex="1"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        gap="3"
        overflowY="auto"
      >
        <Flex gap="1" direction="column" w="full">
          {topLinkItems.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              isOpen={isOpen}
              isActive={link.name === activeLink?.name}
              isStrokeIcon={link.isStrokeIcon}
              path={link.path}
              onClick={isMobile ? onClose : undefined}
            >
              {t(link.name)}
            </NavItem>
          ))}
        </Flex>
        <Flex gap="1" direction="column" w="full">
          <NavItem
            target="_blank"
            path={`https://airtable.com/app4pShtVmQecBM59/shrbTN8Y2N74PqnTj?prefill_Name=${user.name}`}
            icon={ReferIcon}
            isOpen={isOpen}
            fontSize="xs"
            bg="primary.100"
            color="primary.500"
            _hover={{
              bg: 'primary.100',
              color: 'primary.500',
            }}
          >
            {t('referAndGet300Sar')}
          </NavItem>
          {bottomLinkItems.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              isOpen={isOpen}
              isActive={link.name === activeLink?.name}
              isStrokeIcon={link.isStrokeIcon}
              path={link.path}
              onClick={isMobile ? onClose : undefined}
            >
              {t(link.name)}
            </NavItem>
          ))}
          <NavItem
            as="button"
            icon={Logout}
            onClick={handleSignOut}
            isOpen={isOpen}
          >
            {t('logout')}
          </NavItem>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
