import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';

import { Link } from 'react-router-dom';

const BackToListButton = ({ title, url, ...rest }) => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  return (
    <Button
      textTransform="capitalize"
      bg="white"
      colorScheme="gray"
      color="gray.600"
      leftIcon={
        isRTL ? (
          <ChevronRightIcon boxSize="24px" />
        ) : (
          <ChevronLeftIcon boxSize="24px" />
        )
      }
      size="lg"
      as={url && Link}
      to={url}
      {...rest}
    >
      {title}
    </Button>
  );
};

export default BackToListButton;
