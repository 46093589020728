import {
  Flex,
  SimpleGrid,
  Text,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const OrderStatusDetails = ({ statuses, activeStatus, statusColor }) => {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const activeStatusIndex = statuses.findIndex(
    ({ value }) => value === activeStatus
  );

  const viewStatuses = isMobile
    ? statuses.filter(({ value }) => value === activeStatus)
    : statuses;

  return (
    <SimpleGrid
      columns={isMobile ? 1 : viewStatuses.length}
      spacing={2}
      spacingY={4}
    >
      {viewStatuses.map(({ label, value }, index) => {
        const color =
          statusColor ??
          (activeStatusIndex === statuses.length - 1 ||
          index < activeStatusIndex
            ? 'primary.500'
            : activeStatus === value
            ? 'yellow.600'
            : 'gray.500');

        return (
          <Flex key={index} gap={3} flexDirection="column">
            <Text
              color={color}
              fontSize="sm"
              fontWeight={600}
              textTransform="capitalize"
              noOfLines={1}
            >
              {t(label)}
            </Text>
            <Divider
              width="full"
              height={2}
              orientation="horizontal"
              border="none"
              background={color === 'gray.500' ? 'gray.400' : color}
              borderRadius="full"
              opacity={1}
            />
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};

export default OrderStatusDetails;
