import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, FormErrorMessage, FormControl } from '@chakra-ui/react';
import Input from 'components/shared/Inputs/Input';
import { CheckIcon } from '@chakra-ui/icons';
import { validateCR, validateVAT } from 'utils/validation';

const VerifyBusiness = ({
  onChange,
  onSubmit,
  defaultValues,
  error,
  isVerified,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!onChange) {
      return;
    }

    const subscription = watch((data, { name, type }) => {
      if (name === undefined) {
        return;
      }

      onChange(name, data[name]);
    });

    return () => subscription.unsubscribe();
  }, [defaultValues, onChange, watch]);

  return (
    <form
      id="VerifyBusinessForm"
      className="space-y-4"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Stack spacing={4}>
        <Input
          name="cr_number"
          label={t('crNumber')}
          type="number"
          placeholder={`${t('e.g')} 1015985050`}
          register={register}
          defaultValue={defaultValues.cr_number}
          errors={errors}
          validate={validateCR}
          required="thisFieldIsRequired"
          size="lg"
        />

        <Input
          name="vat_number"
          label={t('vatNumber')}
          type="number"
          placeholder={`${t('e.g')} 9588045890345984`}
          register={register}
          defaultValue={defaultValues.vat_number}
          errors={errors}
          validate={validateVAT}
          required="thisFieldIsRequired"
          size="lg"
        />
      </Stack>

      <FormControl isInvalid={error}>
        <FormErrorMessage marginTop={3}>{error}</FormErrorMessage>
      </FormControl>

      {isVerified && <CheckIcon color="green" boxSize={6} />}
    </form>
  );
};

export default VerifyBusiness;
