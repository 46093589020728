export const getLocalStorage = (name) => {
  const value = localStorage.getItem(name);

  if (value === 'undefined' || value === 'null') {
    return;
  }

  return JSON.parse(value);
};

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const deleteLocalStorage = (name) => {
  localStorage.removeItem(name);
};
