import { SimpleGrid } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Dashboard/Select';
import { getCountries, getCities } from 'api/Dashboard/addresses';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import useLanguage from 'hooks/useLanguage';
import { getFormSelectDefaultValue } from 'utils/select';

const CountryCitySelect = ({
  control,
  watch,
  resetField,
  isUpdate = false,
  errors,
  isSingleColumn = false,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const watchCountryId = watch('country_id');

  const { data: countriesData, isLoading: isCountriesLoading } = useQuery(
    ['countries'],
    () => getCountries('')
  );

  const { data: citiesData, isLoading: isCitiesLoading } = useQuery(
    ['cities', watchCountryId],
    () => getCities('', watchCountryId),
    {
      enabled: !!watchCountryId,
    }
  );

  const cityOptions = useMemo(
    () =>
      citiesData?.data?.map(({ id, name, nameLocale }) => ({
        label: isAr ? nameLocale : name,
        value: id,
      })) ?? [],
    [citiesData?.data, isAr]
  );

  const countryOptions = useMemo(
    () =>
      countriesData?.data?.map(({ id, name, nameLocale }) => ({
        label: isAr ? nameLocale : name,
        value: id,
      })) ?? [],
    [countriesData?.data, isAr]
  );

  return (
    <SimpleGrid columns={{ base: 1, md: isSingleColumn ? 1 : 2 }} gap={4}>
      <Controller
        control={control}
        name="country_id"
        rules={{ required: 'thisFieldIsRequired' }}
        render={({ field: { onChange, value } }) => (
          <Select
            label={t('country')}
            placeholder={t('selectCountry')}
            options={countryOptions}
            onChange={({ value }) => {
              onChange(value);
              resetField('city_id');
            }}
            value={getFormSelectDefaultValue(value, countryOptions) ?? ''}
            error={errors?.country_id}
            size="lg"
            isDisabled={isUpdate}
            isLoading={isCountriesLoading}
            isClearable={false}
            required
          />
        )}
      />

      <Controller
        control={control}
        name="city_id"
        rules={{ required: 'thisFieldIsRequired' }}
        render={({ field: { onChange, value } }) => (
          <Select
            label={t('city')}
            placeholder={t('selectCity')}
            options={cityOptions}
            onChange={({ value }) => onChange(value)}
            value={getFormSelectDefaultValue(value, cityOptions) ?? ''}
            error={errors?.city_id}
            size="lg"
            isDisabled={!watchCountryId || isUpdate}
            isLoading={isCitiesLoading}
            required
          />
        )}
      />
    </SimpleGrid>
  );
};

export default CountryCitySelect;
