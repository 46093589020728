import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Select from '../Select';
import { AddIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { skuQuantityValidation } from 'constants/validation';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { packagingEnum } from 'constants/dashboard';
import { getSKUs } from 'api/Dashboard/skus';
import AsyncSelect from '../AsyncSelect';
import { useQueryClient } from 'react-query';

const skuDefaultValue = {
  sku_id: undefined,
  packaging: undefined,
  quantity: undefined,
};

const SKUsTableForm = ({
  onSubmit,
  defaultValue = skuDefaultValue,
  outboundId,
  hideExpiry,
  backendErrors = [],
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const skuFieldsName = 'skus';

  const isOutbound = !!outboundId;
  const withExpiryDate = !isOutbound && !hideExpiry;

  const {
    formState: { errors },
    trigger,
    control,
    getValues,
    register,
    watch,
  } = useForm({
    defaultValues: {
      skus: [{ ...defaultValue }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: skuFieldsName,
  });

  const fieldErrors = errors[skuFieldsName];

  const currentValues = watch('skus');

  return (
    <>
      {fields.length
        ? backendErrors.map((error) => (
            <Text color="red.500" fontSize="sm" mb={4} key={error.detail}>
              {error.detail}
            </Text>
          ))
        : null}

      <Stack spacing={4}>
        {fields.map((field, index) => {
          const error = fieldErrors?.[index];

          const fieldValue = currentValues[index];

          const skuOptionsData = queryClient.getQueriesData(['skus'])[0]?.[1]
            ?.data;

          const selectedSKUId = fieldValue.sku_id;
          const selectedSKU = skuOptionsData?.find(
            (sku) => sku.id === selectedSKUId
          );

          const selectedPackaging = fieldValue.packaging;

          const quantityMax = {
            [packagingEnum.box]: selectedSKU?.on_hand?.boxes,
            [packagingEnum.unit]: selectedSKU?.on_hand?.units,
            [packagingEnum.pallet]: selectedSKU?.on_hand?.pallets,
          };

          const fieldQuantityMax = isOutbound
            ? quantityMax[selectedPackaging]
            : Number.MAX_SAFE_INTEGER;

          const packagingOptions = Object.keys(packagingEnum).map((key) => {
            const value = packagingEnum[key];
            const packagingMax = quantityMax[value];

            return {
              label: (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width="full"
                  gap={2}
                >
                  <Text as="span">{t(key)}</Text>
                  {!isNaN(packagingMax) && isOutbound && (
                    <Text as="span" fontSize="xs">
                      {`${packagingMax} ${t('available')}`}
                    </Text>
                  )}
                </Flex>
              ),
              value,
            };
          });

          return (
            <Grid
              templateColumns={`repeat(${withExpiryDate ? 9 : 7}, 1fr)`}
              gap={5}
              key={field.id}
            >
              <GridItem colSpan={{ base: withExpiryDate ? 9 : 7, md: 2 }}>
                <Controller
                  control={control}
                  name={`${skuFieldsName}.${index}.sku_id`}
                  rules={{ required: 'thisFieldIsRequired' }}
                  render={({ field: { onChange, value } }) => (
                    <AsyncSelect
                      performSearchRequest={async (urlSearchParams) =>
                        await getSKUs(
                          `${urlSearchParams}&q[s]=created_at+desc${
                            isOutbound
                              ? `&in_inventory=true&outbound_id=${outboundId}`
                              : ''
                          }`
                        )
                      }
                      entityKey="skus"
                      searchKey="name_or_merchant_sku_or_system_sku_cont"
                      placeholder={t('skuNameOrSirdabSKU')}
                      onChange={(option) => onChange(option?.value)}
                      error={error?.sku_id}
                      value={value}
                      size="md"
                      required
                    />
                  )}
                />
              </GridItem>

              <GridItem colSpan={{ base: withExpiryDate ? 9 : 7, md: 2 }}>
                <Controller
                  control={control}
                  name={`${skuFieldsName}.${index}.packaging`}
                  rules={{ required: 'thisFieldIsRequired' }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      placeholder={t('packagingType')}
                      options={packagingOptions}
                      onChange={({ value }) => onChange(value)}
                      error={error?.packaging}
                    />
                  )}
                />
              </GridItem>

              <GridItem colSpan={{ base: withExpiryDate ? 9 : 7, md: 2 }}>
                <Controller
                  name={`${skuFieldsName}.${index}.quantity`}
                  control={control}
                  rules={{
                    required: t('thisFieldIsRequired'),
                    min: {
                      value: skuQuantityValidation.min,
                      message: `${t('minIs')} ${skuQuantityValidation.min}`,
                    },
                    max: {
                      value: fieldQuantityMax,
                      message: `${t('maxIs')} ${fieldQuantityMax}`,
                    },
                  }}
                  render={({ field: { ref, ...restField } }) => (
                    <FormControl isInvalid={error?.quantity}>
                      <NumberInput
                        clampValueOnBlur={false}
                        min={skuQuantityValidation.min}
                        size="lg"
                        borderRadius="2xl"
                        max={fieldQuantityMax}
                        {...restField}
                      >
                        <NumberInputField
                          placeholder={t('quantity')}
                          ref={ref}
                          name={restField.name}
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormErrorMessage>
                        {error?.quantity?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </GridItem>

              {withExpiryDate && (
                <GridItem colSpan={{ base: withExpiryDate ? 9 : 7, md: 2 }}>
                  <DatePicker
                    name={`${skuFieldsName}.${index}.expiry_date`}
                    register={register}
                    errors={
                      error?.expiry_date
                        ? {
                            [error.expiry_date.ref.name]: error.expiry_date,
                          }
                        : {}
                    }
                    control={control}
                    placeholder={t('selectExpiryDate')}
                    required={
                      selectedSKU?.is_expiry &&
                      'thisFieldIsRequiredForExpirableSKUs'
                    }
                    minDate={new Date()}
                  />
                </GridItem>
              )}

              <GridItem colSpan={{ base: withExpiryDate ? 9 : 7, md: 1 }}>
                <HStack spacing={3} justifyContent="end">
                  <IconButton
                    colorScheme="green"
                    background="green.50"
                    color="green.500"
                    variant="ghost"
                    icon={<CheckIcon boxSize={{ base: '4', md: '5' }} />}
                    onClick={async () => {
                      const isValid = await trigger(
                        `${skuFieldsName}.${index}`
                      );

                      if (!isValid) {
                        return;
                      }

                      onSubmit(getValues()[skuFieldsName][index], () => {
                        remove(index);
                      });
                    }}
                    textTransform="capitalize"
                    size="lg"
                  />

                  <IconButton
                    colorScheme="red"
                    background="red.50"
                    variant="ghost"
                    icon={<CloseIcon boxSize={{ base: '4', md: '4' }} />}
                    size="lg"
                    onClick={() => remove(index)}
                  />
                </HStack>
              </GridItem>
            </Grid>
          );
        })}
      </Stack>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="primary"
        variant="outline"
        size="lg"
        mt={4}
        width={{ md: 'auto', base: 'full' }}
        onClick={() => {
          append({ ...skuDefaultValue });
        }}
      >
        {t('insertAnotherSKU')}
      </Button>
    </>
  );
};

export default SKUsTableForm;
