import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import InputField from 'components/shared/Inputs/InputField';
import Button from 'components/shared/Button';
import { passwordValidation } from 'constants/validation';

const Form = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [confirmPasswordError, setConfirmPasswordError] = useState();

  const submit = (data) => {
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      setConfirmPasswordError({
        confirmPassword: {
          message: 'validation.passwordsNotSame',
        },
      });
      return;
    }

    setConfirmPasswordError(undefined);
    onSubmit(data);
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(submit)} noValidate>
      <InputField
        id="password"
        name="password"
        type="password"
        label={t('password')}
        placeholder="••••••••"
        register={register}
        errors={errors}
        required={'validation.passwordRequired'}
        minLength={passwordValidation.minLength}
        maxLength={passwordValidation.maxLength}
      />
      <InputField
        id="confirmPassword"
        name="confirmPassword"
        type="password"
        label={t('confirmPassword')}
        placeholder="••••••••"
        register={register}
        errors={confirmPasswordError ? confirmPasswordError : errors}
        required={'validation.confirmPasswordRequired'}
        minLength={passwordValidation.minLength}
        maxLength={passwordValidation.maxLength}
      />
      <Button
        label={t('resetPassword')}
        type="submit"
        disabled={errors.agree?.message}
        isLoading={isLoading}
      />

      <p className="mt-2">
        <Link
          to="/sign-in"
          className="font-semibold text-sm text-primary hover:text-primary/[.85]"
        >
          {t('returnToLogin')}
        </Link>
      </p>
    </form>
  );
};

export default Form;
