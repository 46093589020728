import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactComponent as InboundIcon } from 'assets/icons/inbound.svg';
import { createColumnHelper } from '@tanstack/react-table';
import OrderStatusTag from 'components/Dashboard/OrderStatusTag';
import StatusTag from 'components/Dashboard/StatusTag';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import ActionsButton from 'components/Dashboard/ActionsButton';
import TablePage from 'components/Dashboard/TablePage';
import { getInbounds } from 'api/Dashboard/inbounds';
import useTable from 'hooks/useTable';
import { Link, useNavigate } from 'react-router-dom';
import { inboundTypeEnum, orderTypeTagColors } from 'constants/dashboard';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as InboundsEmptyTable } from 'assets/images/inbounds-empty-table.svg';

const StyledInboundIcon = styled(InboundIcon)`
  path {
    stroke: #fff;
  }
`;

const defaultSorting = [
  {
    id: 'schedule_at',
    desc: true,
  },
];

const columnHelper = createColumnHelper();

const pageFilterKey = 'q[carrier_id_in][]';

const CreateInboundButton = ({ fullWidth }) => {
  const { t } = useTranslation();

  return (
    <Button
      leftIcon={<StyledInboundIcon />}
      colorScheme="primary"
      size="lg"
      as={Link}
      to="create"
      width={fullWidth && 'full'}
    >
      {t('inboundPages.requestNewInbound')}
    </Button>
  );
};

const Inbounds = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageFilter = [
    {
      label: t('inboundPages.allInbounds'),
      value: '',
    },
    ...Object.keys(inboundTypeEnum).map((key) => ({
      label: t(`orderCarrier.${key}`),
      value: inboundTypeEnum[key],
    })),
  ];

  const {
    data,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getInbounds,
    fetchKey: 'inbounds',
    pageFilterKey,
    defaultState: {
      sorting: defaultSorting,
    },
    searchKey:
      'identifier_or_destination_addressable_of_Warehouse_type_name_cont',
  });

  const filters = [
    {
      label: t('status'),
      name: 'status',
      options: responseFilters.statuses?.map((status) => ({
        label: t(`orderStatus.${status}`),
        value: status,
      })),
    },
    {
      label: t('supplier'),
      name: 'supplier_id',
      options: responseFilters.suppliers?.map(([id, name]) => ({
        label: name,
        value: id,
      })),
      allLabel: 'Suppliers',
    },
    {
      label: t('warehouse'),
      name: 'warehouse_id',
      options: responseFilters.warehouses?.map(([id, name]) => ({
        label: name,
        value: id,
      })),
      allLabel: 'Warehouses',
    },
  ];

  const columns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('schedule_at', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('scheduledAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('inbound_type', {
      cell: ({ getValue }) => {
        const status = getValue();
        return (
          <StatusTag colorScheme={orderTypeTagColors[status]}>
            {t(`orderCarrier.${status}`)}
          </StatusTag>
        );
      },
      header: t('inboundType'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('origin_name', {
      cell: ({ getValue }) => getValue(),
      header: t('origin'),
    }),
    columnHelper.accessor('warehouse_name', {
      cell: ({ getValue }) => getValue(),
      header: t('warehouse'),
    }),
    columnHelper.accessor('supplier_name', {
      cell: ({ getValue }) => getValue(),
      header: t('supplier'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => {
              navigate(`/inbounds/${row.original.id}`);
            }}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <TablePage
      data={data}
      columns={columns}
      filters={filters}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('inboundPages.inboundOrders')}
      pageFilter={pageFilter}
      pageAction={<CreateInboundButton />}
      onFilterChange={onFilterChange}
      onPageFilterChange={(value) => {
        onFilterChange({
          [pageFilterKey]: value,
        });
      }}
      selectedPageFilter={selectedFilter?.[pageFilterKey]}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('inboundPages.tableSearchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      defaultSorting={defaultSorting}
      emptyTable={
        <EmptyTable
          illustration={<InboundsEmptyTable />}
          title={t('emptyStateTitle', { value: t('inboundOrders') })}
          subtitle={t('emptyStateSubtitle', { value: t('newInbound') })}
          actions={<CreateInboundButton fullWidth />}
        />
      }
    />
  );
};

export default Inbounds;
