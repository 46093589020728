import { Divider } from '@chakra-ui/react';

const TitleDivider = () => {
  return (
    <Divider
      orientation="vertical"
      width="8px"
      minHeight="25px"
      height="full"
      opacity="1"
      background="gray.800"
      borderRadius="lg"
      flexShrink={0}
    />
  );
};

export default TitleDivider;
