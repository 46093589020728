import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import CollapsibleCard from '../CollapsibleCard';
import { Center, Flex, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as DollarCircleIcon } from 'assets/icons/dollar-circle.svg';
import Table from '../Table';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const TransportationDetailsSection = ({ inboundDetails }) => {
  const { t } = useTranslation();

  const transactionDetails = inboundDetails.transaction_details ?? {};

  const columns = [
    columnHelper.accessor('number_of_trucks', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('numberOfTrucks'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('truck_type', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('truckType'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('truck_size', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('truckSize'),
      meta: { centerHeader: true },
    }),
  ];

  return (
    <CollapsibleCard
      title={<SectionTitle title={t('transportationDetails')} hideDivider />}
      body={
        <Stack spacing={{ base: 0, md: 6 }}>
          <Flex
            gap={10}
            justifyContent={{ base: 'space-between', md: 'start' }}
          >
            <Flex gap={3}>
              <DollarCircleIcon />
              <Text fontWeight={500} color="gray.600">
                {t('price')}
              </Text>
            </Flex>

            <Text>
              {`${transactionDetails.price ?? 0} `}
              <Text
                fontWeight={500}
                color="gray.600"
                as="span"
                textTransform="uppercase"
              >
                {t('sar')}
              </Text>
            </Text>
          </Flex>

          <Table
            data={transactionDetails.trucks ?? []}
            columns={columns}
            hidePagination
            enableSorting={false}
          />
        </Stack>
      }
      isOpen
    />
  );
};

export default TransportationDetailsSection;
