import '@fontsource-variable/inter';
import '@fontsource/almarai';

import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';
import {
  cardAnatomy,
  menuAnatomy,
  tagAnatomy,
  inputAnatomy,
  numberInputAnatomy,
  modalAnatomy,
} from '@chakra-ui/anatomy';

export const inputSizes = {
  sm: '36px',
  md: '48px',
  lg: '56px',
};

const inputBackgroundFix = {
  field: {
    bg: 'gray.50',
    borderColor: 'gray.300',
    _invalid: {
      boxShadow: 'none',
      borderColor: 'red.500',
      borderRadius: 'lg',
    },
    _focus: {
      boxShadow: 'none',
      borderColor: 'blue.500',
    },
  },
  addon: {
    bg: 'gray.50',
    borderColor: 'gray.300',
    borderWidth: '1px',
  },
};

const {
  definePartsStyle: defineCardPartsStyle,
  defineMultiStyleConfig: defineCardMultiStyleConfig,
} = createMultiStyleConfigHelpers(cardAnatomy.keys);

const cardBaseStyle = defineCardPartsStyle({
  container: {
    boxShadow: 'none',
    borderRadius: 'xl',
  },
  body: {
    paddingTop: 0,
  },
});

const cardSizes = {
  lg: defineCardPartsStyle({
    header: {
      padding: '24px',
      paddingBottom: '20px',
    },
    body: {
      padding: '24px',
      paddingTop: 0,
    },
  }),
};

const cardTheme = defineCardMultiStyleConfig({
  baseStyle: cardBaseStyle,
  sizes: cardSizes,
});

const {
  definePartsStyle: defineMenuPartsStyle,
  defineMultiStyleConfig: defineMenuMultiStyleConfig,
} = createMultiStyleConfigHelpers(menuAnatomy.keys);

const menuBaseStyle = defineMenuPartsStyle({
  list: {
    p: '2',
    borderRadius: 'xl',
    border: 'none',
    boxShadow: '10px 15px 50px 0px rgba(113, 128, 150, 0.08)',
  },
  item: {
    borderRadius: 'lg',
    py: 3,
    fontSize: 'sm',
    fontWeight: 500,
    transition: '.2s',
    color: 'gray.600',
    _hover: {
      color: 'gray.800',
    },
  },
});

const menuTheme = defineMenuMultiStyleConfig({ baseStyle: menuBaseStyle });

const {
  definePartsStyle: defineTagPartsStyle,
  defineMultiStyleConfig: defineTagMultiStyleConfig,
} = createMultiStyleConfigHelpers(tagAnatomy.keys);

const tagTheme = defineTagMultiStyleConfig({
  variants: {
    gray: defineTagPartsStyle({
      container: {
        bg: 'gray.200',
        color: 'gray.700',
      },
    }),
    blue: defineTagPartsStyle({
      container: {
        bg: 'blue.100',
        color: 'blue.500',
      },
    }),
    primary: defineTagPartsStyle({
      container: {
        bg: 'primary.100',
        color: 'primary.500',
      },
    }),
    green: defineTagPartsStyle({
      container: {
        bg: 'green.100',
        color: 'green.500',
      },
    }),
    red: defineTagPartsStyle({
      container: {
        bg: 'red.100',
        color: 'red.500',
      },
    }),
    purple: defineTagPartsStyle({
      container: {
        bg: 'purple.100',
        color: 'purple.500',
      },
    }),
    yellow: defineTagPartsStyle({
      container: {
        bg: 'yellow.100',
        color: 'yellow.700',
      },
    }),
    pink: defineTagPartsStyle({
      container: {
        bg: 'pink.100',
        color: 'pink.700',
      },
    }),
  },
  defaultProps: {
    variant: 'gray',
  },
});

const {
  definePartsStyle: defineInputPartsStyle,
  defineMultiStyleConfig: defineInputMultiStyleConfig,
} = createMultiStyleConfigHelpers(inputAnatomy.keys);

const inputBaseStyle = defineInputPartsStyle({
  field: {
    borderRadius: 'xl',
    _placeholder: {
      color: 'gray.500',
    },
  },
});

const inputTheme = defineInputMultiStyleConfig({
  baseStyle: inputBaseStyle,
  variants: {
    backgroundFix: inputBackgroundFix,
  },
  defaultProps: {
    variant: 'backgroundFix',
  },
  sizes: {
    lg: {
      field: {
        fontSize: 'md',
      },
    },
  },
});

const {
  definePartsStyle: defineNumberInputPartsStyle,
  defineMultiStyleConfig: defineNumberInputMultiStyleConfig,
} = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const numberInputBaseStyle = defineNumberInputPartsStyle({
  field: {
    borderRadius: 'xl',
    _placeholder: {
      color: 'gray.500',
      textTransform: 'capitalize',
    },
  },
  stepper: {
    color: 'gray.600',
  },
});

const numberInputTheme = defineNumberInputMultiStyleConfig({
  baseStyle: numberInputBaseStyle,
  variants: {
    backgroundFix: inputBackgroundFix,
  },
  defaultProps: {
    variant: 'backgroundFix',
  },
  sizes: {
    lg: {
      field: {
        fontSize: 'md',
      },
    },
  },
});

const {
  definePartsStyle: defineModalPartsStyle,
  defineMultiStyleConfig: defineModalMultiStyleConfig,
} = createMultiStyleConfigHelpers(modalAnatomy.keys);

const modalBaseStyle = defineModalPartsStyle({
  closeButton: {
    _rtl: {
      right: 'auto',
      left: '4',
    },
  },
});

const modalTheme = defineModalMultiStyleConfig({
  baseStyle: modalBaseStyle,
});

const baseTheme = extendTheme({
  styles: {
    global: {
      html: {
        fontSize: '16px',
      },
    },
  },
  fonts: {
    heading: `'Inter Variable', sans-serif`,
    body: `'Inter Variable', sans-serif`,
  },
  colors: {
    lightTeal: { 100: '#CEF5F2', 500: '#CEF5F2' },
    primary: {
      50: '#F0FCFB',
      100: '#CEF5F2',
      200: '#a8eee8',
      300: '#7ae4dc',
      400: '#34C4BE',
      500: '#03989E',
      600: '#027787',
      700: '#015A71',
      800: '#00415B',
      900: '#002F4B',
    },
    gray: {
      50: '#F8FAFC',
      100: '#F7FAFC',
      200: '#EDF2F7',
      300: '#E2E8F0',
      400: '#CBD5E0',
      500: '#A0AEC0',
      600: '#718096',
      700: '#4A5568',
      800: '#2D3748',
      900: '#1A202C',
    },
    red: {
      50: '#FFF4F4',
      100: '#FFE8E8',
      200: '#FFD4D4',
      300: '#FFA9A9',
      400: '#FF7979',
      500: '#E53E3E',
      600: '#D94D59',
      700: '#B6354B',
      800: '#92213F',
      900: '#791437',
    },
    yellow: {
      50: '#FFF8E6',
      100: '#FFF4CC',
      200: '#ffedae',
      300: '#ffe066',
      400: '#ffd43b',
      500: '#fdce23',
      600: '#FBBF24',
      700: '#f59f00',
      800: '#f08c00',
      900: '#e67700',
    },
    green: {
      50: '#E7FDEA',
      100: '#E1FFE4',
      200: '#9AF7AE',
      300: '#65E78E',
      400: '#3ECF7A',
      500: '#0CAF60',
      600: '#08965F',
      700: '#067D5B',
      800: '#036553',
      900: '#02534D',
    },
    blue: {
      50: '#EBF8FF',
      100: '#E1EEFE',
      200: '#BFDBFE',
      300: '#93C5FD',
      400: '#60A5FA',
      500: '#3B82F6',
      600: '#2563EB',
      700: '#1D4ED8',
      800: '#1E40AF',
      900: '#1E3A8A',
    },
    cyan: {
      50: '#EDFDFD',
      100: '#DFF7FC',
      200: '#9DECF9',
      300: '#76E4F7',
      400: '#0BC5EA',
      500: '#00B5D8',
      600: '#00A3C4',
      700: '#0987A0',
      800: '#086F83',
      900: '#065666',
    },
    purple: {
      50: '#FAF5FF',
      100: '#F7F0FF',
      200: '#D6BCFA',
      300: '#B794F4',
      400: '#9F7AEA',
      500: '#805AD5',
      600: '#6B46C1',
      700: '#553C9A',
      800: '#44337A',
      900: '#322659',
    },
    pink: {
      50: '#FFF5F7',
      100: '#FEE6ED',
      200: '#FBB6CE',
      300: '#F687B3',
      400: '#ED64A6',
      500: '#D53F8C',
      600: '#B83280',
      700: '#97266D',
      800: '#702459',
      900: '#521B41',
    },
  },
  components: {
    Input: inputTheme,
    Select: {
      variants: {
        backgroundFix: inputBackgroundFix,
      },
      defaultProps: {
        variant: 'backgroundFix',
      },
      sizes: {
        lg: {
          field: {
            fontSize: 'md',
          },
        },
      },
    },
    NumberInput: numberInputTheme,
    Button: {
      baseStyle: {
        borderRadius: '8px',
        fontWeight: '600',
        textTransform: 'capitalize',
      },
      variants: {
        primary: {
          bg: '#03989E',
          color: '#fff',
          colorScheme: 'primary',
          borderWidth: 1,
          borderColor: '#03989E',
        },
        secondary: {
          bg: 'white',
          borderColor: 'gray.200',
          borderWidth: 1,
        },
        danger: {
          color: 'red.500',
          borderWidth: 1,
          borderColor: 'gray.200',
        },
      },
      sizes: {
        lg: {
          fontSize: 'md',
        },
        md: {
          fontSize: 'sm',
        },
        sm: {
          fontSize: 'sm',
        },
      },
    },
    Popover: {
      variants: {
        responsive: {
          content: { width: 'unset' },
        },
      },
    },
    Card: cardTheme,
    Skeleton: {
      baseStyle: {
        borderRadius: 'md',
      },
    },
    Menu: menuTheme,
    Tag: tagTheme,
    Modal: modalTheme,
    Alert: {
      baseStyle: (props) => ({
        container: {
          bg: props.status === 'warning' ? 'yellow.50' : undefined,
        },
        icon: {
          color: props.status === 'warning' ? 'yellow.700' : undefined,
        },
      }),
    },
  },
});

const arTheme = extendTheme(
  {
    fonts: {
      heading: `'Almarai', sans-serif`,
      body: `'Almarai', sans-serif`,
    },
    styles: {
      global: {
        // body: {
        //   lineHeight: '1.9rem',
        // },
        // '*::placeholder': {
        // lineHeight: '3rem !important',
        // },
      },
    },
  },
  baseTheme
);

export { baseTheme as theme, arTheme };
