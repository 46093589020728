export const PASSWORD_MINLENGTH = 6;
export const PASSWORD_MAXLENGTH = 128;
export const PHONENUMBER_LENGTH = 10;
export const VAT_LENGTH = 15;
export const CR_LENGTH = 10;

export const passwordValidation = {
  minLength: {
    value: PASSWORD_MINLENGTH,
    message: 'validation.minLengthIs',
  },
  maxLength: {
    value: PASSWORD_MAXLENGTH,
    message: 'validation.maxLengthIs',
  },
};

export const skuQuantityValidation = {
  min: 1,
  max: 30,
};
