import { Box, Flex, Text } from '@chakra-ui/react';

const SectionDataItem = ({ icon, label, value, wrapOnMobile }) => (
  <Flex
    justifyContent="space-between"
    alignItems={{ base: wrapOnMobile ? 'start' : undefined, md: 'center' }}
    flexFlow={{ base: wrapOnMobile ? 'column' : undefined, md: 'row' }}
    fontWeight={500}
    columnGap={{ base: 4, md: 10 }}
    rowGap={2}
  >
    <Flex gap={3}>
      {icon}
      <Text color="gray.600" textTransform="capitalize" whiteSpace="nowrap">
        {label}
      </Text>
    </Flex>

    <Box>{value}</Box>
  </Flex>
);

export default SectionDataItem;
