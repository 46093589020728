import { createContext, useContext } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as AlertIllustration } from 'assets/images/alert-illustration.svg';
import { Link } from 'react-router-dom';

const UnverifiedOrganizationModalContext = createContext();

const HighlightText = ({ children }) => (
  <Text fontWeight={600} color="gray.900" as="span">
    {children}
  </Text>
);

export const UnverifiedOrganizationModalProvider = ({ children }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const showModal = () => {
    onOpen();
  };

  return (
    <UnverifiedOrganizationModalContext.Provider value={{ showModal }}>
      {children}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl">
          <ModalHeader borderBottomWidth={1} padding={6}>
            {t('unverifiedOrganization')}
          </ModalHeader>
          <ModalCloseButton mt={4} marginInlineEnd={2} bgColor="gray.50" />
          <ModalBody textAlign="center" padding={6}>
            <Stack spacing={6} alignItems="center">
              <AlertIllustration />
              <Text color="gray.700">
                <Trans
                  i18nKey="yourOrganizationNotYetVerifiedToCompleteAction"
                  components={[<HighlightText />]}
                />
              </Text>
            </Stack>
          </ModalBody>
          <ModalFooter gap={2} borderTopWidth={1} padding={6}>
            <Button
              colorScheme="gray"
              variant="outline"
              size="lg"
              onClick={onClose}
            >
              {t('cancel')}
            </Button>
            <Button
              colorScheme="primary"
              size="lg"
              as={Link}
              to="settings/organization"
              onClick={onClose}
            >
              {t('goToSettingsPage')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </UnverifiedOrganizationModalContext.Provider>
  );
};

export function useUnverifiedOrganizationModal() {
  return useContext(UnverifiedOrganizationModalContext);
}
