import { useTranslation } from 'react-i18next';

const TermsAndConditionsInput = () => {
  const { t } = useTranslation();
  return (
    <div className="text-sm">
      {t('byProceedYouAgreeTo')}{' '}
      <a
        href={t('termsAndConditionsURL')}
        className="font-semibold text-primary hover:text-primary/[.85]"
        target="_blank"
        rel="noreferrer"
      >
        {t('termsAndConditions')}
      </a>
    </div>
  );
};

export default TermsAndConditionsInput;
