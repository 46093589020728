import { Flex } from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';

const CreateButtonsContainer = ({ children }) => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  return (
    <Flex
      gap={3}
      position="fixed"
      top={{ base: 'auto', md: '24px' }}
      bottom={{ base: 0, md: 'auto' }}
      right={{ base: 'auto', md: isRTL ? 'auto' : '32px' }}
      left={{ base: 0, md: isRTL ? '32px' : 'auto' }}
      width={{ base: 'full', md: 'auto' }}
      background={{ base: 'white', md: 'transparent' }}
      paddingX={{ base: 4, md: 0 }}
      paddingY={{ base: 5, md: 0 }}
      justifyContent={{ base: 'end', md: 'start' }}
      zIndex={1400}
    >
      {children}
    </Flex>
  );
};

export default CreateButtonsContainer;
