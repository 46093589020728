import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  SimpleGrid,
  Stack,
  Switch as ChakraSwitch,
  Text,
  useDisclosure,
  useToast,
  Spinner,
  useMediaQuery,
  MenuItem,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PageDrawer from 'components/Dashboard/PageDrawer';
import { useMutation, useQuery } from 'react-query';
import { flattenErrorObject } from 'utils/formError';
import Input from 'components/shared/Inputs/Input';
import { Controller, useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { emailRegex } from 'utils/regex';
import RadioCardGroup from 'components/shared/Inputs/RadioCardGroup/RadioCardGroup';
import { ReactComponent as AdminIcon } from 'assets/icons/user-settings.svg';
import { ReactComponent as EmployeeIcon } from 'assets/icons/user-eye.svg';
import AsyncSelect from 'components/Dashboard/AsyncSelect';
import { getSKUGroups } from 'api/Dashboard/skuGroups';
import {
  createUser,
  getUserById,
  getUsers,
  updateUser,
} from 'api/Dashboard/users';
import StatusTag from 'components/Dashboard/StatusTag';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';

const RoleRadioContainer = ({ icon, text }) => {
  return (
    <Flex flex={1} gap={3} alignItems="center">
      <Flex
        boxSize="40px"
        alignItems="center"
        justifyContent="center"
        bg="gray.100"
        borderRadius="full"
      >
        {icon}
      </Flex>
      <Text as="span" textTransform="capitalize">
        {text}
      </Text>
    </Flex>
  );
};

const Switch = ({ register, name, label }) => {
  return (
    <FormControl display="flex" gap={3}>
      <ChakraSwitch size="lg" colorScheme="primary" {...register(name)} />
      <FormLabel fontWeight="600">{label}</FormLabel>
    </FormControl>
  );
};

const UserManagementForm = ({
  defaultValues = {
    confirmInbounds: {
      action: 'confirm',
      subject_class: 'inbound',
      checked: false,
    },
    confirmOutbounds: {
      action: 'confirm',
      subject_class: 'outbound',
      checked: false,
    },
    accessAllInventory: {
      action: 'access',
      subject_class: 'inventory',
      checked: false,
    },
  },
  onSubmit,
  isDisabled,
  isEdit,
  backendErrors = {},
}) => {
  const { t } = useTranslation();

  const { handleSubmit, formState, register, watch, control } = useForm({
    defaultValues,
  });

  const watchRole = watch('role');
  const watchAccessAllInventory = watch('accessAllInventory');

  const errors = { ...formState.errors, ...backendErrors };

  const roleOptions = [
    {
      label: (
        <RoleRadioContainer icon={<AdminIcon fill="red" />} text={t('admin')} />
      ),
      value: 'admin',
    },
    {
      label: (
        <RoleRadioContainer icon={<EmployeeIcon />} text={t('employee')} />
      ),
      value: 'employee',
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id="userManagementForm">
      <fieldset disabled={isDisabled ? 'disabled' : ''}>
        <Stack spacing={5}>
          <Heading fontSize="lg" fontWeight={600}>
            {t('personalDetails')}
          </Heading>
          <SimpleGrid columns={2} spacing={5}>
            <Input
              name="firstname"
              label={t('firstName')}
              register={register}
              errors={errors}
              placeholder={`${t('e.g')}: ${t('firstNameExample')}`}
              size="lg"
              required="thisFieldIsRequired"
              defaultValue={defaultValues.firstname}
            />
            <Input
              name="lastname"
              label={t('lastName')}
              register={register}
              errors={errors}
              placeholder={`${t('e.g')}: ${t('lastNameExample')}`}
              size="lg"
              required="thisFieldIsRequired"
              defaultValue={defaultValues.lastname}
            />

            <Input
              name="phone"
              label={t('phoneNumber')}
              defaultValue={defaultValues.phone}
              placeholder={`${t('e.g')}: +966112212222`}
              register={register}
              errors={errors}
              required="thisFieldIsRequired"
              validate={(value) =>
                isValidPhoneNumber(value, 'SA') || 'validation.invalidNumber'
              }
              size="lg"
            />
            <Input
              name="email"
              label={t('email')}
              defaultValue={defaultValues.email}
              placeholder={`${t('e.g')}: ${t('email@gmail.com')}`}
              register={register}
              errors={errors}
              pattern={{
                value: emailRegex,
                message: 'validation.invalidEmail',
              }}
              required="thisFieldIsRequired"
              size="lg"
            />
          </SimpleGrid>

          <RadioCardGroup
            name="role"
            label={t('userType')}
            options={roleOptions}
            register={register}
            required="thisFieldIsRequired"
            errors={errors}
            columns={2}
            defaultValue={defaultValues.role}
          />

          {watchRole === 'employee' && (
            <Stack spacing={6} mt={5}>
              <Heading fontSize="lg" fontWeight={600}>
                {t('employeePermissions')}
              </Heading>

              <Switch
                name="confirmInbounds.checked"
                label={t('canConfirmInbounds')}
                register={register}
              />
              <Switch
                name="confirmOutbounds.checked"
                label={t('canConfirmOutbounds')}
                register={register}
              />
              <Switch
                name="accessAllInventory.checked"
                label={t('accessAllInventory')}
                register={register}
              />

              {!watchAccessAllInventory?.checked && (
                <Controller
                  control={control}
                  name="skuGroups"
                  // rules={{ required: 'thisFieldIsRequired' }}
                  render={({ field: { onChange } }) => (
                    <AsyncSelect
                      performSearchRequest={getSKUGroups}
                      entityKey="sku-groups"
                      label={t('onlyShowsTheseSKUGroups')}
                      onChange={(selectedValues) =>
                        onChange(selectedValues.map(({ value }) => value))
                      }
                      error={errors?.skuGroups}
                      defaultValue={defaultValues.skuGroups}
                      size="lg"
                      placeholder={t('skuGroups')}
                      isMulti
                      // required
                    />
                  )}
                />
              )}
            </Stack>
          )}
        </Stack>
      </fieldset>
    </form>
  );
};

const getUserPermission = (permissions, { subject, action }) =>
  permissions?.find(
    (permission) =>
      permission.subject_class === subject && permission.action === action
  );

const columnHelper = createColumnHelper();

const UserManagement = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const [userToEdit, setUserToEdit] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data,
    isLoading,
    selectedFilter,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    onSearchChange,
    pagination,
    refetch,
  } = useTable({
    fetch: getUsers,
    fetchKey: 'users',
    searchKey: 'name_or_email_cont',
  });

  const onMutationSuccess = () => {
    onClose();
    refetch();
  };

  const {
    mutate: createUserMutation,
    isLoading: isCreateUserLoading,
    error: createUserError,
  } = useMutation(createUser, {
    onSuccess: onMutationSuccess,
    onError: () => {
      toast({
        title: t('createUserFailed'),
        status: 'error',
        duration: 5000,
      });
    },
  });

  const {
    mutate: updateUserMutation,
    isLoading: isUpdateUserLoading,
    error: updateUserError,
  } = useMutation(({ user, id }) => updateUser(id, user), {
    onSuccess: () => {
      onMutationSuccess();
      toast({
        title: t('updateUserSuccess'),
        status: 'success',
        duration: 5000,
      });
      setUserToEdit(undefined);
    },
  });

  const handleSubmitUser = (values) => {
    const payload = {
      ...values,

      confirmInbounds: undefined,
      confirmOutbounds: undefined,
      accessAllInventory: undefined,
      ...(values.role === 'employee'
        ? {
            permissions: [
              userToEdit
                ? values.confirmInbounds
                : {
                    action: 'confirm',
                    subject_class: 'inbound',
                    checked: values.confirmInbounds.checked,
                  },
              userToEdit
                ? values.confirmOutbounds
                : {
                    action: 'confirm',
                    subject_class: 'outbound',
                    checked: values.confirmOutbounds.checked,
                  },
              userToEdit
                ? values.accessAllInventory
                : {
                    action: 'access',
                    subject_class: 'inventory',
                    checked: values.accessAllInventory.checked,
                  },
            ],
          }
        : {}),

      skuGroups: undefined,
      sku_group_ids: values.skuGroups,
    };

    if (userToEdit) {
      updateUserMutation({ user: payload, id: userToEdit.id });
      return;
    }

    createUserMutation(payload);
  };

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('role', {
      cell: ({ getValue }) => (
        <Text as="span" textTransform="capitalize">
          {t(getValue())}
        </Text>
      ),
      header: t('type'),
    }),
    columnHelper.accessor('email', {
      cell: ({ getValue }) => getValue(),
      header: t('email'),
    }),
    columnHelper.accessor('phone', {
      cell: ({ getValue }) => getValue(),
      header: t('phoneNumber'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => {
        const value = getValue();
        const valueLabel = value === null ? 'inactive' : value;
        const isActive = value === 'active';

        return (
          <StatusTag colorScheme={isActive ? 'green' : 'red'} size="sm">
            {t(valueLabel)}
          </StatusTag>
        );
      },
      header: t('status'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const isAdmin = row.original.role === 'admin';

        if (isAdmin) {
          return null;
        }

        const value = row.original.status;
        const isActive = value === 'active';
        const activateButtonLabel = isActive ? 'deactivate' : 'activate';

        const handleEdit = () => {
          setUserToEdit(row.original);
          onOpen();
        };

        const handleActivateToggle = () => {
          updateUserMutation({
            user: {
              status: isActive ? 'inactive' : 'active',
            },
            id: row.original.id,
          });
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<DotsVerticalIcon />}
                size="sm"
              />
              <MenuList>
                <MenuItem onClick={handleEdit} textTransform="capitalize">
                  {t('manage')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color={isActive && 'red.500'}
                  onClick={handleActivateToggle}
                >
                  {t(activateButtonLabel)}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <fieldset disabled={isUpdateUserLoading ? 'disabled' : undefined}>
            <Flex gap={3} justifyContent="center">
              <Button
                onClick={handleEdit}
                variant="outline"
                color="gray.600"
                textTransform="capitalize"
                fontWeight={500}
                size="sm"
              >
                {t('manage')}
              </Button>
              <Button
                variant="outline"
                color={isActive && 'red.600'}
                textTransform="capitalize"
                fontWeight={500}
                size="sm"
                onClick={handleActivateToggle}
              >
                {t(activateButtonLabel)}
              </Button>
            </Flex>
          </fieldset>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const { data: userData = {}, isLoading: isUserDataLoading } = useQuery(
    userToEdit?.id ? ['user-data', userToEdit.id] : ['user-data'],
    () => getUserById(userToEdit?.id),
    {
      enabled: !!userToEdit?.id,
    }
  );

  const userDefaultValue = userData && {
    firstname: userData.name?.split(' ')[0],
    lastname: userData.name?.split(' ')[1],
    email: userData.email,
    phone: userData.phone,
    role: userData.role,
    skuGroups: userData.sku_groups?.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
    confirmInbounds: getUserPermission(userData.permissions, {
      subject: 'inbound',
      action: 'confirm',
    }),
    confirmOutbounds: getUserPermission(userData.permissions, {
      subject: 'outbound',
      action: 'confirm',
    }),
    accessAllInventory: getUserPermission(userData.permissions, {
      subject: 'inventory',
      action: 'access',
    }),
  };

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        selectedFilter={selectedFilter}
        pagination={pagination}
        title={t('allUsers')}
        tableAction={
          <Button
            leftIcon={<AddIcon />}
            colorScheme="primary"
            textTransform="capitalize"
            size="lg"
            onClick={onOpen}
          >
            {t('addNewUser')}
          </Button>
        }
        onFilterChange={onFilterChange}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('suppliersPage.tableSearchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
      />

      <PageDrawer
        title={t(userToEdit ? 'editUser' : 'addNewUser')}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          if (userToEdit) {
            setUserToEdit(undefined);
          }
        }}
        formId="userManagementForm"
        isLoading={isCreateUserLoading || isUpdateUserLoading}
      >
        {isUserDataLoading ? (
          <Spinner color="primary.500" size="lg" />
        ) : (
          <UserManagementForm
            onSubmit={handleSubmitUser}
            defaultValues={userDefaultValue}
            isDisabled={isCreateUserLoading || isUpdateUserLoading}
            isEdit={userToEdit}
            backendErrors={flattenErrorObject(
              userToEdit
                ? updateUserError?.response.data.errors
                : createUserError?.response.data.errors
            )}
          />
        )}
      </PageDrawer>
    </>
  );
};

export default UserManagement;
