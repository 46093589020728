import { PopupButton } from 'react-calendly';

const CalendlyPopupText = ({ content }) => {
  return (
    <PopupButton
      url="https://calendly.com/sirdab-naif"
      rootElement={document.getElementById('root')}
      text={content}
    />
  );
};

export default CalendlyPopupText;
