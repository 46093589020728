import { Box, Card, CardBody } from '@chakra-ui/react';
import Chart from './Chart';
import { chartBarBaseConfig } from './const';
import CardHeader from './CardHeader';
import { useTranslation } from 'react-i18next';

const SpaceUtilization = ({ monthlyUtilization }) => {
  const { t } = useTranslation();

  const labels = monthlyUtilization.map((item) =>
    t(`months.${Object.keys(item)[0]}`)
  );

  const palletsChartData = monthlyUtilization.map((item) => {
    const key = Object.keys(item)[0];

    return item[key].pallets;
  });

  const sqmChartData = monthlyUtilization.map((item) => {
    const key = Object.keys(item)[0];

    return item[key].sqm;
  });

  return (
    <Card borderRadius="xl" borderWidth="1" height="100%">
      <CardHeader title={t('spaceUtilization')} />
      <CardBody pt="0" display="flex" alignItems="end">
        <Box width="100%" height="100%" maxHeight="300px" minHeight="200px">
          <Chart
            data={{
              labels,
              datasets: [
                {
                  label: t('pallets'),
                  data: palletsChartData,
                  ...chartBarBaseConfig,
                  backgroundColor: chartBarBaseConfig.hoverBackgroundColor,
                  hoverBackgroundColor: undefined,
                  barPercentage: 0.75,
                },
                {
                  label: t('sqM'),
                  data: sqmChartData,
                  ...chartBarBaseConfig,
                  hoverBackgroundColor: undefined,
                  barPercentage: 0.75,
                },
              ],
            }}
          />
        </Box>
      </CardBody>
    </Card>
  );
};

export default SpaceUtilization;
