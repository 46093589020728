import { Stack, Heading } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PageDrawer from '../PageDrawer';
import { flattenErrorObject } from 'utils/formError';
import Map from 'components/shared/Inputs/Map';
import POCsInput, { defaultPocAttributes } from '../POCsInput';
import Input from 'components/shared/Inputs/Input';
import CountryCitySelect from '../Addresses/CountryCitySelect';

const initDefaultValues = {
  pocs_attributes: [{ ...defaultPocAttributes }],
  address: undefined,
};

const AddressForm = ({ onSubmit, isDisabled }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
    getValues,
    trigger,
    resetField,
  } = useForm({
    defaultValues: initDefaultValues,
  });

  const watchAddress = watch('address');

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="addressForm" noValidate>
      <Heading fontSize="lg" fontWeight={600} mb={4} textTransform="capitalize">
        {t('addressesPage.addressDetails')}
      </Heading>
      <Stack spacing={5}>
        <Input
          name="addressName"
          label={t('addressName')}
          register={register}
          errors={errors}
          placeholder={t('addressName')}
          size="lg"
          required="thisFieldIsRequired"
        />

        <CountryCitySelect
          control={control}
          watch={watch}
          resetField={resetField}
          errors={errors}
        />

        <Controller
          control={control}
          name="address"
          rules={{
            validate: (value, formValues) => {
              return (
                !!(value?.location?.lat && value?.location?.lng) ||
                'thisFieldIsRequired'
              );
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Map
              label={t('location')}
              onAddressChange={(address) => {
                onChange({
                  location: address.location,
                  url: address.url,
                });
              }}
              error={t(errors.address?.message)}
              inputSize="lg"
              watchAddress={watchAddress}
              value={value}
              required
            />
          )}
        />

        <Heading fontSize="lg" fontWeight={600} mb={-2} mt={2}>
          {t('addressesPage.pocDetails')}
        </Heading>
        <POCsInput
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          trigger={trigger}
        />
      </Stack>
    </form>
  );
};

const AddressFormDrawer = ({
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  backendErrors,
}) => {
  const { t } = useTranslation();

  return (
    <PageDrawer
      title={t('addressesPage.addNewAddress')}
      isOpen={isOpen}
      onClose={() => onClose()}
      formId="addressForm"
      isLoading={isLoading}
    >
      <AddressForm
        onSubmit={onSubmit}
        isDisabled={isLoading}
        backendErrors={flattenErrorObject(backendErrors)}
      />
    </PageDrawer>
  );
};

export default AddressFormDrawer;
