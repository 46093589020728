import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

const CollapsibleCard = ({ title, body, isOpen }) => (
  <Accordion allowToggle defaultIndex={isOpen ? 0 : undefined}>
    <AccordionItem border={0}>
      <Card>
        <CardHeader py={0}>
          <AccordionButton
            py={6}
            px={0}
            _hover={{ background: 'transparent' }}
            fontSize="2xl"
          >
            <Box as="span" flex="1">
              {title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </CardHeader>

        <AccordionPanel p={0}>
          <CardBody>{body}</CardBody>
        </AccordionPanel>
      </Card>
    </AccordionItem>
  </Accordion>
);

export default CollapsibleCard;
