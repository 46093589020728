import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import {
  createSupplier,
  getSuppliers,
  updateSupplier,
} from 'api/Dashboard/suppliers';
import { AddIcon } from '@chakra-ui/icons';
import { ReactComponent as DotsHorizontalIcon } from 'assets/icons/dots-horizontal.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useTranslation } from 'react-i18next';

import { useState } from 'react';
import PageDrawer from 'components/Dashboard/PageDrawer';
import SupplierForm from 'components/Dashboard/Suppliers/SupplierForm';
import { useMutation } from 'react-query';
import { flattenErrorObject } from 'utils/formError';

const columnHelper = createColumnHelper();

const Suppliers = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const [supplierToEdit, setSupplierToEdit] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data,
    isLoading,
    selectedFilter,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    onSearchChange,
    pagination,
    refetch,
  } = useTable({
    fetch: getSuppliers,
    fetchKey: 'suppliers',
    searchKey: 'name_or_email_cont',
  });

  const onMutationSuccess = () => {
    onClose();
    refetch();
  };

  const {
    mutate: createSupplierMutation,
    isLoading: isCreateSupplierLoading,
    error: createSupplierError,
  } = useMutation(createSupplier, {
    onSuccess: onMutationSuccess,
    onError: () => {
      toast({
        title: t('createSupplierFailed'),
        status: 'error',
        duration: 5000,
      });
    },
  });

  const {
    mutate: updateSupplierMutation,
    isLoading: isUpdateSupplierLoading,
    error: updateSupplierError,
  } = useMutation(updateSupplier, {
    onSuccess: () => {
      onMutationSuccess();
      setSupplierToEdit(undefined);
    },
  });

  const handleSubmitSupplier = (values) => {
    if (supplierToEdit) {
      updateSupplierMutation({ ...values, id: supplierToEdit.id });
      return;
    }
    createSupplierMutation(values);
  };

  const columns = [
    columnHelper.accessor('id', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
    }),
    columnHelper.accessor('mobile_number', {
      cell: ({ getValue }) => getValue(),
      header: t('phoneNumber'),
    }),
    columnHelper.accessor('email', {
      cell: ({ getValue }) => getValue(),
      header: t('email'),
    }),
    columnHelper.accessor('city_name', {
      cell: ({ getValue }) => getValue(),
      header: t('city'),
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<DotsHorizontalIcon />}
            variant="ghost"
          />
          <MenuList>
            {/* <MenuItem
              icon={<EyeIcon width="24" height="24" />}
              onClick={() => console.log({ view: row.original.id })}
              textTransform="capitalize"
            >
              {t('view')}
            </MenuItem> */}
            <MenuItem
              icon={<EditIcon width="24" height="24" />}
              onClick={() => {
                setSupplierToEdit(row.original);
                onOpen();
              }}
              textTransform="capitalize"
            >
              {t('edit')}
            </MenuItem>
          </MenuList>
        </Menu>
      ),
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const supplierDefaultValue = supplierToEdit && {
    name: supplierToEdit.name,
    email: supplierToEdit.email,
    mobile_number: supplierToEdit.mobile_number,
  };

  return (
    <>
      <TablePage
        data={data}
        columns={columns}
        selectedFilter={selectedFilter}
        pagination={pagination}
        title={t('suppliersPage.supplierList')}
        pageAction={
          <Button
            leftIcon={<AddIcon />}
            colorScheme="primary"
            textTransform="capitalize"
            size="lg"
            onClick={onOpen}
          >
            {t('suppliersPage.addNewSupplier')}
          </Button>
        }
        onFilterChange={onFilterChange}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('suppliersPage.tableSearchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
      />

      <PageDrawer
        title={t(
          supplierToEdit
            ? 'suppliersPage.editSupplier'
            : 'suppliersPage.addNewSupplier'
        )}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          if (supplierToEdit) {
            setSupplierToEdit(undefined);
          }
        }}
        formId="supplierForm"
        isLoading={isCreateSupplierLoading || isUpdateSupplierLoading}
      >
        <SupplierForm
          onSubmit={handleSubmitSupplier}
          defaultValues={supplierDefaultValue}
          isDisabled={isCreateSupplierLoading || isUpdateSupplierLoading}
          isEdit={supplierToEdit}
          backendErrors={flattenErrorObject(
            supplierToEdit
              ? updateSupplierError?.response.data.errors
              : createSupplierError?.response.data.errors
          )}
        />
      </PageDrawer>
    </>
  );
};

export default Suppliers;
