import Card from './Card';
import { ReactComponent as UsedIcon } from 'assets/icons/used.svg';
import { chartBarBaseConfig } from './const';
import { useTranslation } from 'react-i18next';

const CurrentSpaceUsed = ({
  utilizedSpaces,
  utilizedSpacesPercentage,
  utilizedSpacesPerWeek,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      icon={<UsedIcon />}
      title={`${t('currentSpaceUsed')} (%)`}
      hint={t('usedSpaceTrendsOverLast4Weeks')}
      data={[
        {
          label: t('pallets'),
          value: utilizedSpaces.pallets,
        },
        {
          label: t('sqM'),
          value: utilizedSpaces.sqm,
        },
      ]}
      chartData={{
        labels: ['', '', '', ''],
        datasets: [
          {
            data: utilizedSpacesPerWeek,
            ...chartBarBaseConfig,
          },
        ],
      }}
    />
  );
};

export default CurrentSpaceUsed;
