import { useTranslation } from 'react-i18next';

import CreatePasswordForm from 'components/CreatePassword/Form';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const CreatePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('reset_password_token');

  const { setPassword, isSetPasswordLoading } = useAuth();

  const handleSubmit = (data) => {
    const { password, confirmPassword } = data;

    setPassword(
      {
        user: {
          password,
          password_confirmation: confirmPassword,
          reset_password_token: resetPasswordToken,
        },
      },
      {
        onSuccess: () => {
          navigate('success');
        },
      }
    );
  };

  return (
    <RegistrationLayout
      title={t('createNewPassword')}
      subtitle={t('createPasswordSubtitle')}
      content={
        <CreatePasswordForm
          onSubmit={handleSubmit}
          isLoading={isSetPasswordLoading}
        />
      }
    />
  );
};

export default CreatePassword;
