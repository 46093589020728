const { useJsApiLoader } = require('@react-google-maps/api');

const libraries = ['places'];

const useMapLoader = () => {
  const loadResult = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  return loadResult;
};

export default useMapLoader;
