import api from 'utils/axios';

export const getFeatureFlags = async () => {
  try {
    const response = await api('/feature_flags');

    return response?.data;
  } catch (error) {
    throw error;
  }
};
