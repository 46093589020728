import { Card, CardHeader, CardBody } from '@chakra-ui/react';
import { addComment } from 'api/Dashboard/comments';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Comments from '../Comments';

const CommentsSection = ({ comments = [], id, onSuccess }) => {
  const { t } = useTranslation();

  const { mutate: addCommentMutation, isLoading: isAddingComment } =
    useMutation(addComment, {
      onSuccess: (data) => {
        onSuccess();
      },
    });

  const handleCommentSubmit = ({ comment }) => {
    addCommentMutation({
      body: comment,
      commentable_id: id,
      commentable_type: 'Manifest',
    });
  };

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={`${t('comments')} (${comments.length})`} />
      </CardHeader>
      <CardBody>
        <Comments
          comments={comments}
          onSubmit={handleCommentSubmit}
          isLoading={isAddingComment}
        />
      </CardBody>
    </Card>
  );
};

export default CommentsSection;
