import { CR_LENGTH, VAT_LENGTH } from 'constants/validation';
import i18n from 'utils/i18n';

export const validateCR = (value) => {
  return (
    value?.length === CR_LENGTH ||
    `${i18n.t('crNumber')} ${i18n.t('digitsShouldBe', { count: CR_LENGTH })}`
  );
};

export const validateVAT = (value) => {
  return (
    value?.length === VAT_LENGTH ||
    `${i18n.t('vatNumber')} ${i18n.t('digitsShouldBe', { count: VAT_LENGTH })}`
  );
};
