import { ChevronDownIcon, SmallAddIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PageActions = () => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="end" gap="4" mb="4">
      <Button
        leftIcon={<SmallAddIcon boxSize="24px" />}
        colorScheme="lightTeal"
        color="teal"
        flex={{ base: '1', md: '0 1 auto' }}
        as={Link}
        to="skus/create"
      >
        {t('overviewPage.addItem')}
      </Button>
      <Menu>
        <MenuButton
          as={Button}
          colorScheme="primary"
          rightIcon={<ChevronDownIcon boxSize="24px" />}
          flex={{ base: '1', md: '0 1 auto' }}
        >
          {t('overviewPage.request')}
        </MenuButton>
        <MenuList>
          <MenuItem as={Link} to="inbounds/create">
            {t('inboundPages.requestNewInbound')}
          </MenuItem>
          <MenuItem as={Link} to="outbounds/create">
            {t('outboundPages.requestNewOutbound')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default PageActions;
