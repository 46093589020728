import api from 'utils/axios';

export const getSuppliers = async (searchParams) => {
  try {
    const response = await api(`/merchants/suppliers?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

const trimAllSpaces = (text) => text?.replace(/\s/g, '');

export const createSupplier = async ({
  name,
  email,
  mobile_number,
  address,
}) => {
  const supplier = {
    name,
    email,
    mobile_number: mobile_number ? trimAllSpaces(String(mobile_number)) : '',
    address_attributes: address && {
      maps_url: address.url,
      country_name: address.country_name,
      city_name: address.city_name,
      name: address.name,
    },
  };

  try {
    const response = await api.post('/merchants/suppliers', {
      supplier,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSupplier = async ({ id, name, email, mobile_number }) => {
  const supplier = {
    name,
    email,
    mobile_number: trimAllSpaces(mobile_number),
  };

  try {
    const response = await api.patch(`/merchants/suppliers/${id}`, {
      supplier,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
