import MapWithDirections from 'components/shared/MapWithDirections';
import { getWarehouseById } from 'api/Dashboard/warehouses';
import { useQuery } from 'react-query';
import { getAddressById } from 'api/Dashboard/addresses';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';

const FormLocationSection = ({ originId, destinationId }) => {
  const { t } = useTranslation();

  const { data: addressDetails } = useQuery(
    ['address-details', originId],
    () => getAddressById(originId),
    {
      enabled: !!originId,
      cacheTime: 100,
    }
  );

  const { data: warehouseDetails } = useQuery(
    ['warehouse-details', destinationId],
    () => getWarehouseById(destinationId),
    {
      enabled: !!destinationId,
      cacheTime: 100,
    }
  );

  const origin = {
    lat: addressDetails?.latitude,
    lng: addressDetails?.longitude,
  };

  const destination = {
    lat: warehouseDetails?.address?.latitude,
    lng: warehouseDetails?.address?.longitude,
  };

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('mapView')} />
      </CardHeader>
      <CardBody minHeight="440px">
        <MapWithDirections
          containerStyle={{
            width: '100%',
            minHeight: '440px',
            borderRadius: '16px',
          }}
          origin={origin}
          destination={destination}
        />
      </CardBody>
    </Card>
  );
};

export default FormLocationSection;
