import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import CollapsibleCard from '../CollapsibleCard';
import { Center, Text } from '@chakra-ui/react';
import Table from '../Table';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'utils/date';
import OrderStatusTag from '../OrderStatusTag';

const columnHelper = createColumnHelper();

const StatusHistorySection = ({ statusHistories = [] }) => {
  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor('caused_by', {
      cell: ({ getValue }) => <Text fontWeight={700}>{getValue()}</Text>,
      header: t('causedBy'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: { centerHeader: true, fitContent: true },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ getValue }) => (
        <Center fontWeight={700}>
          {formatDate(getValue(), 'MMMM d, yyyy h:mm:ss a')}
        </Center>
      ),
      header: t('createdAt'),
      meta: { centerHeader: true },
    }),
  ];

  return (
    <CollapsibleCard
      title={<SectionTitle title={t('statusHistory')} hideDivider />}
      body={
        <Table
          data={statusHistories}
          columns={columns}
          hidePagination
          enableSorting={false}
        />
      }
    />
  );
};

export default StatusHistorySection;
