import {
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIllustration } from 'assets/images/alert-illustration.svg';

const PaymentStatusModal = ({ isOpen, onClose, errorCode }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent padding={0} borderRadius="xl">
        <ModalHeader
          padding={6}
          borderBottom="1px solid"
          borderColor="gray.200"
          textTransform="capitalize"
        >
          {t('paymentFailed')}
        </ModalHeader>
        <ModalCloseButton mt={4} />

        <ModalBody padding={6}>
          <Center flexDirection="column" gap={4}>
            <AlertIllustration width="160px" height="160px" />
            <Text>
              <Text as="span" fontWeight="bold">
                {t('paymentFailedForTheFollowingReason')}
              </Text>
              <Text as="span">{t(`paymentErrorMessages.${errorCode}`)}</Text>
            </Text>
          </Center>
        </ModalBody>

        <ModalFooter padding={6} borderTop="1px solid" borderColor="gray.200">
          <Flex gap={3} width="full" justifyContent="flex-end">
            <Button
              borderColor="gray.300"
              variant="outline"
              size="lg"
              minW="160px"
              onClick={onClose}
            >
              {t('close')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentStatusModal;
