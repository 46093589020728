import { Flex } from '@chakra-ui/react';

const ListPageTitle = ({ title, action }) => {
  return (
    <Flex
      justifyContent={
        title && action ? 'space-between' : action ? 'flex-end' : 'flex-start'
      }
      gap={4}
      flexDirection={{ base: 'column', md: 'row' }}
    >
      {title}

      {action}
    </Flex>
  );
};

export default ListPageTitle;
