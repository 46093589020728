import { Stack, Heading, SimpleGrid } from '@chakra-ui/react';
import Input from 'components/shared/Inputs/Input';
import Map from 'components/shared/Inputs/Map';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { emailRegex } from 'utils/regex';

const SupplierForm = ({
  defaultValues = {},
  onSubmit,
  isDisabled,
  isEdit,
  backendErrors = {},
}) => {
  const { t } = useTranslation();

  // The validation expects the mobile_number to be a string, but the backend saves it as number
  const mobileNumberDefaultValue = defaultValues.mobile_number
    ? '' + defaultValues.mobile_number
    : '';

  const { handleSubmit, formState, register, control } = useForm({
    defaultValues: {
      ...defaultValues,
      mobile_number: mobileNumberDefaultValue,
    },
  });

  const errors = { ...formState.errors, ...backendErrors };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id="supplierForm">
      <fieldset disabled={isDisabled ? 'disabled' : ''}>
        <Stack spacing={5}>
          <Heading fontSize="lg" fontWeight={600}>
            {t('supplierDetails')}
          </Heading>
          <Input
            name="name"
            label={t('name')}
            defaultValue={defaultValues.name}
            register={register}
            errors={errors}
            placeholder={`${t('e.g')}: ${t('nameExample')}`}
            required="thisFieldIsRequired"
            size="lg"
          />
          <SimpleGrid columns={2} spacing={5}>
            <Input
              name="mobile_number"
              label={t('phoneNumber')}
              defaultValue={defaultValues.mobile_number}
              placeholder={`${t('e.g')}: +966112212222`}
              register={register}
              errors={errors}
              validate={(value) =>
                value
                  ? isValidPhoneNumber(value, 'SA') ||
                    'validation.invalidNumber'
                  : true
              }
              size="lg"
            />

            <Input
              name="email"
              label={t('email')}
              defaultValue={defaultValues.email}
              placeholder={`${t('e.g')}: ${t('email@gmail.com')}`}
              register={register}
              errors={errors}
              pattern={{
                value: emailRegex,
                message: 'validation.invalidEmail',
              }}
              size="lg"
            />
          </SimpleGrid>

          {!isEdit && (
            <>
              <Heading fontSize="lg" fontWeight={600} marginTop={1}>
                {t('locationDetails')}
              </Heading>

              <Controller
                control={control}
                name="address"
                render={({ field: { onChange } }) => (
                  <Map
                    label={t('address')}
                    onAddressChange={(address) => {
                      onChange(
                        address
                          ? {
                              city_name: address.city?.long_name,
                              country_name: address.country?.long_name,
                              location: address.location,
                              name: address.name,
                              url: address.url,
                            }
                          : null
                      );
                    }}
                    defaultValue={defaultValues.address}
                    error={t(errors.address?.message)}
                    inputSize="lg"
                    isFlipped
                  />
                )}
              />
            </>
          )}
        </Stack>
      </fieldset>
    </form>
  );
};

export default SupplierForm;
