import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import CalendlyPopupText from 'components/shared/CalendlyPopupText';
import { openInvoiceInNewTab } from 'utils/payment';
import { sendPayment } from 'api/Dashboard/organization';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { queryClient } from 'utils/queryClient';
import { usePaymentDue } from 'context/PaymentDueContext';
import { getLocalStorage } from 'utils/localStorage';

const AccountLockedContent = ({ textAlign = 'left', title, subtitle }) => {
  return (
    <Stack
      textAlign={textAlign}
      fontFamily={textAlign === 'left' ? 'Inter Variable' : 'Almarai'}
      spacing={4}
    >
      <Text fontSize="3xl" fontWeight={600}>
        {title}
      </Text>
      <Text fontWeight={500} color="gray.600">
        {subtitle}
      </Text>
    </Stack>
  );
};

const AccountLockedModal = ({ isOpen }) => {
  const { t } = useTranslation();
  const { paymentDueState } = usePaymentDue();
  const isAdmin = getLocalStorage('user')?.role === 'admin';
  const organizationData = queryClient.getQueryData(['organization']);

  const {
    mutate: sendPaymentRequest,
    isLoading: isSendingPaymentRequest,
    data: sendPaymentResponse,
  } = useMutation(() => sendPayment(organizationData?.id), {
    onSuccess: async (response) => {
      await openInvoiceInNewTab(response);
    },
  });

  const handleSettleBalance = async () => {
    if (sendPaymentResponse) {
      await openInvoiceInNewTab(sendPaymentResponse);
      return;
    }

    sendPaymentRequest();
  };

  return (
    <Modal isOpen={isOpen} size="3xl" isCentered>
      <ModalOverlay />
      <ModalContent padding={10} paddingBottom={6}>
        <ModalBody>
          <Stack spacing={10}>
            <AccountLockedContent
              title="Your account has been locked"
              subtitle="We are sorry to inform you that your account access has been paused due to overdue invoices. To resume access to our warehousing services, please settle all pending invoices."
            />
            <AccountLockedContent
              title="حسابك موقف بالوقت الحالي"
              subtitle="يؤسفنا إبلاغك بأنه تم إيقاف حسابك بسبب الفواتير المتأخرة. لإستعادة الوصول الى حسابك، يرجى سداد جميع الفواتير المعلقة."
              textAlign="right"
            />
          </Stack>
        </ModalBody>

        <ModalFooter marginTop={8} alignSelf="center">
          <Flex maxWidth="360px" gap={4} flexDirection="column">
            {!!paymentDueState && isAdmin && (
              <Button
                colorScheme="primary"
                size="lg"
                variant="outline"
                width="full"
                isLoading={isSendingPaymentRequest}
                onClick={handleSettleBalance}
              >
                {t('settleBalance')}
              </Button>
            )}
            <CalendlyPopupText
              content={
                <Button as="span" colorScheme="primary" size="lg" width="full">
                  {t('scheduleCallWithFinanceTeam')}
                </Button>
              }
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AccountLockedModal;
