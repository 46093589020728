export const flattenErrorObject = (errorObject) => {
  const result = {};

  for (const key in errorObject) {
    if (Array.isArray(errorObject[key]) && errorObject[key].length > 0) {
      result[key] = { message: errorObject[key][0] };
    }
  }

  return result;
};
