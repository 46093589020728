import { Card, CardHeader, CardBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import OrderStatusDetails from '../OrderStatusDetails';

const inboundDetailsStatuses = [
  { value: 'created', label: 'inboundDetailsPage.inboundStatus.created' },
  {
    value: 'arrived_at_warehouse',
    label: 'inboundDetailsPage.inboundStatus.arrived_at_warehouse',
  },
  {
    value: 'unloading',
    label: 'inboundDetailsPage.inboundStatus.unloading',
  },
  { value: 'received', label: 'inboundDetailsPage.inboundStatus.received' },
  {
    value: 'quality_control',
    label: 'inboundDetailsPage.inboundStatus.quality_control',
  },
  {
    value: 'location_assignment',
    label: 'inboundDetailsPage.inboundStatus.location_assignment',
  },
  { value: 'put_away', label: 'inboundDetailsPage.inboundStatus.put_away' },
  { value: 'complete', label: 'inboundDetailsPage.inboundStatus.complete' },
];

const StatusSection = ({ activeStatus }) => {
  const { t } = useTranslation();

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('inboundStatus')} />
      </CardHeader>
      <CardBody>
        <OrderStatusDetails
          statuses={inboundDetailsStatuses}
          activeStatus={activeStatus}
        />
      </CardBody>
    </Card>
  );
};
export default StatusSection;
