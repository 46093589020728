const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({ base64File: reader.result, file });
    reader.onerror = (error) => reject(error);
  });
};

export const toBase64Handler = async (files) => {
  const filePathsPromises = [];

  files.forEach((file) => {
    filePathsPromises.push(toBase64(file));
  });

  const filePaths = await Promise.all(filePathsPromises);

  const mappedFiles = filePaths.map(({ base64File, file }) => ({
    name: file.name,
    type: file.type,
    size: file.size,
    base64: base64File,
  }));

  return mappedFiles;
};

export const stringToNumber = (string) => {
  if (string === '' || string === null) {
    return null;
  }

  const number = +string;
  if (isNaN(number)) {
    return string;
  }

  return number;
};
