import api from 'utils/axios';

export const getReports = async (searchParams) => {
  try {
    const response = await api(`/merchants/reports?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const deleteReport = async (id) => {
  try {
    const response = await api.delete(`/merchants/reports/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const generateReport = async (report) => {
  try {
    const response = await api.post('/merchants/reports', { report });
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};
