import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Switch,
} from '@chakra-ui/react';
import Input from 'components/shared/Inputs/Input';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';

export const defaultPocAttributes = { name: '', phone_number: '', main: true };

const POCsInput = ({
  register,
  control,
  errors,
  getValues,
  trigger,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'pocs_attributes',
  });

  const currentState = getValues('pocs_attributes') ?? [];
  const mainContactFieldIndex = currentState.findIndex((field) => field.main);

  return (
    <>
      <Flex flexDirection="column" gap={4}>
        {fields.map((field, index) => {
          const pocFormProps = register(`pocs_attributes.${index}.main`);

          const onMainPocChange = (e) => {
            if (
              mainContactFieldIndex !== -1 &&
              mainContactFieldIndex !== index
            ) {
              update(mainContactFieldIndex, {
                ...currentState[mainContactFieldIndex],
                main: false,
              });
            }

            pocFormProps.onChange(e);
            trigger(`pocs_attributes.${index}`);
          };

          const isRequired =
            mainContactFieldIndex === index ||
            (index === 0 &&
              (currentState.length === 1 || mainContactFieldIndex === -1));

          return (
            <Box key={field.id}>
              <Flex gap={4}>
                <Input
                  name={`pocs_attributes.${index}.name`}
                  label={t('fullName')}
                  register={register}
                  errors={errors}
                  placeholder={`${t('e.g')}: ${t('nameExample')}`}
                  size="lg"
                  required={isRequired && 'thisFieldIsRequired'}
                />

                <Input
                  name={`pocs_attributes.${index}.phone_number`}
                  label={t('phoneNumber')}
                  placeholder={`${t('e.g')}: +966112212222`}
                  register={register}
                  errors={errors}
                  required={isRequired && 'thisFieldIsRequired'}
                  validate={(value) => {
                    if (!value) {
                      return true;
                    }

                    return (
                      isValidPhoneNumber(value, 'SA') ||
                      'validation.invalidNumber'
                    );
                  }}
                  size="lg"
                />

                {fields.length > 1 && (
                  <Flex gap={4} alignItems="end">
                    <IconButton
                      variant="ghost"
                      colorScheme="red"
                      bgColor="red.100"
                      icon={<CloseIcon boxSize={{ base: '4', md: '3' }} />}
                      onClick={() => remove(index)}
                      size="lg"
                    />
                  </Flex>
                )}
              </Flex>
              <FormControl display="flex" alignItems="center" mt={3}>
                <Switch
                  id={`pocs_attributes.${index}.main`}
                  size="lg"
                  {...pocFormProps}
                  onChange={onMainPocChange}
                  colorScheme="primary"
                />
                <FormLabel
                  htmlFor={`pocs_attributes.${index}.main`}
                  m="0"
                  ms={3}
                >
                  {t('setAsDefaultContact')}
                </FormLabel>
              </FormControl>
            </Box>
          );
        })}
      </Flex>

      <Button
        variant="outline"
        colorScheme="primary"
        leftIcon={<AddIcon />}
        mt={8}
        size="lg"
        onClick={() => {
          append({ ...defaultPocAttributes, main: false });
        }}
      >
        {t('addAnotherPOC')}
      </Button>
    </>
  );
};

export default POCsInput;
