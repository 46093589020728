import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickSlider from 'react-slick';
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Button,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import NoImgPlaceholder from 'assets/images/no-image-placeholder.png';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Carousel = ({ slides, boxSize, imageProps }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState(null);

  const defaultProps = {
    objectFit: 'cover',
    width: 'full',
    alt: 'No Image',
    boxSize: boxSize,
  };

  const handleImageClick = (url) => {
    if (!url) {
      return;
    }

    setSelectedImage(url);
    onOpen();
  };

  if (!slides.length) {
    return <Image src={NoImgPlaceholder} {...defaultProps} {...imageProps} />;
  }

  return (
    <>
      <SlickSlider {...settings}>
        {slides?.map((url, index) => (
          <Image
            key={index}
            src={url}
            fallbackSrc={NoImgPlaceholder}
            onClick={() => handleImageClick(url)}
            sx={{ cursor: url ? 'pointer' : 'auto' }}
            {...defaultProps}
            {...imageProps}
          />
        ))}
      </SlickSlider>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent p={4} paddingTop={12} borderRadius="2xl">
          <ModalCloseButton />
          <Image
            src={selectedImage}
            fallbackSrc={NoImgPlaceholder}
            objectFit="contain"
            width="full"
            maxH="70vh"
            borderRadius="xl"
          />
          <Flex justify="flex-end" mt={4}>
            <Button colorScheme="teal" onClick={onClose} minW="100px">
              {t('done')}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Carousel;
