import { Flex, PinInput, PinInputField } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

const codeLength = 6;
const inputList = Array.from({ length: codeLength }, (_, index) => index);

const CodeVerification = ({ name, control }) => {
  return (
    <Flex justifyContent="start">
      <Flex
        style={{
          direction: 'ltr',
        }}
        position="relative"
        gap={2}
      >
        <Controller
          control={control}
          name={name}
          rules={{
            required: true,
            minLength: {
              value: codeLength,
            },
          }}
          render={({ field: { onChange } }) => (
            <PinInput
              size="lg"
              placeholder=""
              onChange={(value) => {
                onChange(value);
              }}
              otp
              autoFocus
            >
              {inputList.map((i) => (
                <PinInputField
                  bg="gray.50"
                  _focus={{
                    borderColor: 'primary.500',
                    outlineColor: 'primary.500',
                    outlineOffset: 0,
                    boxShadow: 'none',
                    outlineWidth: '1px',
                  }}
                  key={i}
                />
              ))}
            </PinInput>
          )}
        />
      </Flex>
    </Flex>
  );
};

export default CodeVerification;
