import { useState, useEffect } from 'react';

export function useCountdown(initialCountdown) {
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(initialCountdown);

  const startCountdown = () => {
    setIsResendDisabled(true);

    let countdown = resendCountdown;
    const timer = setInterval(() => {
      if (countdown > 0) {
        countdown--;
        setResendCountdown(countdown);
      } else {
        setIsResendDisabled(false);
        setResendCountdown(initialCountdown);
        clearInterval(timer);
      }
    }, 1000);
  };

  useEffect(() => {
    if (isResendDisabled) {
      const timer = setInterval(() => {
        if (resendCountdown > 0) {
          setResendCountdown(resendCountdown - 1);
        } else {
          setIsResendDisabled(false);
          setResendCountdown(initialCountdown);
          clearInterval(timer);
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isResendDisabled, resendCountdown, initialCountdown]);

  return { isResendDisabled, resendCountdown, startCountdown };
}
