export const openUrlInNewTab = async (url) => {
  try {
    const result = await window.navigator.permissions.query({
      name: 'popup',
    });

    if (result.state === 'granted' || result.state === 'prompt') {
      const newWindow = window.open(url, '_blank');
      if (!newWindow) {
        alert('Popups are blocked. Please allow popups and try again.');
      }
      return newWindow;
    }

    alert(
      'Please allow popups in your browser settings to proceed with payment.'
    );
    return null;
  } catch (error) {
    const newWindow = window.open(url, '_blank');
    if (!newWindow) {
      alert('Popups are blocked. Please allow popups and try again.');
    }
    return newWindow;
  }
};
