import {
  Stack,
  Flex,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  useDisclosure,
  Text,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as HashtagUpIcon } from 'assets/icons/hashtag-up.svg';
import { ReactComponent as CalendarAddIcon } from 'assets/icons/calendar-add.svg';
import { ReactComponent as CalendarTickIcon } from 'assets/icons/calendar-tick.svg';
import { ReactComponent as InboundIcon } from 'assets/icons/inbound.svg';
import { ReactComponent as UserPlusBottomIcon } from 'assets/icons/user-plus-bottom.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/timer.svg';
import { ReactComponent as UserSquareIcon } from 'assets/icons/user-square.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/mobile.svg';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import StatusTag from 'components/Dashboard/StatusTag';
import { formatDate } from 'utils/date';
import SectionDataItem from '../SectionDataItem';
import styled from '@emotion/styled';
import { orderTypeTagColors } from 'constants/dashboard';
import PageDrawer from '../PageDrawer';
import { updateAddress } from 'api/Dashboard/addresses';
import { useMutation } from 'react-query';
import POCForm from '../POCForm';
import { ReactComponent as WarehouseIcon } from 'assets/icons/warehouse.svg';
import { ReactComponent as Routing2Icon } from 'assets/icons/routing-2.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as StatusIcon } from 'assets/icons/loading.svg';
import { getMapsURL } from 'utils/location';
import { translatePickupTime } from 'utils/orders';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

const StyledEditIcon = styled(EditIcon)`
  fill: #03989e;
  max-width: 20px;
  max-height: 20px;
  path {
    stroke: #fff;
  }

  path:last-of-type {
    stroke: #03989e;
  }
`;

const DetailsSection = ({ inboundDetails, activeOneItemStatus, onSuccess }) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const hasOrigin = inboundDetails.origin?.id;

  const inboundDetailsItems = [
    {
      icon: <HashtagUpIcon />,
      label: (
        <Text textTransform="uppercase" as="span">
          {t('id')}
        </Text>
      ),
      value: `#${inboundDetails.identifier}`,
    },
    ...(!!activeOneItemStatus
      ? [
          {
            icon: <StatusIcon />,
            label: t('status'),
            value: (
              <StatusTag colorScheme={activeOneItemStatus.color}>
                {t(activeOneItemStatus.label)}
              </StatusTag>
            ),
          },
        ]
      : []),
    {
      icon: <CalendarAddIcon />,
      label: t('requestedAt'),
      value: formatDate(inboundDetails.created_at, 'd/M/yyyy'),
    },
    {
      icon: <CalendarTickIcon />,
      label: t('scheduledOn'),
      value: formatDate(inboundDetails.schedule_at, 'd/M/yyyy'),
    },
    {
      icon: <ClockIcon />,
      label: t('pickupTime'),
      value: translatePickupTime(inboundDetails.time_of_pickup),
    },
    {
      icon: <InboundIcon />,
      label: t('inboundType'),
      value: (
        <StatusTag
          colorScheme={orderTypeTagColors[inboundDetails.inbound_type]}
        >
          {t(`orderCarrier.${inboundDetails.inbound_type}`)}
        </StatusTag>
      ),
    },
    {
      icon: <UserPlusBottomIcon />,
      label: t('supplier'),
      value: inboundDetails.supplier_name,
    },
    {
      icon: <TimerIcon />,
      label: t('overtime'),
      value: `${inboundDetails.overtime ? inboundDetails.overtime : 0} ${t(
        'Hour'
      )}`,
    },
    {
      icon: <UserSquareIcon />,
      label: t('pocName'),
      value: (
        <Flex gap={2}>
          {inboundDetails.poc?.name}
          {hasOrigin && (
            <IconButton
              size="xs"
              variant="unstyled"
              icon={<StyledEditIcon />}
              onClick={onOpen}
            />
          )}
        </Flex>
      ),
    },
    {
      icon: <MobileIcon />,
      label: t('pocNumber'),
      value: (
        <Flex gap={2}>
          {inboundDetails.poc?.phone_number}
          {hasOrigin && (
            <IconButton
              size="xs"
              variant="unstyled"
              icon={<StyledEditIcon />}
              onClick={onOpen}
            />
          )}
        </Flex>
      ),
    },
  ];

  const inboundDetailsOrigin = inboundDetails.origin ?? {};
  const inboundDetailsDestination = inboundDetails.destination ?? {};
  const inboundDetailsWarehouseName = inboundDetails.warehouse_name;

  const tripInformationDetails = [
    {
      icon: <WarehouseIcon />,
      label: t('warehouse'),
      value: (
        <Flex gap={2}>
          <Text noOfLines={1} title={inboundDetailsWarehouseName}>
            {inboundDetailsWarehouseName}
          </Text>
          <Box
            flex={1}
            as="a"
            target="_blank"
            href={getMapsURL(inboundDetailsDestination)}
          >
            <LocationIcon />
          </Box>
        </Flex>
      ),
    },
    {
      icon: <Routing2Icon width="24px" />,
      label: t('originAddress'),
      value: (
        <Flex gap={2}>
          <Text noOfLines={1} title={inboundDetailsOrigin.name}>
            {inboundDetailsOrigin.name}
          </Text>
          <Box
            flex={1}
            as="a"
            target="_blank"
            href={getMapsURL(inboundDetailsOrigin)}
          >
            <LocationIcon />
          </Box>
        </Flex>
      ),
    },
  ];

  const pocFormDefaultValues = {
    id: inboundDetails.poc?.id,
    name: inboundDetails.poc?.name,
    phone_number: inboundDetails.poc?.phone_number,
  };

  const { mutate: updatePOC, isLoading: isUpdatePOCLoading } = useMutation(
    updateAddress,
    {
      onSuccess: (success) => {
        onClose();
        onSuccess();
      },
    }
  );

  const handleUpdatePOC = (data) => {
    updatePOC({
      pocs_attributes: [{ ...data, main: true }],
      id: inboundDetails.origin.id,
    });
  };

  return (
    <>
      <Card size="lg">
        <CardHeader>
          <SectionTitle title={t('inboundDetails')} />
        </CardHeader>
        <CardBody>
          <Stack spacing={4}>
            {inboundDetailsItems.map(({ icon, label, value }, index) => (
              <SectionDataItem
                key={index}
                icon={icon}
                label={label}
                value={value}
              />
            ))}
          </Stack>

          <Stack spacing={4} mt={5}>
            <Text fontWeight={600}>{t('tripInformation')}</Text>
            {tripInformationDetails.map(({ icon, label, value }, index) => (
              <SectionDataItem
                key={index}
                icon={icon}
                label={label}
                value={value}
              />
            ))}
          </Stack>
        </CardBody>
      </Card>

      <PageDrawer
        title={t('editPOCDetails')}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        formId="editPOCForm"
        isLoading={isUpdatePOCLoading}
      >
        <POCForm
          defaultValues={pocFormDefaultValues}
          onSubmit={handleUpdatePOC}
        />
      </PageDrawer>
    </>
  );
};

export default DetailsSection;
