import { memo, useCallback, useMemo, useState } from 'react';

import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from '@react-google-maps/api';
import useMapLoader from 'hooks/useMapLoader';
import { riyadhLocation } from 'constants/locations';

const MapWithDirections = ({
  containerStyle = { width: '100%', height: '300px' },
  origin,
  destination,
}) => {
  const { isLoaded } = useMapLoader();

  const [response, setResponse] = useState(null);

  const directionsCallback = useCallback((result, status) => {
    if (result !== null) {
      if (status === 'OK') {
        setResponse(result);
      } else {
        console.log('response: ', result);
      }
    }
  }, []);

  const directionsServiceOptions = useMemo(
    () => ({
      origin,
      destination,
      travelMode: 'DRIVING',
    }),
    [destination, origin]
  );

  const directionsResult = useMemo(() => {
    return {
      directions: response,
    };
  }, [response]);

  if (!isLoaded || !destination || !origin) {
    return null;
  }

  const hasDirections = (location) => location.lat && location.lng;

  return (
    <GoogleMap
      id="map-with-direction"
      mapContainerStyle={containerStyle}
      zoom={2}
      center={hasDirections(origin) ? origin : riyadhLocation}
      options={{
        gestureHandling: !hasDirections(origin) ? 'none' : undefined,
      }}
    >
      {hasDirections(destination) && hasDirections(origin) && (
        <DirectionsService
          options={directionsServiceOptions}
          callback={directionsCallback}
        />
      )}

      {directionsResult.directions && (
        <DirectionsRenderer
          options={{
            ...directionsResult,
            polylineOptions: {
              strokeOpacity: 1,
              strokeColor: '#03989E',
              strokeWeight: 6,
            },
          }}
        />
      )}
    </GoogleMap>
  );
};

export default memo(MapWithDirections);
