import api from 'utils/axios';

export const getUsers = async (searchParams) => {
  try {
    const response = await api(`/merchants/users?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await api(`/merchants/users/${id}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (user) => {
  try {
    const response = await api.post('/merchants/users', { user });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id, user) => {
  try {
    const response = await api.patch(`/merchants/users/${id}`, { user });

    return response.data;
  } catch (error) {
    throw error;
  }
};
