import { useState } from 'react';
import { HStack } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

const Rating = ({ defaultValue = 0, onChange, disabled }) => {
  const [rating, setRating] = useState(defaultValue);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleClick = (index) => {
    if (disabled) {
      return;
    }

    const newRating = index + 1;
    setRating(newRating);
    onChange?.(newRating);
  };

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(-1);
  };

  return (
    <HStack spacing={0}>
      {[...Array(5)].map((_, index) => (
        <StarIcon
          key={index}
          boxSize={10}
          aria-label={`Rate ${index + 1} stars`}
          color={
            index < (hoverIndex !== -1 ? hoverIndex + 1 : rating)
              ? 'yellow.500'
              : 'gray.300'
          }
          onClick={() => handleClick(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          variant="ghost"
          paddingInline={1}
          cursor="pointer"
          pointerEvents={disabled ? 'none' : undefined}
        />
      ))}
    </HStack>
  );
};

export default Rating;
