import { CardHeader as ChakraCardHeader } from '@chakra-ui/react';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';

const CardHeader = ({ title }) => {
  return (
    <ChakraCardHeader pb="0" paddingX={6} pt={6}>
      <SectionTitle title={title} hideDivider />
    </ChakraCardHeader>
  );
};

export default CardHeader;
