import { Tag } from '@chakra-ui/react';

const StatusTag = ({
  colorScheme,
  children,
  size = 'lg',
  fontSize = 'xs',
  borderRadius = 'lg',
  width = 'full',
  color,
  background,
  fontWeight = '600',
}) => (
  <Tag
    size={size}
    variant={colorScheme}
    fontSize={fontSize}
    color={color}
    textTransform="capitalize"
    fontWeight={fontWeight}
    width={width}
    justifyContent="center"
    minW="70px"
    height="30px"
    id="tag"
    whiteSpace="nowrap"
    borderRadius={borderRadius}
    background={background}
  >
    {children}
  </Tag>
);

export default StatusTag;
