import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import Select from 'components/Dashboard/Select';
import { useTranslation } from 'react-i18next';
import { getFormSelectDefaultValue } from 'utils/select';

const BusinessNeeds = ({
  onChange,
  onSubmit,
  defaultValues,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues,
  });

  const watchIsEcommerce = watch('is_ecommerce');

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (name === undefined) {
        return;
      }

      onChange(name, data[name]);
    });

    return () => subscription.unsubscribe();
  }, [defaultValues, onChange, watch]);

  const referralOptions = [
    { label: t('google'), value: 'google' },
    { label: t('instagram'), value: 'instagram' },
    { label: t('twitter'), value: 'twitter' },
    { label: t('linkedin'), value: 'linkedin' },
    { label: t('friendReferral'), value: 'friendReferral' },
    { label: t('myCompanyColleagues'), value: 'myCompanyColleagues' },
  ];

  const firstInboundDateOptions = [
    { label: t('withinNextWeek'), value: 'withinNextWeek' },
    { label: t('withinNextMonth'), value: 'withinNextMonth' },
    {
      label: t('withinNextTwoThreeMonths'),
      value: 'withinNextTwoThreeMonths',
    },
    { label: t('unsure'), value: 'unsure' },
  ];

  const frequencyOptions = [
    {
      label: t('temporarilyForADefiniteTime'),
      value: 'definiteTime',
    },
    {
      label: t('monthly'),
      value: 'monthToMonth',
    },
    {
      label: t('semiAnnually'),
      value: 'semiAnnually',
    },
    {
      label: t('agreementType.annually'),
      value: 'annual',
    },
  ];

  const yesOrNoOptions = [
    {
      label: t('yes'),
      value: 'yes',
    },
    {
      label: t('no'),
      value: 'no',
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="BusinessNeedsForm" noValidate>
      <fieldset disabled={disabled ? 'disabled' : ''}>
        <Stack spacing={6}>
          <Controller
            control={control}
            name="first_inbound_date"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('expectedStartDate')}
                textTransform="none"
                options={firstInboundDateOptions}
                onChange={({ value }) => onChange(value)}
                error={errors?.first_inbound_date}
                defaultValue={getFormSelectDefaultValue(
                  defaultValues.first_inbound_date,
                  firstInboundDateOptions
                )}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="frequency"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('expectedDuration')}
                textTransform="none"
                options={frequencyOptions}
                onChange={({ value }) => onChange(value)}
                error={errors?.frequency}
                defaultValue={getFormSelectDefaultValue(
                  defaultValues.frequency,
                  frequencyOptions
                )}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="transportation"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('requireTransportation')}
                textTransform="none"
                options={yesOrNoOptions}
                onChange={({ value }) => onChange(value)}
                error={errors?.transportation}
                defaultValue={getFormSelectDefaultValue(
                  defaultValues.transportation,
                  yesOrNoOptions
                )}
                required
              />
            )}
          />

          <Controller
            control={control}
            name="is_ecommerce"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('doYouSellYourProductsOnline')}
                textTransform="none"
                options={yesOrNoOptions}
                onChange={({ value }) => onChange(value)}
                error={errors?.is_ecommerce}
                defaultValue={getFormSelectDefaultValue(
                  defaultValues.is_ecommerce,
                  yesOrNoOptions
                )}
                required
              />
            )}
          />

          {watchIsEcommerce === 'yes' && (
            <Controller
              control={control}
              name="ecommerce_fulfillment"
              rules={{ required: 'thisFieldIsRequired' }}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t(
                    'doYouRequireForDirectToConsumerPackagingAndDelivery'
                  )}
                  textTransform="none"
                  options={yesOrNoOptions}
                  onChange={({ value }) => onChange(value)}
                  error={errors?.ecommerce_fulfillment}
                  defaultValue={getFormSelectDefaultValue(
                    defaultValues.ecommerce_fulfillment,
                    yesOrNoOptions
                  )}
                  required
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="referral"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('hearAboutSirdab')}
                textTransform="none"
                placeholder={t('selectAnOption')}
                options={referralOptions}
                onChange={({ value }) => onChange(value)}
                error={errors?.referral}
                defaultValue={getFormSelectDefaultValue(
                  defaultValues.referral,
                  referralOptions
                )}
                required
              />
            )}
          />
        </Stack>
      </fieldset>
    </form>
  );
};

export default BusinessNeeds;
