import Card from './Card';
import { ReactComponent as CartIcon } from 'assets/icons/cart.svg';
import { chartBarBaseConfig } from './const';
import { useTranslation } from 'react-i18next';

const PendingOrders = ({ pendingOrders, pendingOrdersPerWeek }) => {
  const { t } = useTranslation();

  return (
    <Card
      icon={<CartIcon />}
      title={t('pendingOrders')}
      hint={t('pendingOrdersTrendsOverLast4Weeks')}
      data={[
        {
          label: t('inbounds'),
          value: pendingOrders.total_inbounds,
        },
        {
          label: t('outbounds'),
          value: pendingOrders.total_outbounds,
        },
      ]}
      chartData={{
        labels: ['', '', '', ''],
        datasets: [
          {
            label: t('order'),
            data: pendingOrdersPerWeek,
            ...chartBarBaseConfig,
          },
        ],
      }}
    />
  );
};

export default PendingOrders;
