import {
  Stack,
  FormControl,
  FormLabel,
  SimpleGrid,
  Textarea,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PageDrawer from 'components/Dashboard/PageDrawer';
import { useForm } from 'react-hook-form';
import ImageInput from '../ImageInput';
import SKUDetailsInputs from './SKUDetailsInputs';

const SKUForm = ({
  backendErrors,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { handleSubmit, formState, control, watch, setValue, register } = useForm();

  const watchImage = watch('image');

  const errors = { ...formState.errors, ...backendErrors };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id="skuForm">
      <Stack spacing={5}>
        <FormControl>
          <FormLabel textTransform="capitalize">{t('skuImage')}</FormLabel>
          <ImageInput
            watchImage={watchImage}
            setValue={setValue}
            control={control}
          />
        </FormControl>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={5}>
          <SKUDetailsInputs
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </SimpleGrid>
        <FormControl>
          <FormLabel>{t('description')}</FormLabel>
          <Textarea
            placeholder={t('description')}
            minHeight="158px"
            borderRadius="xl"
            bg="gray.50"
            {...register('description')}
          />
        </FormControl>
      </Stack>
    </form>
  );
};

const SKUFormDrawer = ({
  onSubmit,
  isOpen,
  onClose,
  backendErrors,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <PageDrawer
      title={t('skusPages.createNewSKU')}
      isOpen={isOpen}
      onClose={onClose}
      formId="skuForm"
      size="xl"
      isLoading={isLoading}
    >
      <SKUForm
        onSubmit={(values) => {
          const skuImage = values.image;

          const payload = {
            ...values,
            category_ids: values.category_ids?.map(({ value }) => value),
            image: skuImage
              ? {
                  ...skuImage,
                  base64: skuImage.base64.split(',')[1],
                }
              : skuImage,
          };
          onSubmit(payload);
        }}
        backendErrors={backendErrors}
      />
    </PageDrawer>
  );
};

export default SKUFormDrawer;
