import {
  Box,
  Button,
  Card,
  CardBody,
  TableContainer as ChakraTableContainer,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import CardHeader from './CardHeader';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import OrderStatusTag from '../OrderStatusTag';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import useLanguage from 'hooks/useLanguage';

const TableContainer = styled(ChakraTableContainer)`
  td {
    padding-inline: 5px;
  }
  td:nth-of-type(1) {
    padding-inline-start: 0;
    padding-inline-end: 4px;
  }

  td:nth-of-type(2) {
    padding-inline-start: 0;
  }

  td:last-of-type {
    padding-inline-end: 0;
  }

  @media screen and (max-width: 767px) {
    table {
      display: flex;
      flex-direction: column;
      border-collapse: collapse;
      word-wrap: break-word;
    }
    tbody {
      display: block;
    }
    tr {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #ddd;
      padding: 16px 0;

      td {
        display: flex;
        border: 0;
        flex-basis: 50%;
        align-items: center;
        padding: 4px 0;

        #tag {
          width: auto;
        }
      }

      td:nth-of-type(2) {
        justify-content: flex-end;
      }

      td:last-of-type {
        justify-content: flex-end;
      }
    }
  }

  @media screen and (max-width: 340px) {
    td {
      flex-basis: 100% !important;
      justify-content: stretch !important;
    }
  }
`;

const TableCard = ({ icon, title, data, to }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  return (
    <Card borderRadius="xl" height="full">
      <CardHeader
        title={
          <Flex alignItems="center" justifyContent="space-between" flex={1}>
            <Text as="span">{title}</Text>
            <Button
              as={Link}
              to={to}
              textTransform="capitalize"
              size="sm"
              rightIcon={isRTL ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            >
              {t('viewAll')}
            </Button>
          </Flex>
        }
      />

      <CardBody pt={{ base: 0, md: 5 }} paddingX={6}>
        <TableContainer>
          <Table variant="simple" fontSize="14px">
            <Tbody>
              {data.map(
                ({ identifier, carrier_key, schedule_at, status }, index) => (
                  <Tr key={index}>
                    <Td>
                      <Flex alignItems="center" gap="2">
                        {icon && (
                          <Box bg="gray.100" borderRadius="full" p="1">
                            {icon}
                          </Box>
                        )}
                        <Text fontWeight="500">#{identifier}</Text>
                      </Flex>
                    </Td>
                    <Td>{carrier_key && t(`orderCarrier.${carrier_key}`)}</Td>
                    <Td>
                      <Flex alignItems="center" gap="2">
                        <CalendarIcon
                          width="24"
                          height="24"
                          style={{ display: 'inline' }}
                        />
                        <Text color="gray.600">{formatDate(schedule_at)}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <OrderStatusTag status={status} />
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default TableCard;
