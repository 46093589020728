import { Button, CloseButton, Flex, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';

const VersionUpdateToast = ({ onClose }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  return (
    <Stack
      p={4}
      spacing={1}
      bg="primary.700"
      rounded="md"
      color="white"
      shadow="lg"
      fontWeight={500}
      position="relative"
    >
      <CloseButton
        position="absolute"
        left={isAr ? 1 : 'auto'}
        right={isAr ? 'auto' : 1}
        top={3}
        variant="ghost"
        color="white"
        onClick={onClose}
        p={1}
        minW="auto"
        h="auto"
      />

      <Text>{t('newVersionAvailable')}</Text>
      <Text fontSize="sm">{t('pleaseRefreshToUpdate')}</Text>
      <Flex justify="flex-end" mt={2}>
        <Button size="sm" onClick={() => window.location.reload()}>
          {t('refreshPage')}
        </Button>
      </Flex>
    </Stack>
  );
};

export default VersionUpdateToast;
