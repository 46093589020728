import api from 'utils/axios';

export const getNotifications = async () => {
  try {
    const response = await api('/notifications');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getUnreadNotifications = async () => {
  try {
    const response = await api('/notifications?read_at=null');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getNotification = async (id) => {
  try {
    const response = await api(`/notifications/${id}`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
