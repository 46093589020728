import {
  Stack,
  FormControl,
  FormLabel,
  Switch,
  SimpleGrid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getWarehouses } from 'api/Dashboard/warehouses';
import { Controller, useForm } from 'react-hook-form';
import Select from 'components/Dashboard/Select';
import DatePicker from 'components/shared/Inputs/DatePicker';
import {
  inboundStatus,
  inboundTypeEnum,
  outboundStatus,
  outboundTypeEnum,
} from 'constants/dashboard';
import { useQuery } from 'react-query';
import RadioCardGroup from 'components/shared/Inputs/RadioCardGroup/RadioCardGroup';
import { handleMultiSelectChange, selectAllOption } from 'utils/select';

export const exportFormatOptions = [
  {
    label: 'XLSX',
    value: 'xlsx',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
];

const ReportForm = ({ id, onSubmit, reportType }) => {
  const { t } = useTranslation();

  const isSKUs = reportType === 'skus';
  const isInbound = reportType === 'inbounds';

  const defaultValues = {
    end_date: isSKUs ? new Date() : undefined,
    status: [
      isInbound
        ? {
            label: t('complete'),
            value: inboundStatus.complete,
          }
        : {
            label: t('completed'),
            value: outboundStatus.completed,
          },
    ],
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues,
  });

  const isOutbound = reportType === 'outbounds';
  const isSpaceUtilization = reportType === 'space_utilization';

  const isChargeableActivities = reportType === 'chargeable_activities';

  const isInboundOrOutbound = isInbound || isOutbound;
  const showStartAndEndDate =
    isInboundOrOutbound || isSpaceUtilization || isChargeableActivities;

  const watchEndDate = watch('end_date');
  const watchStartDate = watch('start_date');

  const { data: warehouses = {} } = useQuery('warehouses', getWarehouses, {
    refetchOnMount: true,
  });

  const translatedSelectAllOption = {
    ...selectAllOption,
    label: t(selectAllOption.label),
  };

  const warehouseOptions =
    warehouses.data?.map(({ warehouse_id, name }) => ({
      label: name,
      value: warehouse_id,
    })) ?? [];

  const inboundStatusOptions =
    Object.keys(inboundStatus).map((key) => ({
      label: t(`orderStatus.${key}`),
      value: inboundStatus[key],
    })) ?? [];

  const outboundStatusOptions =
    Object.keys(outboundStatus).map((key) => ({
      label: t(`orderStatus.${key}`),
      value: outboundStatus[key],
    })) ?? [];

  const inboundTypeOptions =
    Object.keys(inboundTypeEnum).map((key) => ({
      label: t(`orderCarrier.${key}`),
      value: inboundTypeEnum[key],
    })) ?? [];

  const outboundTypeOptions =
    Object.keys(outboundTypeEnum).map((key) => ({
      label: t(`orderCarrier.${key}`),
      value: outboundTypeEnum[key],
    })) ?? [];

  const reportTypeOptions = [
    { label: t('inbounds'), value: 'Inbounds' },
    { label: t('outbounds'), value: 'Outbounds' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={id} noValidate>
      <Stack spacing={5}>
        <Controller
          control={control}
          name="warehouse_id"
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('warehouse')}
              placeholder={t('warehouse')}
              options={[translatedSelectAllOption, ...warehouseOptions]}
              onChange={(values, actionMeta) => {
                handleMultiSelectChange({
                  values,
                  actionMeta,
                  onChange,
                });
              }}
              value={value}
              error={errors.warehouse_id}
              isMulti
            />
          )}
        />

        {isChargeableActivities && (
          <Controller
            control={control}
            name="movement_type"
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('type')}
                options={reportTypeOptions}
                onChange={(values, actionMeta) => {
                  handleMultiSelectChange({
                    values,
                    actionMeta,
                    onChange,
                  });
                }}
                value={value}
                error={errors.type}
                isMulti
              />
            )}
          />
        )}

        {isInboundOrOutbound && (
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('status')}
                placeholder={t('status')}
                options={[
                  translatedSelectAllOption,
                  ...(isInbound ? inboundStatusOptions : outboundStatusOptions),
                ]}
                onChange={(values, actionMeta) => {
                  handleMultiSelectChange({
                    values,
                    actionMeta,
                    onChange,
                  });
                }}
                value={value}
                error={errors.status}
                defaultValue={defaultValues.status}
                isMulti
              />
            )}
          />
        )}

        {isInboundOrOutbound && (
          <Controller
            control={control}
            name="delivery_option"
            render={({ field: { onChange, value } }) => (
              <Select
                label={t(isInbound ? 'inboundType' : 'outboundType')}
                placeholder={t(isInbound ? 'inboundType' : 'outboundType')}
                options={[
                  translatedSelectAllOption,
                  ...(isInbound ? inboundTypeOptions : outboundTypeOptions),
                ]}
                onChange={(values, actionMeta) => {
                  handleMultiSelectChange({
                    values,
                    actionMeta,
                    onChange,
                  });
                }}
                value={value}
                error={errors.delivery_option}
                isMulti
              />
            )}
          />
        )}

        <SimpleGrid columns={showStartAndEndDate ? 2 : 1} spacing={4}>
          {showStartAndEndDate && (
            <DatePicker
              name="start_date"
              label={t('startDate')}
              maxDate={watchEndDate ?? new Date()}
              register={register}
              setValue={setValue}
              errors={errors}
              required={
                isSpaceUtilization || isChargeableActivities
                  ? 'thisFieldIsRequired'
                  : false
              }
              formValue={watchStartDate}
              control={control}
              size="lg"
            />
          )}

          <DatePicker
            name="end_date"
            label={t(
              isInbound || isOutbound || isSpaceUtilization
                ? 'endDate'
                : isSKUs
                ? 'inventoryDate'
                : 'selectDate'
            )}
            minDate={watchStartDate}
            maxDate={new Date()}
            register={register}
            setValue={setValue}
            errors={errors}
            required={
              isSpaceUtilization || isChargeableActivities
                ? 'thisFieldIsRequired'
                : false
            }
            formValue={watchEndDate}
            defaultValue={isSKUs ? new Date() : undefined}
            control={control}
            size="lg"
          />
        </SimpleGrid>

        {(isInbound || isOutbound) && (
          <FormControl display="flex" gap={2}>
            <Switch
              size="lg"
              colorScheme="primary"
              {...register(isInbound ? 'manifest_skus' : 'outbound_skus')}
            />
            <FormLabel>
              {t(
                isInbound
                  ? 'includeSKUsWithinInbounds'
                  : 'includeSKUsWithinOutbounds'
              )}
            </FormLabel>
          </FormControl>
        )}

        <RadioCardGroup
          name="export_format"
          label={t('exportFormat')}
          options={exportFormatOptions}
          register={register}
          errors={errors}
          defaultValue={exportFormatOptions[0].value}
          columns={2}
        />
      </Stack>
    </form>
  );
};

export default ReportForm;
