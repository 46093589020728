import { Spinner } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getLocalStorage } from 'utils/localStorage';

const Validate = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const magicLinkToken = searchParams.get('token');

  const {
    passwordlessLogin,
    isPasswordlessLoginLoading,
    passwordlessLoginError,
    token,
  } = useAuth();

  useEffect(() => {
    const user = getLocalStorage('user');
    if (token && user) {
      navigate('/');
    }
  }, [navigate, token]);

  useEffect(() => {
    passwordlessLogin({ token: magicLinkToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPasswordlessLoginLoading) {
    <Spinner size="lg" color="primary.500" />;
  }

  return passwordlessLoginError?.response?.data?.error;
};

export default Validate;
