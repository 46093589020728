import { Button, Center, Stack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  getAssociatedMovementsInbounds,
  getAssociatedMovementsOutbounds,
} from 'api/Dashboard/skus';
import ActionsButton from 'components/Dashboard/ActionsButton';
import OrderStatusTag from 'components/Dashboard/OrderStatusTag';
import StatusTag from 'components/Dashboard/StatusTag';
import TablePage from 'components/Dashboard/TablePage';
import { orderTypeTagColors, outboundTypeTagColors } from 'constants/dashboard';
import useTable from 'hooks/useTable';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { ReactComponent as InboundIcon } from 'assets/icons/inbound.svg';
import { ReactComponent as OutboundIcon } from 'assets/icons/outbound.svg';
import styled from '@emotion/styled';
import { handleNotFoundPage } from 'utils/notFoundPage';

const StyledInboundIcon = styled(InboundIcon)`
  path {
    stroke: #fff;
  }
`;

const StyledOutboundIcon = styled(OutboundIcon)`
  path {
    stroke: #fff;
  }
`;

const columnHelper = createColumnHelper();

const inboundSortFields = {
  identifier: 'identifier',
  schedule_at: 'schedule_at',
  status: 'status',
};

const outboundSortFields = {
  identifier: 'identifier',
  schedule_at: 'schedule_at',
  status: 'status',
};

const AssociatedMovements = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: associatedMovementsInbounds = [],
    isLoading: isInboundsLoading,
    onSortingChange: onInboundsSortingChange,
    onPaginationChange: onInboundsPaginationChange,
    pagination: inboundsPagination,
  } = useTable({
    fetch: (searchParams) => getAssociatedMovementsInbounds(id, searchParams),
    fetchKey: ['associated-movements-inbounds', id],
    disableURLParams: true,
    onFetchError: handleNotFoundPage,
  });

  const handleSortingChange = (sortFields, isOutbound) => {
    const sortFn = isOutbound
      ? onOutboundsSortingChange
      : onInboundsSortingChange;

    if (!sortFields.length) {
      sortFn(sortFields);
      return;
    }

    const sortField = sortFields[0];
    const backendSortFields = isOutbound
      ? outboundSortFields
      : inboundSortFields;

    const sortId = backendSortFields[sortField.id] ?? sortField.id;

    sortFn([{ ...sortField, id: sortId }]);
  };

  const inboundColumns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('inbound_type', {
      cell: ({ getValue }) => {
        const status = getValue();
        return (
          <StatusTag colorScheme={orderTypeTagColors[status]}>
            {t(`orderCarrier.${status}`)}
          </StatusTag>
        );
      },
      header: t('inboundType'),
      meta: {
        fitContent: true,
      },
      enableSorting: false,
    }),
    columnHelper.accessor('schedule_at', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('scheduledAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('origin.name', {
      cell: ({ getValue }) => getValue(),
      header: t('origin'),
      enableSorting: false,
    }),
    columnHelper.accessor('inventory.pallets_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('pallets'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory.boxes_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('boxes'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory.bins_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('bins'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory.units_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('units'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => navigate(`/inbounds/${row.original.id}`)}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const {
    data: associatedMovementsOutbounds = [],
    isLoading: isOutboundsLoading,
    onSortingChange: onOutboundsSortingChange,
    onPaginationChange: onOutboundsPaginationChange,
    pagination: outboundsPagination,
  } = useTable({
    fetch: (searchParams) => getAssociatedMovementsOutbounds(id, searchParams),
    fetchKey: ['associated-movements-outbounds', id],
    disableURLParams: true,
    onFetchError: handleNotFoundPage,
  });

  const outboundColumns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('outbound_type', {
      cell: ({ getValue }) => {
        const status = getValue();
        if (!status) {
          return null;
        }
        return (
          <StatusTag colorScheme={outboundTypeTagColors[status]}>
            {t(`orderCarrier.${status}`)}
          </StatusTag>
        );
      },
      header: t('outboundPages.outboundType'),
      meta: {
        fitContent: true,
      },
      enableSorting: false,
    }),
    columnHelper.accessor('schedule_at', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('scheduledAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('destination.name', {
      cell: ({ getValue }) => getValue(),
      header: t('destination'),
      enableSorting: false,
    }),
    columnHelper.accessor('inventory.pallets_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('pallets'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory.boxes_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('boxes'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory.bins_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('bins'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory.units_count', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('units'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => navigate(`/outbounds/${row.original.id}`)}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <Stack spacing={6}>
      <TablePage
        data={associatedMovementsInbounds}
        columns={inboundColumns}
        pagination={inboundsPagination}
        title={t('inboundPages.inboundOrders')}
        tableAction={
          <Button
            leftIcon={<StyledInboundIcon />}
            colorScheme="primary"
            size="lg"
            as={Link}
            to="/inbounds/create"
          >
            {t('inboundPages.requestNewInbound')}
          </Button>
        }
        onSortingChange={(sortFields) => handleSortingChange(sortFields)}
        onPaginationChange={onInboundsPaginationChange}
        isLoading={isInboundsLoading}
        hideSearch
      />

      <TablePage
        data={associatedMovementsOutbounds}
        columns={outboundColumns}
        pagination={outboundsPagination}
        title={t('outboundPages.outboundOrders')}
        tableAction={
          <Button
            leftIcon={<StyledOutboundIcon />}
            colorScheme="primary"
            size="lg"
            as={Link}
            to="/outbounds/create"
          >
            {t('outboundPages.requestNewOutbound')}
          </Button>
        }
        onSortingChange={(sortFields) => handleSortingChange(sortFields, true)}
        onPaginationChange={onOutboundsPaginationChange}
        searchPlaceholder={t('outboundPages.tableSearchPlaceholder')}
        isLoading={isOutboundsLoading}
        hideSearch
      />
    </Stack>
  );
};

export default AssociatedMovements;
