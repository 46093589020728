import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getQuote, approveQuote, updateQuote } from 'api/onboarding';
import {
  Box,
  Center,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import Container from 'components/shared/Container';
import Quote from 'components/Onboarding/Quote/Quote';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { saveToken, getToken } from 'utils/token';

const Lead = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const toast = useToast();

  const location = useLocation();
  const decodedURL = useMemo(
    () => decodeURIComponent(location.search),
    [location.search]
  );

  const searchParams = useMemo(
    () => new URLSearchParams(decodedURL),
    [decodedURL]
  );

  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const quoteId = searchParams.get('quoteId');

  useEffect(() => {
    const savedToken = getToken();

    if (savedToken.token === token) {
      return;
    }
    saveToken({
      newToken: token,
    });
  }, [token]);

  const {
    data: { data, meta } = { data: undefined },
    isLoading,
    refetch,
  } = useQuery(['lead-quote', quoteId], () => getQuote(quoteId));

  const isApproveQuoteAllowed = data?.status === 'pending';

  const services = data?.quote_line_items?.map((quoteLineItem) => ({
    ...quoteLineItem.warehouse_service,
    price_per_unit_cents: quoteLineItem.price_per_unit_cents,
    lineItemId: quoteLineItem.id,
  }));

  const defaultValues = {
    number_of_sqm: data?.number_of_sqm,
    number_of_pallets: data?.number_of_pallets,
  };

  const { mutate: approveQuoteMutation, isLoading: isApproveQuoteLoading } =
    useMutation(approveQuote, {
      onSuccess: (data) => {
        navigate('/sign-up', { state: { quoteId } });
      },
    });

  const { mutate: updateQuoteMutation, isLoading: isUpdateQuoteLoading } =
    useMutation(updateQuote, {
      onSuccess: (data) => {
        toast({
          title: t('quoteUpdatedSuccessfully'),
          status: 'success',
          duration: 3000,
          isClosable: false,
        });
        refetch();
      },
    });

  const handleApproveQuote = () => {
    approveQuoteMutation({
      quoteId,
      quote: {
        id: +quoteId,
        number_of_pallets: +data.number_of_pallets,
        number_of_sqm: +data.number_of_sqm,
        quote_line_items_attributes: data.quote_line_items_attributes,
      },
    });
  };

  const handleUpdateQuote = (data) => {
    updateQuoteMutation({
      quoteId,
      quote: {
        id: +quoteId,
        number_of_pallets: +data.number_of_pallets,
        number_of_sqm: +data.number_of_sqm,
        quote_line_items_attributes: data.quote_line_items_attributes,
      },
    });
  };

  return (
    <Flex width="full" flex={1} justifyContent="center" bg="#F8FAFC" pt={10}>
      <Container>
        <Stack spacing={3} textAlign="center">
          <Heading fontWeight="medium" fontSize="3xl">
            {t('reviewAndApproveQuote')}
          </Heading>
          <Text fontSize="sm" color="gray.500">
            {t('customizeYourSelectedQuota')}
          </Text>
        </Stack>
        <Center paddingBlock={8}>
          <Box p={4} borderRadius="3xl" width="container.md">
            {!isApproveQuoteAllowed && (
              <Text color="gray.600" fontWeight={600}>
                <Trans
                  i18nKey="yourQuoteIsAlreadyApproved"
                  components={[
                    <Link as={RouterLink} to="/sign-up" color="primary.500" />,
                  ]}
                />
              </Text>
            )}

            {data && (
              <Quote
                defaultValues={defaultValues}
                services={services}
                allOtherServices={meta?.services}
                disabled={
                  isApproveQuoteLoading ||
                  !isApproveQuoteAllowed ||
                  isUpdateQuoteLoading
                }
                isLoading={isLoading}
                onApproveQuote={handleApproveQuote}
                onSubmit={handleUpdateQuote}
                ignorePriceRange
              />
            )}
          </Box>
        </Center>
      </Container>
    </Flex>
  );
};

export default Lead;
