import { useTranslation } from 'react-i18next';
import Card from './Card';
import { ReactComponent as StorageIcon } from 'assets/icons/storage.svg';

const StorageOverview = ({ storageOverview }) => {
  const { t } = useTranslation();

  return (
    <Card
      icon={<StorageIcon />}
      title={t('storageOverview')}
      data={[
        {
          label: t('items'),
          value: storageOverview.items,
        },
        {
          label: `${t('total')} ${t('warehouses')}`,
          value: storageOverview.warehouses,
        },
      ]}
    />
  );
};

export default StorageOverview;
