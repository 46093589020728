import { useState, useEffect } from 'react';
import i18n, { handleLayout, setLocalStorageLanguage } from 'utils/i18n';
import { update } from '@intercom/messenger-js-sdk';
import { getAlignment } from 'utils/intercom';

const useLanguage = () => {
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChanged = (newLanguage) => {
      setLanguage(newLanguage);
      setLocalStorageLanguage(newLanguage);
      handleLayout(newLanguage);
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);

    update({
      alignment: getAlignment(),
      language_override: newLanguage,
    });
  };

  return { language, changeLanguage };
};

export default useLanguage;
