import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  FormErrorMessage,
  FormControl,
  Grid,
  GridItem,
  Flex,
} from '@chakra-ui/react';
import Input from 'components/shared/Inputs/Input';
import { useMutation } from 'react-query';
import { verifyBusiness } from 'api/onboarding';
import { validateCR, validateVAT } from 'utils/validation';

const VerifyBusiness = ({ onSuccess, defaultValues = {} }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const {
    mutate: verifyBusinessMutation,
    isLoading: isVerifyBusinessLoading,
    error: verifyBusinessError,
  } = useMutation(verifyBusiness, {
    onSuccess,
  });

  const onSubmit = (data) => {
    verifyBusinessMutation(data);
  };

  const error = verifyBusinessError?.response.data.errors[0];

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Input
            name="cr_number"
            label={t('crNumber')}
            placeholder={`${t('e.g')} 1015985050`}
            register={register}
            defaultValue={defaultValues.cr_number}
            errors={errors}
            type="number"
            validate={validateCR}
            required="thisFieldIsRequired"
            size="lg"
          />
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 1 }}>
          <Input
            name="vat_number"
            label={t('vatNumber')}
            placeholder={`${t('e.g')} 9588045890345984`}
            register={register}
            defaultValue={defaultValues.vat_number}
            errors={errors}
            type="number"
            validate={validateVAT}
            required="thisFieldIsRequired"
            size="lg"
          />
        </GridItem>
      </Grid>

      <Flex width="full" justifyContent="end">
        <Button
          type="submit"
          colorScheme="primary"
          size="lg"
          isLoading={isVerifyBusinessLoading}
          fontWeight="semibold"
        >
          {t('save')}
        </Button>
      </Flex>

      <FormControl isInvalid={error}>
        <FormErrorMessage marginTop={3}>{error}</FormErrorMessage>
      </FormControl>
    </form>
  );
};

export default VerifyBusiness;
