import api from 'utils/axios';

export const getNotificationSubscriptions = async () => {
  try {
    const response = await api('/notification_subscriptions');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserNotificationSubscriptions = async (id) => {
  try {
    const response = await api(`/users/${id}/user_notification_subscriptions`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const createUserNotificationSubscriptions = async (
  id,
  user_notification_subscription
) => {
  try {
    const response = await api.post(
      `/users/${id}/user_notification_subscriptions`,
      user_notification_subscription
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUserNotificationSubscriptions = async (
  id,
  notificationId
) => {
  try {
    const response = await api.delete(
      `/users/${id}/user_notification_subscriptions/${notificationId}`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getBillingAccountDetails = async () => {
  try {
    const response = await api('/merchants/billings/account_details');
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getBillings = async (searchParams) => {
  try {
    const response = await api(`/merchants/billings?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const requestBillingStatement = async () => {
  try {
    const response = await api.post('/merchants/billings/request_statement');
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
