import { useState } from 'react';

const useLinkUnlink = (defaultValue = []) => {
  const [linked, setLinked] = useState(defaultValue);

  const link = (id) => {
    setLinked((prevValue) => [...prevValue, id]);
  };

  const unlink = (id) => {
    setLinked((prevValue) => {
      const prevValueMock = [...prevValue];

      const index = prevValueMock.indexOf(id);
      if (index > -1) {
        prevValueMock.splice(index, 1);
      }

      return [...prevValueMock];
    });
  };

  return { linked, link, unlink, setLinked };
};

export default useLinkUnlink;
