import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Hide,
  Show,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  createUserNotificationSubscriptions,
  deleteUserNotificationSubscriptions,
  getNotificationSubscriptions,
  getUserNotificationSubscriptions,
} from 'api/Dashboard/settings';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getLocalStorage } from 'utils/localStorage';
import { ReactComponent as EmailIcon } from 'assets/icons/sms.svg';
import { ReactComponent as SystemIcon } from 'assets/icons/computer.svg';
import { ReactComponent as WhatsappIcon } from 'assets/icons/whatsapp.svg';

const groupByEventType = (
  notificationSubscriptions,
  userNotificationSubscriptions
) =>
  notificationSubscriptions.reduce((acc, notificationSubscription) => {
    const eventType = notificationSubscription.event_type;

    if (!acc[eventType]) {
      acc[eventType] = [];
    }

    const userNotification = userNotificationSubscriptions.find(
      (userNotificationSubscription) =>
        userNotificationSubscription.event_type ===
          notificationSubscription.event_type &&
        userNotificationSubscription.delivery_type ===
          notificationSubscription.delivery_type
    );

    acc[eventType].push({
      ...notificationSubscription,
      checked: !!userNotification,
      userNotificationId: userNotification?.id,
    });

    return acc;
  }, {});

const NotificationTypeLabel = ({ children, icon, isHorizontal }) => {
  return (
    <Flex
      width={isHorizontal ? 'auto' : '50px'}
      fontSize={isHorizontal ? 'sm' : 'xs'}
      fontWeight={600}
      color="gray.600"
      textTransform="capitalize"
      alignItems="center"
      flexDirection={isHorizontal ? 'row' : 'column'}
      gap={1}
    >
      {icon}
      {children}
    </Flex>
  );
};

const NotificationSubscription = ({
  groupedSubscriptions,
  subscriptionKey,
  onChange,
  isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      justifyContent="space-between"
      alignItems={{ base: 'stretch', md: 'center' }}
      flexDirection={{ base: 'column', md: 'row' }}
      gap={6}
    >
      <Box>
        <Text fontSize="sm" fontWeight={600} textTransform="capitalize">
          {t(`notificationSettings.${subscriptionKey}.title`)}
        </Text>
        <Text fontSize="sm" fontWeight={500} color="gray.600">
          {t(`notificationSettings.${subscriptionKey}.subtitle`)}
        </Text>
      </Box>

      <Flex gap={5} flexDirection={{ base: 'column', md: 'row' }}>
        {groupedSubscriptions[subscriptionKey]?.map(
          ({ id, delivery_type, event_type, checked, userNotificationId }) => {
            const eventDelivery = notificationDeliveryTypes.find(
              (notificationDeliveryType) =>
                notificationDeliveryType.key === delivery_type
            );

            return (
              <Flex key={id} justifyContent="space-between">
                <Show below="md">
                  <NotificationTypeLabel icon={eventDelivery.icon} isHorizontal>
                    {t(eventDelivery.title)}
                  </NotificationTypeLabel>
                </Show>
                <Switch
                  size="lg"
                  colorScheme="primary"
                  isDisabled={delivery_type === 'whatsapp' || isDisabled}
                  name={`${id}-${event_type}-${delivery_type}`}
                  isChecked={checked}
                  onChange={(e) => {
                    onChange({
                      id,
                      event_type,
                      delivery_type,
                      userNotificationId,
                      checked: e.target.checked,
                    });
                  }}
                />
              </Flex>
            );
          }
        )}
      </Flex>
    </Flex>
  );
};

const notificationSettings = [
  'InboundCreatedNotifier',
  'OutboundCreatedNotifier',
  'ManifestCompletedNotifier',
  'DraftManifestNotifier',
  'LowInventoryNotifier',
  'NewCommentNotifier',
  'NearToExpiryInventoryNotifier',
  'ExpiredInventoryNotifier',
];

const notificationDeliveryTypes = [
  // {
  //   key: 'app',
  //   title: 'system',
  //   icon: <SystemIcon />,
  // },
  {
    key: 'email',
    title: 'notificationSettings.email',
    icon: <EmailIcon />,
  },
  {
    key: 'whatsapp',
    title: 'whatsapp',
    icon: <WhatsappIcon />,
  },
];

const NotificationSettings = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const user = getLocalStorage('user') ?? {};

  const {
    data: userNotificationSubscriptions = [],
    isLoading: isUserNotificationSubscriptionsLoading,
    refetch,
    isRefetching,
  } = useQuery('user-notification-subscriptions', () =>
    getUserNotificationSubscriptions(user.id)
  );

  const { data: notificationSubscriptionsData = [], isLoading } = useQuery(
    'notification-subscriptions',
    getNotificationSubscriptions
  );

  const notificationSubscriptions = notificationSubscriptionsData.filter(
    (notificationSubscription) =>
      notificationSubscription.delivery_type !== 'app'
  );

  const handleUpdateSuccess = () => {
    refetch();
    toast({
      title: t('notificationSettingsUpdatedSuccessfully'),
      status: 'success',
      duration: 3000,
    });
  };

  const handleUpdateError = () => {
    refetch();
    toast({
      title: t('notificationSettingsUpdateError'),
      status: 'error',
      duration: 3000,
    });
  };

  const {
    mutate: createUserNotification,
    isLoading: isCreateUserNotificationLoading,
  } = useMutation(
    (payload) => createUserNotificationSubscriptions(user.id, payload),
    {
      onSuccess: handleUpdateSuccess,
      onError: handleUpdateError,
    }
  );

  const {
    mutate: deleteUserNotification,
    isLoading: isDeleteUserNotificationLoading,
  } = useMutation(
    (notificationId) =>
      deleteUserNotificationSubscriptions(user.id, notificationId),
    {
      onSuccess: handleUpdateSuccess,
      onError: handleUpdateError,
    }
  );

  const flatUserNotificationSubscriptions = userNotificationSubscriptions.map(
    (userNotificationSubscription) => ({
      ...userNotificationSubscription.notification_subscription,
      id: userNotificationSubscription.id,
    })
  );

  const groupedNotificationSubscriptions = groupByEventType(
    notificationSubscriptions,
    flatUserNotificationSubscriptions
  );

  const onNotificationChange = ({
    id,
    event_type,
    delivery_type,
    checked,
    userNotificationId,
  }) => {
    if (checked) {
      createUserNotification({
        user_notification_subscription: {
          notification_subscription_id: id,
        },
      });
      return;
    }
    deleteUserNotification(userNotificationId);
  };

  if (isUserNotificationSubscriptionsLoading || isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('notificationSettings.title')} />
      </CardHeader>
      <CardBody>
        <Hide below="md">
          <Flex justifyContent="flex-end" mb={5}>
            <HStack spacing={5}>
              {notificationDeliveryTypes.map(({ icon, title }, index) => (
                <NotificationTypeLabel key={index} icon={icon}>
                  {t(title)}
                </NotificationTypeLabel>
              ))}
            </HStack>
          </Flex>
        </Hide>
        <Stack spacing={5} divider={<StackDivider borderColor="gray.200" />}>
          {notificationSettings.map((notificationSubscription) => (
            <NotificationSubscription
              key={notificationSubscription}
              subscriptionKey={notificationSubscription}
              groupedSubscriptions={groupedNotificationSubscriptions}
              onChange={onNotificationChange}
              isDisabled={
                isCreateUserNotificationLoading ||
                isDeleteUserNotificationLoading ||
                isRefetching
              }
            />
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default NotificationSettings;
