import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from 'components/shared/Inputs/Input';
import { isValidPhoneNumber } from 'libphonenumber-js/max';

const POCForm = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate id="editPOCForm">
      <Stack spacing={6}>
        <Input
          name="name"
          label={t('pocName')}
          register={register}
          errors={errors}
          placeholder={`${t('e.g')}: ${t('nameExample')}`}
          required="thisFieldIsRequired"
          size="lg"
        />
        <Input
          name="phone_number"
          label={t('pocNumber')}
          placeholder={`${t('e.g')}: +966112212222`}
          register={register}
          errors={errors}
          required={'thisFieldIsRequired'}
          validate={(value) => {
            if (!value) {
              return true;
            }

            return (
              isValidPhoneNumber(value, 'SA') || 'validation.invalidNumber'
            );
          }}
          size="lg"
        />
      </Stack>
    </form>
  );
};

export default POCForm;
