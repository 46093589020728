import {
  addSkuToInbound,
  downloadSKULabels,
  editInboundSKU,
  getInboundSKUs,
  removeInboundSKU,
} from 'api/Dashboard/inbounds';
import useTable from 'hooks/useTable';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TablePage from '../TablePage';
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { ReactComponent as DocumentDownloadIcon } from 'assets/icons/document-download.svg';
import { useMutation, useQuery } from 'react-query';
import SKUsTableForm from '../Orders/SKUsTableForm';
import { createSKU } from 'api/Dashboard/skus';
import { adjustToUTC, renderDate } from 'utils/date';
import { flattenErrorObject } from 'utils/formError';
import SKUFormDrawer from '../SKUs/SKUFormDrawer';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import styled from '@emotion/styled';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import EmptyTable from '../EmptyTable';
import { packagingEnum } from 'constants/dashboard';
import Select from '../Select';
import DebouncedNumberInput from 'components/shared/Inputs/DebouncedNumberInput';
import { skuQuantityValidation } from 'constants/validation';
import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { useForm } from 'react-hook-form';

const StyledEditIcon = styled(EditIcon)`
  max-width: 20px;
  max-height: 20px;
  path {
    stroke: #fff;
  }

  path:last-of-type {
    stroke: #fff;
  }
`;

const columnHelper = createColumnHelper();

export const packagingKeysMap = {
  'Pallet wrap': 'pallet',
  Box: 'box',
  Unit: 'unit',
};

const SKUsSection = ({ inboundId, status, onUpdateSuccess }) => {
  const { t } = useTranslation();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  const [editItemID, setEditItemID] = useState(undefined);

  const isDraft = status === 'draft';

  const packagingOptions = Object.keys(packagingEnum).map((key) => ({
    label: t(key),
    value: packagingEnum[key],
  }));

  const onDownloadLabelSuccess = (response) => {
    toast({
      title: response.data.message,
      status: 'success',
      duration: 5000,
      isClosable: false,
    });
  };

  const {
    refetch: downloadSmallLabelsQuery,
    isLoading: isDownloadSmallLabelsLoading,
    isRefetching: isDownloadSmallLabelsRefetching,
  } = useQuery(
    ['download-inbound-small-labels', inboundId],
    () => downloadSKULabels(inboundId, 'small'),
    {
      enabled: false,
      onSuccess: onDownloadLabelSuccess,
    }
  );

  const {
    refetch: downloadLargeLabelsQuery,
    isLoading: isDownloadLargeLabelsLoading,
    isRefetching: isDownloadLargeLabelsRefetching,
  } = useQuery(
    ['download-inbound-large-labels', inboundId],
    () => downloadSKULabels(inboundId, 'large'),
    {
      enabled: false,
      onSuccess: onDownloadLabelSuccess,
    }
  );

  const fetchFn = useCallback(
    (searchParams) => getInboundSKUs(inboundId, searchParams),
    [inboundId]
  );

  const {
    data: createSKUData,
    mutate: createSKUMutation,
    isLoading: isCreateSKULoading,
    error: createSKUErrors,
  } = useMutation(createSKU, {
    onSuccess: () => {
      onClose();
    },
  });

  const { mutate: removeInboundSKUMutation, isLoading: isRemoveSKULoading } =
    useMutation((skuID) => removeInboundSKU({ id: inboundId, skuID }), {
      onSuccess: (data) => {
        refetchSKUs();
      },
    });

  const { mutate: addSkuToInboundMutation, error: addSkuToInboundError } =
    useMutation((payload) => addSkuToInbound(inboundId, payload), {
      onSuccess: (data) => {
        refetch();
        onUpdateSuccess();
      },
    });

  const { mutate: editInboundSKUMutation, isLoading: isEditInboundSKULoading } =
    useMutation(editInboundSKU, {
      onSuccess: (data) => {
        refetch();
      },
    });

  const handleSkuChange = (skuID, inbound_sku) => {
    if (inbound_sku.quantity === 0) {
      return;
    }

    const payload = {
      id: inboundId,
      skuID,
      inbound_sku: {
        merchant_sku_packaging: inbound_sku.packaging,
        merchant_sku_quantity: inbound_sku.quantity,
        merchant_sku_expiry: inbound_sku.expiry_date,
      },
    };

    editInboundSKUMutation(payload);
  };

  const {
    data = [],
    refetch,
    isLoading,
    onSearchChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    refetch: refetchSKUs,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['inbound-skus', inboundId],
    searchKey: 'sku_merchant_sku_or_sku_system_sku_or_sku_name_cont',
    disableURLParams: true,
  });

  const { control, register } = useForm();

  const draftColumns = [
    columnHelper.accessor('expected.packaging', {
      cell: ({ getValue, row }) => {
        const {
          id,
          expected: { quantity, expiry_date },
        } = row.original;

        if (id === editItemID) {
          const value = getValue();

          const isPallet = value === 'Pallet wrap';

          const defaultValue = {
            label: t(value),
            value:
              packagingEnum[isPallet ? 'pallet' : value.toLocaleLowerCase()],
          };

          return (
            <Select
              placeholder={t('packagingType')}
              options={packagingOptions}
              onChange={({ value }) => {
                handleSkuChange(id, {
                  packaging: value,
                  quantity,
                  expiry_date,
                });
              }}
              isDisabled={isEditInboundSKULoading}
              defaultValue={defaultValue}
            />
          );
        }
        return (
          <Text fontWeight={500} textTransform="capitalize">
            {t(getValue())}
          </Text>
        );
      },
      header: t('expectedPackaging'),
      enableSorting: false,
    }),
    columnHelper.accessor('expected.quantity', {
      cell: ({ getValue, row }) => {
        const {
          id,
          expected: { packaging, expiry_date },
        } = row.original;

        if (id === editItemID) {
          const defaultValue = getValue();

          return (
            <DebouncedNumberInput
              placeholder="quantity"
              name="quantity"
              defaultValue={defaultValue}
              min={skuQuantityValidation.min}
              onChange={(value) => {
                const packagingKey = packagingKeysMap[packaging];

                handleSkuChange(id, {
                  packaging: packagingEnum[packagingKey],
                  quantity: value,
                  expiry_date: expiry_date,
                });
              }}
              isDisabled={isEditInboundSKULoading}
            />
          );
        }
        return <Text fontWeight={500}>{getValue()}</Text>;
      },
      header: t('expectedQuantity'),
      enableSorting: false,
    }),
    columnHelper.accessor('expected.expiry_date', {
      cell: ({ getValue, row }) => {
        const {
          id,
          expected: { packaging, quantity },
        } = row.original;

        if (id === editItemID) {
          const colValue = getValue();
          const defaultValue = colValue === 'N/A' ? undefined : colValue;

          return (
            <DatePicker
              name={`expiry_date.${id}`}
              register={register}
              errors={{}}
              defaultValue={defaultValue}
              control={control}
              placeholder={t('selectExpiryDate')}
              onChange={(value) => {
                const packagingKey = packagingKeysMap[packaging];

                handleSkuChange(id, {
                  packaging: packagingEnum[packagingKey],
                  quantity: quantity,
                  expiry_date: adjustToUTC(value),
                });
              }}
              isDisabled={isEditInboundSKULoading}
              appendPopperToParent
            />
          );
        }
        return <Text fontWeight={500}>{renderDate(getValue())}</Text>;
      },
      header: t('expectedExpiry'),
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        const id = row.original.id;

        const handleDelete = () => {
          removeInboundSKUMutation(row.original.id);
        };

        const handleEdit = () => {
          setEditItemID((prevValue) => (prevValue === id ? undefined : id));
        };

        if (isMobile) {
          return (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<DotsVerticalIcon />}
                size="sm"
              />
              <MenuList>
                <MenuItem textTransform="capitalize" onClick={handleEdit}>
                  {t('edit')}
                </MenuItem>
                <MenuItem
                  textTransform="capitalize"
                  color="red.500"
                  onClick={handleDelete}
                >
                  {t('remove')}
                </MenuItem>
              </MenuList>
            </Menu>
          );
        }

        return (
          <fieldset disabled={isRemoveSKULoading ? 'disabled' : undefined}>
            <Flex gap={3} justifyContent="center">
              <Button
                variant="outline"
                color="gray.600"
                textTransform="capitalize"
                fontWeight={500}
                onClick={handleEdit}
              >
                {t('edit')}
              </Button>
              <Button
                variant="outline"
                color="red.600"
                textTransform="capitalize"
                fontWeight={500}
                onClick={handleDelete}
              >
                {t('remove')}
              </Button>
            </Flex>
          </fieldset>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const viewColumns = [
    columnHelper.accessor('', {
      cell: ({ row }) => {
        const { expected, received } = row.original;

        return (
          <Flex flexDirection="row" gap={4} justifyContent="space-between">
            <Flex flexDirection="column" gap={2}>
              <Text>{`${expected.quantity} ${expected.packaging}`}</Text>
              <Text>{`${received.quantity} ${received.packaging}`}</Text>
            </Flex>

            <Flex flexDirection="column" gap={2}>
              <Text
                textTransform="uppercase"
                color="purple.500"
                fontWeight={500}
              >
                {t('expected')}
              </Text>
              <Text
                textTransform="uppercase"
                color="green.500"
                fontWeight={500}
              >
                {t('received')}
              </Text>
            </Flex>
          </Flex>
        );
      },
      header: t('quantityPackaging'),
      meta: {
        fitContent: true,
        centerHeader: true,
      },
    }),
    columnHelper.accessor('received.expiry_date', {
      cell: ({ row }) => {
        const { expected, received } = row.original;

        return (
          <Flex flexDirection="column" gap={2}>
            <Text>{renderDate(expected.expiry_date)}</Text>
            <Text>{renderDate(received.expiry_date)}</Text>
          </Flex>
        );
      },
      header: t('expiryDate'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('storage.unit', {
      cell: ({ getValue }) => <Center>{t(getValue())}</Center>,
      header: t('storedAs'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('inventory_quantity', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('noInventories'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('cases_pallets', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('casesPallets'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('units_cases', {
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      header: t('unitsCase'),
      enableSorting: false,
      meta: {
        centerHeader: true,
      },
    }),
  ];

  const columns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => getValue(),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
        fitContent: true,
      },
    }),
    columnHelper.accessor('sku_system_sku', {
      cell: ({ getValue }) => getValue(),
      header: t('sirdabSKU'),
    }),
    ...(isDraft ? draftColumns : viewColumns),
  ];

  const handleSkuSubmit = (sku, onSuccessCallback) => {
    addSkuToInboundMutation(
      {
        ...sku,
        expiry_date: adjustToUTC(sku.expiry_date),
      },
      {
        onSuccess: onSuccessCallback,
      }
    );
  };

  return (
    <TablePage
      data={data}
      columns={columns}
      tableAction={
        <>
          <Flex gap={4} flexWrap="wrap">
            <Menu>
              <MenuButton
                as={Button}
                colorScheme="primary"
                leftIcon={<DocumentDownloadIcon />}
                isLoading={
                  isDownloadSmallLabelsLoading ||
                  isDownloadLargeLabelsLoading ||
                  isDownloadSmallLabelsRefetching ||
                  isDownloadLargeLabelsRefetching
                }
                size="lg"
              >
                {t('downloadLabels')}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => downloadSmallLabelsQuery()}>
                  {t('download2X1.5Label')}
                </MenuItem>
                <MenuItem onClick={() => downloadLargeLabelsQuery()}>
                  {t('download4X6Label')}
                </MenuItem>
              </MenuList>
            </Menu>
            {isDraft && (
              <Button
                leftIcon={<StyledEditIcon />}
                colorScheme="primary"
                textTransform="capitalize"
                size="lg"
                onClick={onOpen}
              >
                {t('skusPages.createNewSKU')}
              </Button>
            )}
          </Flex>
          <SKUFormDrawer
            isOpen={isOpen}
            onClose={onClose}
            backendErrors={flattenErrorObject(
              createSKUErrors?.response.data.errors
            )}
            isLoading={isCreateSKULoading}
            onSubmit={(payload) => createSKUMutation(payload)}
          />
        </>
      }
      pagination={pagination}
      title={<SectionTitle title={t('inboundSKUs')} hideDivider />}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('skusPages.searchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      footer={
        <Box pb={6} mt={2}>
          {!isCreateSKULoading && isDraft && (
            <SKUsTableForm
              onSubmit={handleSkuSubmit}
              defaultValue={{
                sku_id: createSKUData?.data.id,
              }}
              backendErrors={addSkuToInboundError?.response?.data?.errors}
            />
          )}
        </Box>
      }
      emptyTable={
        <EmptyTable
          illustration={<SKUsEmptyTable />}
          title={t('noSKUsYet')}
          subtitle={`${t('createNewSKU')} ${t('or')} ${t('selectExisting', {
            value: t('theInbound'),
          })}`}
          noPadding
        />
      }
      showEmptyInsideBody
    />
  );
};

export default SKUsSection;
