import {
  Box,
  Stack,
  Show,
  Flex,
  Hide,
  Button,
  Grid,
  GridItem,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  addSkuToOutbound,
  cancelOutbound,
  getOutboundById,
  getOutboundSKUs,
  removeOutboundSKU,
  submitOutboundForReview,
  updateOutbound,
} from 'api/Dashboard/outbounds';
import BackToListButton from 'components/Dashboard/BackToListButton';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import EmptyTable from 'components/Dashboard/EmptyTable';
import AttachmentsSection from 'components/Dashboard/Orders/AttachmentsSection';
import SKUsTableForm from 'components/Dashboard/Orders/SKUsTableForm';
import TablePage from 'components/Dashboard/TablePage';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import { AbilityContext } from 'context/AbilityContext';
import useTable from 'hooks/useTable';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { ReactComponent as SKUsEmptyTable } from 'assets/images/skus-empty-table.svg';
import { handleNotFoundPage } from 'utils/notFoundPage';

const columnHelper = createColumnHelper();

const OutboundAssign = () => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const ability = useContext(AbilityContext);
  const canConfirm = ability.can('confirm', 'outbound');

  const fetchFn = useCallback(
    (searchParams) => getOutboundSKUs(id, searchParams),
    [id]
  );

  const {
    data: skus = [],
    isLoading,
    onSearchChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    refetch: refetchSKUs,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['outbound-skus', id],
    searchKey: 'sku_merchant_sku_or_sku_system_sku_or_sku_name_cont',
    disableURLParams: true,
  });

  const { data: outboundDetails = {}, refetch: refetchOutboundDetails } =
    useQuery(['outbound-details', id], () => getOutboundById(id), {
      onError: handleNotFoundPage,
    });

  const { mutate: removeOutboundSKUMutation, isLoading: isRemoveSKULoading } =
    useMutation((skuID) => removeOutboundSKU({ id, skuID }), {
      onSuccess: (data) => {
        refetchSKUs();
      },
    });

  const { mutate: confirmOutbound, isLoading: isConfirmOutboundLoading } =
    useMutation(
      async () => {
        await updateOutbound(id, {
          status: 'created',
        });
      },
      {
        onSuccess: () => {
          navigate(`/outbounds/${id}`);
        },
        onError: (error) => {
          const errorMsg = error.response.data.errors.status[0];

          toast({
            title: errorMsg,
            status: 'error',
            duration: 5000,
          });

          navigate(`/outbounds/${id}/edit`);
        },
      }
    );

  const { mutate: submitOutbound, isLoading: isSubmitOutboundLoading } =
    useMutation(() => submitOutboundForReview(id), {
      onSuccess: () => {
        navigate(`/outbounds/${id}`);
      },
    });

  const { mutate: cancelOutboundMutation, isLoading: isCancelOutboundLoading } =
    useMutation(() => cancelOutbound(id), {
      onSuccess: (success) => {
        navigate(`/outbounds/${id}`);
      },
    });

  const columns = [
    columnHelper.accessor('sku_name', {
      cell: ({ getValue }) => getValue(),
      header: t('skuName'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
        fitContent: true,
      },
    }),
    columnHelper.accessor('requested.packaging', {
      cell: ({ getValue }) => getValue(),
      header: t('requestedPackaging'),
    }),
    columnHelper.accessor('requested.quantity', {
      cell: ({ getValue }) => getValue(),
      header: t('requestedQuantity'),
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => (
        <Button
          variant="outline"
          color="red.600"
          textTransform="capitalize"
          fontWeight={500}
          onClick={() => removeOutboundSKUMutation(row.original.id)}
          isDisabled={isRemoveSKULoading}
          size="sm"
        >
          {t('remove')}
        </Button>
      ),
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const { mutate: addSkuToOutboundMutation, error: addSkuToOutboundError } =
    useMutation((payload) => addSkuToOutbound(id, payload), {
      onSuccess: (data) => {
        refetchSKUs();
      },
    });

  const handleSkuSubmit = (sku, onSuccessCallback) => {
    addSkuToOutboundMutation(sku, { onSuccess: onSuccessCallback });
  };

  return (
    <Stack spacing={6} pb={16}>
      <Box>
        <Show below="md">
          <Box mb={2} mt={4}>
            <PageTitle title={pageTitle} fontWeight={600} />
          </Box>
        </Show>
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Hide below="md">
            <BackToListButton
              title={t('outboundDetails')}
              url={`/outbounds/${id}/edit`}
            />
          </Hide>
        </Flex>
      </Box>
      <TablePage
        data={skus}
        columns={columns}
        pagination={pagination}
        title={<SectionTitle title={t('outboundSKUs')} hideDivider />}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        searchPlaceholder={t('skusPages.searchPlaceholder')}
        onSearchChange={onSearchChange}
        isLoading={isLoading}
        footer={
          <Box pb={6} mt={2}>
            <SKUsTableForm
              onSubmit={handleSkuSubmit}
              outboundId={id}
              backendErrors={addSkuToOutboundError?.response?.data?.errors}
            />
          </Box>
        }
        emptyTable={
          <EmptyTable
            illustration={<SKUsEmptyTable />}
            title={t('noSKUsYet')}
            subtitle={`${t('selectExisting', {
              value: t('theOutbound'),
            })}`}
            noPadding
          />
        }
        showEmptyInsideBody
      />

      {!isLoading && (
        <AttachmentsSection
          id={id}
          attachments={outboundDetails.attachments}
          onSuccess={refetchOutboundDetails}
          attachmentType="Outbound"
        />
      )}
      <CreateButtonsContainer>
        <Box
          as="fieldset"
          disabled={
            isCancelOutboundLoading || isConfirmOutboundLoading
              ? 'disabled'
              : undefined
          }
          flex={1}
        >
          <Grid templateColumns="repeat(6, 1fr)" gap={4}>
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Button
                colorScheme="gray"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                width="full"
                as={Link}
                to={`/outbounds/${id}`}
              >
                {t('saveDraft')}
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 2 }}>
              <Button
                colorScheme="red"
                variant="outline"
                bgColor="white"
                size="lg"
                minWidth="124px"
                width="full"
                onClick={cancelOutboundMutation}
                isLoading={isCancelOutboundLoading}
              >
                {t('cancelOutbound')}
              </Button>
            </GridItem>
            <GridItem colSpan={{ base: 6, md: 2 }}>
              <Button
                colorScheme="primary"
                size="lg"
                minWidth="124px"
                textTransform="capitalize"
                width="full"
                onClick={canConfirm ? confirmOutbound : submitOutbound}
                isLoading={
                  canConfirm
                    ? isConfirmOutboundLoading
                    : isSubmitOutboundLoading
                }
                isDisabled={skus.length === 0}
              >
                {t(canConfirm ? 'confirm' : 'submitForReview')}
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </CreateButtonsContainer>
    </Stack>
  );
};

export default OutboundAssign;
