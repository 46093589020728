import { Heading } from '@chakra-ui/react';

const PageTitle = ({ title, wrap, ...props }) => {
  return (
    <Heading
      display={{ base: 'inline', md: 'none' }}
      fontSize="2xl"
      whiteSpace={wrap ? 'normal' : 'nowrap'}
      textTransform="capitalize"
      {...props}
    >
      {title}
    </Heading>
  );
};

export default PageTitle;
