import { Box, Stack, Text, useToast } from '@chakra-ui/react';
import { approveQuote, getQuote, updateQuote } from 'api/onboarding';
import BackToListButton from 'components/Dashboard/BackToListButton';
import Quote from 'components/Onboarding/Quote/Quote';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { handleNotFoundPage } from 'utils/notFoundPage';

const ReviewQuote = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const warehousesSearch = location.state?.warehousesSearch;

  const {
    data: { data, meta } = { data: undefined },
    isLoading,
    refetch,
  } = useQuery(['quote', id], () => getQuote(id), {
    onError: handleNotFoundPage,
  });

  const { mutate: approveQuoteMutation, isLoading: isApproveQuoteLoading } =
    useMutation(approveQuote, {
      onSuccess: (data) => {
        navigate('/warehouses/success');
      },
    });

  const { mutate: updateQuoteMutation, isLoading: isUpdateQuoteLoading } =
    useMutation(updateQuote, {
      onSuccess: (data) => {
        toast({
          title: t('quoteUpdatedSuccessfully'),
          status: 'success',
          duration: 3000,
          isClosable: false,
        });
        refetch();
      },
    });

  const isApproveQuoteAllowed = data?.status === 'pending';

  const services = data?.quote_line_items?.map((quoteLineItem) => ({
    ...quoteLineItem.warehouse_service,
    price_per_unit_cents: quoteLineItem.price_per_unit_cents,
    lineItemId: quoteLineItem.id,
  }));

  const defaultValues = {
    number_of_sqm: data?.number_of_sqm,
    number_of_pallets: data?.number_of_pallets,
  };

  const handleApproveQuote = (data) => {
    approveQuoteMutation({
      quoteId: id,
      quote: {
        id: +id,
        number_of_pallets: +data.number_of_pallets,
        number_of_sqm: +data.number_of_sqm,
        quote_line_items_attributes: data.quote_line_items_attributes,
      },
    });
  };

  const handleUpdateQuote = (data) => {
    updateQuoteMutation({
      quoteId: id,
      quote: {
        id: +id,
        number_of_pallets: +data.number_of_pallets,
        number_of_sqm: +data.number_of_sqm,
        quote_line_items_attributes: data.quote_line_items_attributes,
      },
    });
  };

  const handleBackToPrevPage = () => {
    if (warehousesSearch) {
      navigate('/warehouses', {
        state: { warehousesSearch },
      });

      return;
    }

    navigate('/warehouses/quotations');
  };

  return (
    <Stack spacing={6}>
      <Box>
        <BackToListButton
          title={t('listOfWarehouses')}
          onClick={() => handleBackToPrevPage()}
        />
      </Box>

      <Box p={{ base: 4, md: 6 }} borderRadius="2xl">
        <Stack mb={4} spacing={2}>
          <Text
            fontSize="lg"
            fontWeight={600}
            textTransform="capitalize"
            flex={1}
          >
            {t('reviewAndApproveQuote')}
          </Text>
          <Text fontSize="sm" fontWeight={500} color="gray.600">
            {t('customizeYourSelectedQuota')}
          </Text>
        </Stack>

        {data && (
          <Quote
            defaultValues={defaultValues}
            services={services}
            allOtherServices={meta?.services}
            disabled={
              isApproveQuoteLoading ||
              !isApproveQuoteAllowed ||
              isUpdateQuoteLoading
            }
            isLoading={isLoading}
            onApproveQuote={handleApproveQuote}
            onSubmit={handleUpdateQuote}
            onCancel={handleBackToPrevPage}
            noScroll
          />
        )}
      </Box>
    </Stack>
  );
};

export default ReviewQuote;
