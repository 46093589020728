import { selectAllOption } from './select';

export const handleMultiValuesPayload = (multiValues) => {
  const payload =
    !multiValues?.length || multiValues?.[0].value === selectAllOption.value
      ? undefined
      : multiValues?.map((option) => option.value);

  return payload;
};
