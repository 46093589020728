import OnboardingNavbar from 'components/Navbar/OnboardingNavbar';
import { Outlet } from 'react-router-dom';

const OnboardingLayout = () => {
  return (
    <>
      <OnboardingNavbar isSticky />
      <Outlet />
    </>
  );
};

export default OnboardingLayout;
