import { Box, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccountIcon } from 'assets/icons/user.svg';
import { ReactComponent as UserManagementIcon } from 'assets/icons/user-settings.svg';
import { ReactComponent as PushNotificationsIcon } from 'assets/icons/bell.svg';
import { ReactComponent as ReceiptTextIcon } from 'assets/icons/receipt-text.svg';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { getLocalStorage } from 'utils/localStorage';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

const SettingsTab = ({ children, icon: Icon, path, isStroke }) => {
  return (
    <Tab
      display="flex"
      gap={2}
      fontSize="sm"
      color="gray.600"
      textTransform="capitalize"
      fontWeight={500}
      as={Link}
      to={path}
      sx={{
        whiteSpace: 'nowrap',
        svg: {
          width: '24px',
          height: '24px',
        },
      }}
      _selected={{
        fontWeight: 600,
        color: 'primary.500',
        bg: 'primary.50',
        borderRadius: 'lg',
        path: {
          fill: isStroke ? '' : 'primary.500',
          stroke: isStroke ? 'primary.500' : '',
        },
      }}
    >
      {Icon}
      {children}
    </Tab>
  );
};

const settingTabs = [
  {
    title: 'account',
    icon: <AccountIcon />,
    path: '',
  },
  {
    title: 'organization',
    icon: <SettingsIcon />,
    path: '/organization',
    isStroke: true,
  },
  {
    title: 'usersManagement',
    icon: <UserManagementIcon />,
    path: '/user-management',
    adminsOnly: true,
  },
  {
    title: 'billing',
    icon: <ReceiptTextIcon />,
    path: '/billing',
    adminsOnly: true,
  },
  {
    title: 'notifications',
    icon: <PushNotificationsIcon />,
    path: '/push-notifications',
    isStroke: true,
  },
];

const getActiveTabIndex = (tabs, path) =>
  tabs.findIndex((tab) => `/settings${tab.path}` === path);

const Settings = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = getLocalStorage('user') ?? {};

  const tabs = settingTabs.filter(
    (tab) => !tab.adminsOnly || user.role === 'admin'
  );

  return (
    <Tabs
      variant="unstyled"
      bg="white"
      borderTop="1px"
      borderColor="gray.200"
      index={getActiveTabIndex(tabs, pathname)}
      isLazy
    >
      <TabList
        paddingY={4}
        paddingX={{ base: 4, md: 8 }}
        gap={2}
        overflowX="auto"
      >
        {tabs.map(({ icon, path, title, isStroke }) => (
          <SettingsTab
            key={title}
            icon={icon}
            path={`/settings${path}`}
            isStroke={isStroke}
          >
            {t(title)}
          </SettingsTab>
        ))}
      </TabList>
      <Box py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }} bg="gray.50">
        <Outlet />
      </Box>
    </Tabs>
  );
};

export default Settings;
