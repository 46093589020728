import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from 'locales/en.json';
import translationAR from 'locales/ar.json';

const LOCAL_STORAGE_KEY = 'selectedLanguage';

export const getLocalStorageLanguage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEY) ?? 'ar';

export const setLocalStorageLanguage = (newLanguage) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, newLanguage);
};

export const handleLayout = (newLanguage) => {
  document.documentElement.lang = newLanguage;
  document.documentElement.dir = newLanguage === 'ar' ? 'rtl' : 'ltr';
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
  },
  lng: getLocalStorageLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

handleLayout(getLocalStorageLanguage());

export default i18n;
