import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import sirdabLogo from 'assets/images/Sirdab_Logo-01.png';
import Container from 'components/shared/Container';
import LanguageSelect from 'components/shared/Inputs/LanguageSelect';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

const OnboardingNavbar = ({ isSticky = false }) => {
  const { t } = useTranslation();

  const { signOut } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const links = [
    {
      label: t('home'),
      href: 'https://www.sirdab.co',
    },
    {
      label: t('blog'),
      href: 'https://www.sirdab.co/blogar',
    },
    {
      label: t('faq'),
      href: 'https://www.sirdab.co/#faq',
    },
  ];

  const handleSignOut = () => {
    signOut();
  };

  const NavLinks = (
    <>
      {links.map((link) => (
        <Link
          as="a"
          target="_blank"
          sx={{
            textWrap: 'nowrap',
            paddingY: 3,
          }}
          key={link.label}
          href={link.href}
        >
          {link.label}
        </Link>
      ))}
      <Box>
        <LanguageSelect />
      </Box>
      <Button colorScheme="primary" onClick={handleSignOut}>
        {t('logout')}
      </Button>
    </>
  );

  return (
    <Box
      sx={{
        bg: 'white',
        borderBottom: '1px solid #e6e6e6',
        position: isSticky ? 'sticky' : 'fixed',
        top: 0,
        left: 0,
        width: 'full',
        zIndex: 1402,
      }}
    >
      <Container>
        <Flex alignItems="center" justifyContent="space-between" py={3}>
          <Flex alignItems="center" justifyContent="space-between" width="full">
            <Box>
              <Image
                src={sirdabLogo}
                alt="Sirdab Logo"
                height={{ base: '25px', md: '30px' }}
              />
            </Box>
            <HStack
              as="nav"
              spacing={{ md: 3, lg: 4 }}
              display={{ base: 'none', md: 'flex' }}
            >
              {NavLinks}
            </HStack>
          </Flex>
          <IconButton
            size="md"
            icon={
              isOpen ? <CloseIcon boxSize="5" /> : <HamburgerIcon boxSize="8" />
            }
            aria-label="Open Menu"
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
            variant="unstyled"
          />
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as="nav" spacing={3}>
              {NavLinks}
            </Stack>
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default OnboardingNavbar;
