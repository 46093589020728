import slide1 from 'assets/images/registration.png';

const ImageOverlay = ({ title, content }) => {
  return (
    <div className="relative hidden w-0 flex-1 lg:block max-w-[40%] rounded-[2.5rem] overflow-hidden">
      <img
        className="absolute inset-0 h-full w-full object-cover"
        src={slide1}
        alt="slide"
      />
      <div className="absolute px-12 pb-40 inset-0 flex flex-col justify-end text-white bg-black bg-opacity-20 p-4">
        <h2 className="text-3xl font-semibold mb-4">{title}</h2>
        <p className="text-lg">{content}</p>
      </div>
    </div>
  );
};

export default ImageOverlay;
