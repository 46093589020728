import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getPageName, logEvent } from 'utils/amplitude';
import useLanguage from './useLanguage';

const mapTableParams = (params) => {
  if (!params) {
    return {};
  }

  const { page, per_page, ...restParams } = params;

  return {
    ...restParams,
    ...(page && { table_page: page }),
    ...(per_page && { table_rows_per_page: per_page }),
  };
};

export const useAmplitudePageTracking = () => {
  const location = useLocation();
  const isFirstRender = useRef(true);
  const { language } = useLanguage();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const pageName = getPageName(location.pathname, location.search);
    const pageSearch = Object.fromEntries(new URLSearchParams(location.search));
    const pageReferrer = document.referrer;

    const eventProps = {
      page_name: pageName,
      page_language: language,
      page_domain: window.location.hostname,
      page_location: window.location.href,
      page_path: location.pathname,
      page_URL: window.location.host,
      referrer: pageReferrer.length ? pageReferrer : undefined,
      referring_domain: pageReferrer.split('/')[2],
      ...mapTableParams(pageSearch),
    };

    logEvent('Page Viewed', eventProps);
  }, [language, location.hostname, location.pathname, location.search]);
};
