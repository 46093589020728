import i18n from './i18n';

export const mapSelectListToFormOptions = (selectList) =>
  selectList?.map(([label, value]) => ({
    label,
    value,
  }));

export const getFormSelectDefaultValue = (value, options) =>
  options?.find((item) => item.value === value);

export const selectAllOption = {
  label: 'selectAll',
  value: 'all',
};

export const handleMultiSelectChange = ({ values, actionMeta, onChange }) => {
  const translatedSelectAllOption = {
    ...selectAllOption,
    label: i18n.t(selectAllOption.label),
  };

  if (actionMeta.option?.value === selectAllOption.value) {
    onChange([translatedSelectAllOption]);
    return;
  }

  const valuesWithoutSelectAll = values.filter(
    (value) => value.value !== selectAllOption.value
  );

  onChange(valuesWithoutSelectAll);
};
