const RegistrationHeader = ({ title, subtitle }) => (
  <div>
    <h1 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
      {title}
    </h1>
    {!!subtitle && <p className="text-gray-500 font-medium mt-2">{subtitle}</p>}
  </div>
);

export default RegistrationHeader;
