import {
  Stack,
  Box,
  Show,
  Flex,
  Hide,
  Grid,
  GridItem,
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  FormErrorMessage,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import BackToListButton from 'components/Dashboard/BackToListButton';
import CreateButtonsContainer from 'components/Dashboard/CreateButtonsContainer';
import ImageInput from 'components/Dashboard/ImageInput';
import SKUDetailsInputs from 'components/Dashboard/SKUs/SKUDetailsInputs';
import PageTitle from 'components/Layouts/DashboardLayout/PageTitle';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Input from 'components/shared/Inputs/Input';
import OptionalIndicator from 'components/shared/OptionalIndicator';
import { packagingEnum } from 'constants/dashboard';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import Select from '../Select';
import { getFormSelectDefaultValue } from 'utils/select';
import { useEffect } from 'react';

const SKUFormPage = ({
  backendErrors,
  onSubmit,
  isLoading,
  defaultValues = { value: 0 },
}) => {
  const { t } = useTranslation();
  const { pageTitle } = useOutletContext();

  const {
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    register,
    unregister,
  } = useForm({ defaultValues });

  const watchImage = watch('image');
  const isExpiry = watch('is_expiry');

  const packagingOptions = [
    {
      label: t('box'),
      value: 0,
    },
    {
      label: t('unit'),
      value: 1,
    },
  ];

  const errors = {
    ...formState.errors,
    ...backendErrors,
  };

  useEffect(() => {
    if (!isExpiry) {
      unregister('near_expiry_threshold');
    }
  }, [isExpiry, unregister]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={6}>
        <Box>
          <Show below="md">
            <Box mt={4}>
              <PageTitle title={pageTitle} fontWeight={600} />
            </Box>
          </Show>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Hide below="md">
              <BackToListButton title={t('skusList')} url="/skus" />
            </Hide>
          </Flex>
        </Box>

        <Grid templateColumns="repeat(6, 1fr)" gap={6}>
          <GridItem
            colSpan={{ base: 6, md: 4 }}
            display="flex"
            flexFlow="column"
            gap={6}
          >
            <Card size="lg">
              <CardHeader>
                <SectionTitle title={t('skuDetails')} />
              </CardHeader>
              <CardBody>
                <FormControl isInvalid>
                  <FormErrorMessage>
                    {backendErrors.base?.message}
                  </FormErrorMessage>
                </FormControl>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                  <SKUDetailsInputs
                    register={register}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    defaultValues={defaultValues}
                  />
                </SimpleGrid>
                <FormControl mt={5}>
                  <FormLabel optionalIndicator={<OptionalIndicator />}>
                    {t('description')}
                  </FormLabel>
                  <Textarea
                    placeholder={t('description')}
                    minHeight="158px"
                    borderRadius="xl"
                    bg="gray.50"
                    {...register('description')}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={{ base: 6, md: 2 }}>
            <Stack spacing={6}>
              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('skuImage')} />
                </CardHeader>
                <CardBody>
                  <ImageInput
                    watchImage={watchImage}
                    setValue={setValue}
                    control={control}
                  />
                </CardBody>
              </Card>

              <Card size="lg">
                <CardHeader>
                  <SectionTitle title={t('lowInventoryNotifications')} />
                </CardHeader>
                <CardBody>
                  <Stack spacing={6}>
                    <Alert
                      status="warning"
                      borderRadius="xl"
                      alignItems="start"
                    >
                      <AlertIcon />
                      {t('lowInventoryInputsAlert')}
                    </Alert>

                    <Controller
                      control={control}
                      name="low_inventory_unit"
                      // rules={{ required: 'thisFieldIsRequired' }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={t('lowInventoryThresholdStorageType')}
                          placeholder={t('type')}
                          options={packagingOptions}
                          onChange={({ value }) => onChange(value)}
                          error={errors?.low_inventory_unit}
                          defaultValue={getFormSelectDefaultValue(
                            packagingEnum[defaultValues.low_inventory_unit],
                            packagingOptions
                          )}
                        />
                      )}
                    />

                    <Input
                      name="low_inventory_quantity"
                      label={t('lowInventoryThresholdValue')}
                      register={register}
                      errors={errors}
                      placeholder={t('thresholdValue')}
                      size="lg"
                      type="number"
                    />
                  </Stack>
                </CardBody>
              </Card>

              {isExpiry && (
                <Card size="lg">
                  <CardHeader>
                    <SectionTitle title={t('nearExpiryNotifications')} />
                  </CardHeader>
                  <CardBody>
                    <Stack spacing={6}>
                      <Alert
                        status="warning"
                        borderRadius="xl"
                        alignItems="start"
                      >
                        <AlertIcon />
                        {t('nearExpiryInputsAlert')}
                      </Alert>

                      <Input
                        name="near_expiry_threshold"
                        label={t('nearExpiryThresholdValue')}
                        register={register}
                        errors={errors}
                        placeholder={t('thresholdValue')}
                        size="lg"
                        type="number"
                        defaultValue={defaultValues.near_expiry_threshold}
                        required="thisFieldIsRequired"
                      />
                    </Stack>
                  </CardBody>
                </Card>
              )}
            </Stack>
          </GridItem>
        </Grid>
      </Stack>

      <CreateButtonsContainer>
        <Button
          colorScheme="red"
          variant="outline"
          bgColor="white"
          size="lg"
          minWidth="124px"
          isDisabled={isLoading}
          as={Link}
          to="/skus"
        >
          {t('cancel')}
        </Button>
        <Button
          colorScheme="primary"
          size="lg"
          minWidth="124px"
          type="submit"
          textTransform="capitalize"
          isLoading={isLoading}
        >
          {t('confirm')}
        </Button>
      </CreateButtonsContainer>
    </form>
  );
};

export default SKUFormPage;
