import { getSKUCategories } from 'api/Dashboard/skus';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import Input from 'components/shared/Inputs/Input';
import Select from 'components/Dashboard/Select';
import { useQuery } from 'react-query';
import { warehouseTypeEnum, getWarehouseTypeOptions } from 'constants/dashboard';
import { getFormSelectDefaultValue } from 'utils/select';
import { useEffect } from 'react';

const SKUDetailsInputs = ({
  register,
  errors,
  control,
  setValue,
  defaultValues = {},
}) => {
  const { t } = useTranslation();

  const { data: skuCategoriesData = {} } = useQuery('sku-categories', () =>
    getSKUCategories('')
  );

  const skuCategoriesOptions = skuCategoriesData.data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const warehouseTypeOptions = getWarehouseTypeOptions();
  const skuWarehouseType = useWatch({ control, name: 'sku_warehouse_type' });
  const isExpiry = useWatch({ control, name: 'is_expiry' });

  useEffect(() => {
    const isChilledOrFrozen = skuWarehouseType === warehouseTypeEnum.chilled || skuWarehouseType === warehouseTypeEnum.frozen;

    if (isChilledOrFrozen && isExpiry !== true) {
      setValue('is_expiry', true);
    }
  }, [skuWarehouseType, isExpiry, setValue]);

  return (
    <>
      <Input
        name="name"
        label={t('skuName')}
        register={register}
        errors={errors}
        placeholder={t('skuName')}
        required="thisFieldIsRequired"
        size="lg"
      />

      <Input
        name="merchant_sku"
        label={t('skuNumber')}
        register={register}
        errors={errors}
        placeholder={t('skuNumber')}
        required="thisFieldIsRequired"
        size="lg"
        type="number"
      />

      <Controller
        control={control}
        name="sku_warehouse_type"
        rules={{ required: 'thisFieldIsRequired' }}
        render={({ field: { onChange, value } }) => (
          <Select
            label={t('warehouseType')}
            placeholder={t('selectWarehouseType')}
            options={warehouseTypeOptions}
            onChange={({ value }) => onChange(value)}
            error={errors?.sku_warehouse_type}
            defaultValue={getFormSelectDefaultValue(
              defaultValues.sku_warehouse_type,
              warehouseTypeOptions
            )}
            size="lg"
            required
          />
        )}
      />

      <Controller
        control={control}
        name="is_expiry"
        rules={{
          validate: {
            required: (value) => value !== undefined || 'thisFieldIsRequired',
            mustBeTrue: (value) => {
              if (
                (skuWarehouseType === warehouseTypeEnum.chilled ||
                  skuWarehouseType === warehouseTypeEnum.frozen) &&
                value !== true
              ) {
                return t('frozenChilledSkusMustBeExpirable');
              }
              return true;
            },
          },
        }}
        render={({ field: { onChange, value } }) => {
          const isDisabled = skuWarehouseType === warehouseTypeEnum.chilled || skuWarehouseType === warehouseTypeEnum.frozen;
          const selectValue = isDisabled ? true : value;
          const selectOptions = [
            { label: t('yes'), value: true },
            { label: t('no'), value: false },
          ];
          const defaultValue = getFormSelectDefaultValue(defaultValues.is_expiry, selectOptions);

          return (
            <Select
              label={t('hasExpiry?')}
              placeholder={t('selectAnOption')}
              options={selectOptions}
              onChange={({ value }) => onChange(value)}
              error={errors?.is_expiry}
              defaultValue={defaultValue}
              size="lg"
              required
              isDisabled={isDisabled}
              value={selectOptions.find(option => option.value === selectValue)}
            />
          );
        }}
      />

      <Controller
        control={control}
        name="category_ids"
        rules={{ required: 'thisFieldIsRequired' }}
        render={({ field: { onChange } }) => (
          <Select
            label={t('SKU Categories')}
            placeholder={t('selectSKUCategories')}
            options={skuCategoriesOptions}
            onChange={(value) => onChange(value)}
            defaultValue={defaultValues.category_ids}
            error={errors?.category_ids}
            size="lg"
            isMulti
            required
          />
        )}
      />

      <Input
        name="color"
        label={t('color')}
        register={register}
        errors={errors}
        placeholder={t('color')}
        size="lg"
        type="color"
      />

      <Input
        name="size"
        label={`${t('skuSize')}`}
        register={register}
        errors={errors}
        placeholder={t('size')}
        size="lg"
        type="number"
      />

      <Input
        name="upc"
        label={t('UPC / EAN Number')}
        register={register}
        errors={errors}
        placeholder={t('UPC / EAN Number')}
        size="lg"
        type="number"
      />

      <Input
        name="brand"
        label={t('brand')}
        register={register}
        errors={errors}
        placeholder={t('brand')}
        // required="thisFieldIsRequired"
        size="lg"
      />
      <Input
        name="value"
        label={t('value')}
        register={register}
        errors={errors}
        placeholder={t('value')}
        size="lg"
        type="number"
      />
      <Input
        name="length"
        label={`${t('length')} (${t('CM')})`}
        register={register}
        errors={errors}
        placeholder={t('length')}
        size="lg"
        type="number"
      />

      <Input
        name="width"
        label={`${t('width')} (${t('CM')})`}
        register={register}
        errors={errors}
        placeholder={t('width')}
        size="lg"
        type="number"
      />

      <Input
        name="height"
        label={`${t('height')} (${t('CM')})`}
        register={register}
        errors={errors}
        placeholder={t('height')}
        size="lg"
        type="number"
      />

      <Input
        name="weight"
        label={`${t('weight')} (${t('KG')})`}
        register={register}
        errors={errors}
        placeholder={t('weight')}
        size="lg"
        type="number"
      />
    </>
  );
};

export default SKUDetailsInputs;
