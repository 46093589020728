import { Flex, Stack } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import Table from 'components/Dashboard/Table';
import useTable from 'hooks/useTable';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback } from 'react';
import { getSkuOnHandInventory } from 'api/Dashboard/skus';
import CounterTag from '../CounterTag';
import TotalsCounter from '../TotalsCounter';

const columnHelper = createColumnHelper();

const OnHandInventoryList = ({ id }) => {
  const { t } = useTranslation();
  const fetchFn = useCallback(
    (searchParams) => getSkuOnHandInventory(id, searchParams),
    [id]
  );

  const {
    data = [],
    meta = {},
    isLoading,
    onSortingChange,
    onPaginationChange,
    pagination,
  } = useTable({
    fetch: fetchFn,
    fetchKey: ['sku-on-hand-details', id],
    disableURLParams: true,
  });

  const columns = [
    columnHelper.accessor('identifier', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
      enableSorting: false,
    }),
    columnHelper.accessor('manifest_created_at', {
      cell: ({ getValue }) => getValue(),
      header: t('inboundDate'),
      enableSorting: false,
    }),
    columnHelper.accessor('warehouse_name', {
      cell: ({ getValue }) => getValue(),
      header: t('warehouse'),
      enableSorting: false,
    }),
    columnHelper.accessor('type', {
      cell: ({ getValue }) => {
        const type = getValue();

        return <CounterTag colorScheme="yellow" count={t(type)} />;
      },
      header: t('type'),
      meta: {
        fitContent: true,
      },
      enableSorting: false,
    }),
    columnHelper.accessor('cases_pallets', {
      cell: ({ getValue }) => {
        const value = getValue();

        return (
          <Flex justifyContent="center">
            <CounterTag colorScheme="green" count={value} width="fit-content" />
          </Flex>
        );
      },
      header: t('casesPallets'),
      meta: {
        fitContent: true,
      },
      enableSorting: false,
    }),
    columnHelper.accessor('units_cases', {
      cell: ({ getValue }) => {
        const value = getValue();

        return (
          <Flex justifyContent="center">
            <CounterTag
              colorScheme="purple"
              count={value}
              width="fit-content"
            />
          </Flex>
        );
      },
      header: t('unitsCases'),
      meta: {
        fitContent: true,
      },
      enableSorting: false,
    }),
    columnHelper.accessor('location', {
      cell: ({ getValue }) => {
        const location = getValue();

        if (!location) {
          return null;
        }

        return <CounterTag colorScheme="red" count={location} />;
      },
      header: t('location'),
      meta: {
        fitContent: true,
        centerHeader: true,
      },
      enableSorting: false,
    }),
  ];

  const totals = [
    { label: t('pallets'), value: meta.total_pallets },
    { label: t('boxes'), value: meta.total_boxes },
    { label: t('units'), value: meta.total_units },
    { label: t('bins'), value: meta.total_bins },
  ];

  return (
    <Stack spacing={6}>
      <TotalsCounter totals={totals} />

      <Table
        data={data}
        columns={columns}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        pageCount={pagination.totalPages}
        pageIndex={pagination.pageIndex}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default OnHandInventoryList;
