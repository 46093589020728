import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import CollapsibleCard from '../CollapsibleCard';
import { Center } from '@chakra-ui/react';
import Table from '../Table';
import { createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper();

const OperationalActivitiesSummary = ({ activities = {} }) => {
  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor('pallets_in', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('palletsIn'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('cases_in', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('casesIn'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('units_in', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('unitsIn'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('segregation', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('segregation'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('palletization', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('palletization'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('wrapping', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('wrapping'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('wooden_pallets', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('woodenPallets'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('loose_container', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('looseContainer'),
      meta: { centerHeader: true },
    }),
    columnHelper.accessor('overtime', {
      cell: ({ getValue }) => <Center fontWeight={700}>{getValue()}</Center>,
      header: t('overtime'),
      meta: { centerHeader: true },
    }),
  ];

  return (
    <CollapsibleCard
      title={
        <SectionTitle title={t('operationalActivitiesSummary')} hideDivider />
      }
      body={
        <Table
          data={[activities]}
          columns={columns}
          hidePagination
          enableSorting={false}
        />
      }
    />
  );
};

export default OperationalActivitiesSummary;
