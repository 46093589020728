import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';

import InputField from 'components/shared/Inputs/InputField';
import Button from 'components/shared/Button';
import { passwordValidation } from 'constants/validation';
import ReCaptchaField from 'components/shared/ReCaptchaField';

const Form = ({ onSubmit, isLoading, responseError }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const submit = (data) => {
    onSubmit(data);
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(submit)} noValidate>
      <InputField
        id="email"
        name="email"
        type="email"
        autoComplete="email"
        label={t('email')}
        placeholder="name@sirdab.co"
        register={register}
        errors={errors}
        required="validation.emailRequired"
        pattern={{
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'validation.invalidEmail',
        }}
      />
      <InputField
        id="password"
        name="password"
        type="password"
        label={t('password')}
        placeholder="••••••••"
        register={register}
        errors={errors}
        required="validation.passwordRequired"
        minLength={passwordValidation.minLength}
        maxLength={passwordValidation.maxLength}
      />

      <ReCaptchaField control={control} />

      <div className="flex justify-between items-center">
        <Link
          to={{
            pathname: '/sign-in/forgot-password',
            search: location.search,
          }}
          className="text-sm font-bold text-primary hover:text-primary/[.85]"
        >
          {t('forgotPassword')}
        </Link>
      </div>

      {responseError && (
        <div className="mt-1 text-sm text-red-700">
          {t('invalidLoginCredentials')}
        </div>
      )}

      <Button
        label={t('login')}
        type="submit"
        disabled={errors.agree?.message}
        isLoading={isLoading}
      />

      <Link
        to="/magic_links/new"
        className="flex w-full justify-center rounded-md p-3 font-bold leading-6 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {t('passwordlessLogin')}
      </Link>

      <p className="mt-2 text-sm leading-6 text-gray-500">
        {t('doNotHaveAccount')}{' '}
        <Link
          to={{
            pathname: '/sign-up',
            search: location.search,
          }}
          className="font-semibold text-primary hover:text-primary/[.85]"
        >
          {t('getStarted')}
        </Link>
      </p>
    </form>
  );
};

export default Form;
