import { Ability, AbilityBuilder, defineAbility } from '@casl/ability';

const generateUserCan = (can, user) => {
  if (user.role === 'admin') {
    can('manage', 'all');
  } else if (user.role === 'employee') {
    user.permissions.forEach((permission) => {
      can(permission.action, permission.subject_class);
    });
  }
};

export const defineAbilitiesFor = (user) =>
  defineAbility((can) => {
    generateUserCan(can, user);
  });

export const updateAbility = (ability, user) => {
  const { can, rules } = new AbilityBuilder(Ability);

  generateUserCan(can, user);

  ability.update(rules);
};
