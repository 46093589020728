import { createAddress } from 'api/Dashboard/addresses';
import AddressFormPage from 'components/Dashboard/Addresses/AddressFormPage';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CreateAddress = () => {
  const navigate = useNavigate();

  const {
    mutate: createAddressMutation,
    isLoading: isCreateAddressLoading,
    error,
  } = useMutation(createAddress, {
    onSuccess: (success) => {
      navigate('/addresses');
    },
  });

  const onSubmit = (data) => {
    createAddressMutation(data);
  };

  return (
    <AddressFormPage
      onSubmit={onSubmit}
      isLoading={isCreateAddressLoading}
      backendErrors={error?.response.data.errors}
    />
  );
};

export default CreateAddress;
