import { Spinner } from '@chakra-ui/react';
import { getAddressById, updateAddress } from 'api/Dashboard/addresses';
import AddressFormPage from 'components/Dashboard/Addresses/AddressFormPage';
import { defaultPocAttributes } from 'components/Dashboard/POCsInput';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { handleNotFoundPage } from 'utils/notFoundPage';

const markForDestruction = (defaultValues, values) => {
  // Create a map of unique identifiers (in this case, 'id') for quick lookup
  const map = new Map(values.map((item) => [item.id, item]));

  // Iterate over the combined set of unique identifiers in both arrays
  const uniqueIds = new Set([
    ...defaultValues.map((item) => item.id),
    ...values.map((item) => item.id),
  ]);

  // Generate the result array
  const result = Array.from(uniqueIds, (id) => {
    const itemFromFirstArray = defaultValues.find((item) => item.id === id);
    const itemFromSecondArray = map.get(id);

    if (itemFromFirstArray && !itemFromSecondArray) {
      // If the item is present in the first array but not in the second array, mark for destruction
      return { ...itemFromFirstArray, _destroy: true };
    }

    // If the item is present in the second array or both arrays, use it
    return itemFromSecondArray || itemFromFirstArray;
  });

  return result;
};

const AddressDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: addressDetails, isLoading } = useQuery(
    ['addressDetails', id],
    () => getAddressById(id),
    {
      onError: handleNotFoundPage,
      refetchOnMount: true,
      cacheTime: 100,
    }
  );

  const {
    mutate: updateAddressMutation,
    isLoading: isUpdateAddressLoading,
    error,
  } = useMutation(updateAddress, {
    onSuccess: (success) => {
      navigate('/addresses');
    },
  });

  const defaultValues = addressDetails && {
    pocs_attributes: !addressDetails.pocs.length
      ? [{ ...defaultPocAttributes }]
      : addressDetails.pocs,
    address: {
      location: {
        lat: addressDetails.latitude,
        lng: addressDetails.longitude,
      },
    },
    city_id: addressDetails.city.id,
    country_id: addressDetails.city.country_id,
    addressName: addressDetails.name,
  };

  const onSubmit = (data) => {
    const dataWithDestroyedPOCs = markForDestruction(
      defaultValues.pocs_attributes,
      data.pocs_attributes
    );

    updateAddressMutation({
      ...{ ...data, pocs_attributes: dataWithDestroyedPOCs },
      id,
    });
  };

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  return (
    <AddressFormPage
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      isLoading={isUpdateAddressLoading}
      backendErrors={error?.response.data.errors}
      isUpdate
    />
  );
};

export default AddressDetails;
