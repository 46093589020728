import Card from './Card';
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg';
import { chartBarBaseConfig } from './const';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';

const TotalMovements = ({ movements, weeksMovements }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  return (
    <Card
      icon={<TransferIcon />}
      title={`${t('total')} ${t('movements')}`}
      hint={t('totalMovementsTrendsOverLast4Weeks')}
      data={[
        {
          label: `${t('total')} ${t('inbounds')} ${
            isRTL ? `(${t('overviewPage.inbounds')})` : ''
          }`,
          value: movements.total_inbounds,
        },
        {
          label: `${t('total')} ${t('outbounds')} ${
            isRTL ? `(${t('overviewPage.outbounds')})` : ''
          }`,
          value: movements.total_outbounds,
        },
      ]}
      chartData={{
        labels: ['', '', '', ''],
        datasets: [
          {
            label: t('movements'),
            data: weeksMovements,
            ...chartBarBaseConfig,
          },
        ],
      }}
    />
  );
};

export default TotalMovements;
