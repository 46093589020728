export const saveToken = ({ newToken, expireAt, refreshToken }) => {
  localStorage.setItem('token', newToken);
  localStorage.setItem('expireAt', expireAt);
  localStorage.setItem('refreshToken', refreshToken);
};

export const deleteToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expireAt');
  localStorage.removeItem('refreshToken');
};

export const getToken = () => {
  return {
    token: localStorage.getItem('token'),
    expireAt: localStorage.getItem('expireAt'),
    refreshToken: localStorage.getItem('refreshToken'),
  };
};
