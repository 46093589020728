import { Spinner } from '@chakra-ui/react';
import { getSkuById, updateSKU } from 'api/Dashboard/skus';
import SKUFormPage from 'components/Dashboard/SKUs/SKUFormPage';
import { warehouseTypeEnum } from 'constants/dashboard';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { flattenErrorObject } from 'utils/formError';
import { handleNotFoundPage } from 'utils/notFoundPage';

const SKUEdit = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const { data: skuDetails = {}, isLoading } = useQuery(
    ['sku-details', id],
    () => getSkuById(id),
    { onError: handleNotFoundPage }
  );

  const {
    mutate: updateSKUMutation,
    isLoading: isUpdateSKULoading,
    error: updateSKUErrors,
  } = useMutation((payload) => updateSKU(id, payload), {
    onSuccess: ({ data }) => {
      navigate(`/skus/${data.id}`);
    },
  });

  const backendErrors = flattenErrorObject(
    updateSKUErrors?.response.data.errors
  );

  const onSubmit = (values) => {
    const skuImage = values.image;

    const payload = {
      ...values,
      category_ids: values.category_ids?.map(({ value }) => value),
      image: skuImage?.name
        ? {
            ...skuImage,
            base64: skuImage.base64.split(',')[1],
          }
        : skuImage?.base64
        ? skuImage.base64
        : skuImage,
    };

    delete payload.categories;

    updateSKUMutation(payload);
  };

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const defaultValues = {
    ...skuDetails,
    sku_warehouse_type: warehouseTypeEnum[skuDetails.sku_warehouse_type],
    category_ids: skuDetails.categories?.map((item) => ({
      label: item.name,
      value: item.id,
    })),
    image: skuDetails.image
      ? {
          base64: skuDetails.image,
        }
      : undefined,
  };

  return (
    <SKUFormPage
      onSubmit={onSubmit}
      backendErrors={backendErrors}
      isLoading={isUpdateSKULoading}
      defaultValues={defaultValues}
    />
  );
};

export default SKUEdit;
