import api from 'utils/axios';

export const getAddresses = async (searchParams) => {
  try {
    const response = await api(`/merchants/addresses?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getAddressById = async (id) => {
  try {
    const response = await api(`/merchants/addresses/${id}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const createAddress = async (data) => {
  const address = {
    latitude: data.address.location.lat,
    longitude: data.address.location.lng,
    city_id: data.city_id,
    maps_url: data.address.url,
    name: data.addressName,
    pocs_attributes: data.pocs_attributes.filter((poc) => poc.phone_number),
  };

  try {
    const response = await api.post('/merchants/addresses', { address });

    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAddress = async (data) => {
  const address = {
    name: data.addressName,
    pocs_attributes: data.pocs_attributes.filter((poc) => poc.phone_number),
  };

  try {
    const response = await api.patch(`merchants/addresses/${data.id}`, {
      address,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const getCountries = async (searchParams) => {
  try {
    const response = await api(`/merchants/countries?${searchParams}`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const getCities = async (searchParams, countryId) => {
  try {
    const response = await api(
      `/merchants/cities?${searchParams}&country_id=${countryId}`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
