import { Stack, SimpleGrid, Button, Flex, Divider } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import Input from 'components/shared/Inputs/Input';
import AvatarInput from '../AvatarInput';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { flattenErrorObject } from 'utils/formError';
import TitleDivider from '../TitleDivider';

const OrganizationInfoForm = ({
  defaultValues,
  onSubmit,
  isLoading,
  backendErrors = [],
}) => {
  const { t } = useTranslation();

  const { handleSubmit, formState, register, control } = useForm({
    defaultValues,
  });

  const errors = { ...formState.errors, ...flattenErrorObject(backendErrors) };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={5}>
        <Controller
          control={control}
          name="logo"
          rules={{ required: 'thisFieldIsRequired' }}
          render={({ field: { onChange, value } }) => (
            <AvatarInput
              onChange={onChange}
              value={value}
              onDelete={() => {
                onChange([
                  {
                    _destroy: '1',
                  },
                ]);
              }}
              defaultAvatarName={defaultValues.name}
            />
          )}
        />

        <SimpleGrid rows={2} spacing={5}>
          <Flex
            fontSize="lg"
            fontWeight={600}
            textTransform="capitalize"
            flex={1}
          >
            {t('organizationDetails')}
          </Flex>

          <SimpleGrid columns={2} spacing={3}>
            <Input
              name="name"
              label={t('organizationName')}
              register={register}
              errors={errors}
              placeholder={`${t('e.g')}: ${t('company_name')}`}
              size="lg"
              disabled
            />
            {/* <Input
                name="email"
                label={t('mainEmail')}
                placeholder={`${t('e.g')}: ${t('email@gmail.com')}`}
                register={register}
                errors={errors}
                pattern={{
                  value: emailRegex,
                  message: 'validation.invalidEmail',
                }}
                size="lg"
              /> */}
            <Input
              name="mobile_number"
              label={t('mainPhoneNumber')}
              placeholder={`${t('e.g')}: +966112212222`}
              register={register}
              errors={errors}
              validate={(value) =>
                isValidPhoneNumber(value, 'SA') || 'validation.invalidNumber'
              }
              size="lg"
            />
          </SimpleGrid>

          <Flex
            fontSize="lg"
            fontWeight={600}
            textTransform="capitalize"
            flex={4}
          >
            {t('otherDetails')}
          </Flex>

          <SimpleGrid columns={2} spacing={5}>
            <Input
              name="inventory_expiry_threshold_in_days"
              label={t('defaultNearExpiryThreshold')}
              placeholder={`${t('e.g')}: 30 days`}
              register={register}
              errors={errors}
              type="number"
              size="lg"
            />
          </SimpleGrid>
        </SimpleGrid>
      </Stack>

      <Flex justifyContent="end">
        <Button
          mt={8}
          colorScheme="primary"
          type="submit"
          size="lg"
          textTransform="capitalize"
          isLoading={isLoading}
          isDisabled={!formState.isDirty}
        >
          {t('saveChanges')}
        </Button>
      </Flex>
    </form>
  );
};

export default OrganizationInfoForm;
