import { Link as ReactRouterLink } from 'react-router-dom';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Link,
} from '@chakra-ui/react';
import { useUnverifiedOrganizationAlert } from 'context/UnverifiedOrganizationAlert';
import { Trans, useTranslation } from 'react-i18next';

const UnverifiedOrganizationAlert = () => {
  const { t } = useTranslation();

  const { isUnverifiedOrganizationAlertShown } =
    useUnverifiedOrganizationAlert();

  if (!isUnverifiedOrganizationAlertShown) {
    return null;
  }

  return (
    <Alert status="error" alignItems="start" size="sm" color="gray.700">
      <AlertIcon />
      <Box>
        <AlertTitle>{t('unverifiedOrganization')}</AlertTitle>
        <AlertDescription>
          <Trans
            i18nKey="yourOrganizationNotYetVerifiedFullAccess"
            components={[
              <Link
                as={ReactRouterLink}
                to="settings/organization"
                color="blue.600"
                textDecoration="underline"
                fontWeight={600}
              >
                {t('settingsPage')}.
              </Link>,
            ]}
          />
        </AlertDescription>
      </Box>
    </Alert>
  );
};

export default UnverifiedOrganizationAlert;
