import { useEffect } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

const PaymentAttempted = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const success = searchParams?.get('success');
    const errorCode = searchParams?.get('error_code');

    navigate({
      pathname: '/',
      search: createSearchParams({
        payment_success: success,
        error_code: errorCode,
      }).toString(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default PaymentAttempted;
