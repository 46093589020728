import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input as ChakraInput,
  Text,
} from '@chakra-ui/react';
import { toBase64Handler } from 'components/shared/Inputs/FileUpload/utils';
import { useTranslation } from 'react-i18next';

const AvatarInput = ({ value, onChange, onDelete, defaultAvatarName }) => {
  const { t } = useTranslation();

  const handleChange = async (uploadedFiles) => {
    const uploadedFilesAsArray = [...uploadedFiles];
    const attachmentsBase64 = await toBase64Handler(uploadedFilesAsArray);
    onChange(attachmentsBase64);
  };

  const src = value?.[0]?.base64;

  return (
    <Flex gap={6}>
      <Avatar
        src={src}
        name={defaultAvatarName}
        width="88px"
        height="88px"
        size="lg"
        bg="primary.700"
        color="white"
      />

      <Flex gap={4} flexDirection="column">
        <Text fontSize="sm" color="gray.600" fontWeight={500}>
          {t('supportedImages')}
        </Text>

        <Flex gap={2} flexWrap="wrap">
          <FormControl width="auto">
            <FormLabel cursor="pointer" margin={0}>
              <Button
                variant="outline"
                colorScheme="primary"
                color="primary.600"
                pointerEvents="none"
                fontWeight={600}
              >
                {t('uploadYourPhoto')}
              </Button>
            </FormLabel>
            <ChakraInput
              type="file"
              display="none"
              accept="image/jpg, image/jpeg, image/png"
              onChange={(e) => {
                if (!e.target.files.length) {
                  return;
                }
                handleChange(e.target.files);
                e.target.value = null;
              }}
            />
          </FormControl>
          <Button
            variant="ghost"
            colorScheme="red"
            onClick={() => onDelete()}
            isDisabled={!src}
          >
            {t('deleteImage')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AvatarInput;
