import React from 'react';
import { Stack, Flex, SimpleGrid, Skeleton } from '@chakra-ui/react';

const InboundOutboundSkeleton = () => (
  <Stack spacing={6}>
    <Flex justifyContent="space-between" alignItems="center">
      <Skeleton width={200} height={8} />
      <Skeleton width={200} height={12} />
    </Flex>

    <Skeleton height={20} />

    <SimpleGrid columns={2} spacing={6}>
      <Stack spacing={4}>
        <Skeleton height={8} />
        <Skeleton height={8} />
        <Skeleton height={8} />
        <Skeleton height={8} />
        <Skeleton height={8} />
        <Skeleton height={8} />
        <Skeleton height={8} />
        <Skeleton height={8} />
      </Stack>
      <Skeleton height={300} />
    </SimpleGrid>

    <Skeleton height={20} />

    <Skeleton height={40} />

    <SimpleGrid columns={2} spacing={6}>
      <Skeleton height={40} />
      <Skeleton height={40} />
    </SimpleGrid>

    <Skeleton height={40} />

    <Skeleton height={40} />

    <Skeleton height={40} />
  </Stack>
);

export default InboundOutboundSkeleton;
