import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SignInForm from 'components/SignIn/Form';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';

import useAuth from 'hooks/useAuth';
import { getLocalStorage } from 'utils/localStorage';

const SignIn = () => {
  const { t } = useTranslation();
  const { signIn, isSignInLoading, signInError } = useAuth();
  const navigate = useNavigate();
  const { token, resendOTP } = useAuth();

  useEffect(() => {
    const user = getLocalStorage('user');
    if (token && user) {
      if (!user.otp_verified_at) {
        resendOTP();
        navigate('/sign-up/verify-otp');
        return;
      }
      navigate('/');
    }
  }, [navigate, resendOTP, token]);

  const handleSubmit = (data) => {
    const { email, password } = data;

    signIn({
      email,
      password,
    });
  };

  return (
    <RegistrationLayout
      title={t('welcomeBack')}
      imageTitle={t('signInImageTitle')}
      imageContent={t('signInImageContent')}
      content={
        <SignInForm
          onSubmit={handleSubmit}
          isLoading={isSignInLoading}
          responseError={signInError}
        />
      }
    />
  );
};

export default SignIn;
