import { Button } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import ActionsButton from 'components/Dashboard/ActionsButton';
import { getSKUGroups } from 'api/Dashboard/skuGroups';
import { Link, useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper();

const SKUGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageFilter = [
    {
      label: t('skuGroups'),
      value: 'all',
    },
  ];

  const {
    data,
    isLoading,
    selectedFilter,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getSKUGroups,
    fetchKey: 'sku-groups',
    searchKey: 'name_cont',
  });

  const columns = [
    columnHelper.accessor('name', {
      cell: ({ getValue }) => getValue(),
      header: t('name'),
      meta: {
        mobileHeader: 'left',
        hideHash: true,
      },
    }),
    columnHelper.accessor('total_skus', {
      cell: ({ getValue }) => getValue(),
      header: t('totalSKUs'),
    }),
    columnHelper.accessor('total_users', {
      cell: ({ getValue }) => getValue(),
      header: t('totalUsers'),
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => (
        <ActionsButton
          onClick={() => {
            navigate(`${row.original.id}`);
          }}
        >
          {t('view')}
        </ActionsButton>
      ),
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  return (
    <TablePage
      data={data}
      columns={columns}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('skuGroupsList')}
      // pageFilter={pageFilter}
      pageAction={
        <Button
          leftIcon={<AddIcon />}
          colorScheme="primary"
          textTransform="capitalize"
          size="lg"
          as={Link}
          to="create"
        >
          {t('createNewSKUGroup')}
        </Button>
      }
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      isLoading={isLoading}
      searchPlaceholder={t('searchByName')}
      onSearchChange={onSearchChange}
    />
  );
};

export default SKUGroups;
