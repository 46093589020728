import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TotalsCounter = ({ totals }) => {
  const { t } = useTranslation();

  return (
    <SimpleGrid
      columns={{ base: 2, md: 4 }}
      spacing={8}
      background="gray.100"
      border="1px solid"
      borderColor="gray.50"
      paddingX={8}
      paddingY={4}
      marginTop={2}
      borderRadius="2xl"
    >
      {totals.map(({ label, value }, index) => (
        <Flex gap={1} flexDirection="column" textAlign="center" key={index}>
          <Text
            fontSize="sm"
            color="gray.600"
            fontWeight={500}
            textTransform="capitalize"
          >
            {t(label)}
          </Text>
          <Text fontWeight={600}>{value}</Text>
        </Flex>
      ))}
    </SimpleGrid>
  );
};

export default TotalsCounter;
