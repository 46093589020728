import RegistrationHeader from 'components/shared/Registration/RegistrationHeader';
import ImageOverlay from 'components/shared/Registration/ImageOverlay';
import sirdabLogo from 'assets/images/Sirdab_Logo-01.png';
import LanguageSelect from '../Inputs/LanguageSelect';

const RegistrationLayout = ({
  title,
  subtitle,
  content,
  imageTitle,
  imageContent,
}) => {
  return (
    <div className="flex min-h-full flex-1 p-6">
      <div className="flex flex-1 flex-col justify-between sm:px-6 lg:flex-none lg:px-20 xl:px-24 min-w-[60%]">
        <div className="w-full flex justify-between lg:ltr:pr-6 lg:rtl:pl-6">
          <img className="h-8 w-auto mb-4" src={sirdabLogo} alt="Sirdab logo" />
          <div>
            <LanguageSelect size="md" background="gray.100" border="none" />
          </div>
        </div>
        <div className="mx-auto w-full max-w-md lg:w-120">
          <RegistrationHeader title={title} subtitle={subtitle} />
          <div className="mt-6">{content}</div>
        </div>
        <div></div>
      </div>
      <ImageOverlay title={imageTitle} content={imageContent} />
    </div>
  );
};

export default RegistrationLayout;
