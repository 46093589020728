import { forwardRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ar from 'date-fns/locale/ar';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import useLanguage from 'hooks/useLanguage';
import { Controller } from 'react-hook-form';
import { inputSizes } from 'constants/theme';
import OptionalIndicator from '../OptionalIndicator';

const DatePicker = ({
  name,
  label,
  register,
  errors,
  required,
  minDate,
  maxDate,
  control,
  showTime,
  helperText,
  size = 'md',
  hideRequiredIndicator,
  placeholder,
  isDisabled,
  defaultValue,
  withPortal,
  onChange: parentOnChange,
  appendPopperToParent,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [isMobile] = useMediaQuery('(max-width: 767px)', {
    ssr: false,
  });

  useEffect(() => {
    register(name, {
      required,
    });
  }, [register, name, required]);

  const CustomInput = forwardRef(({ value, onChange, onClick }, ref) => (
    <InputGroup onClick={onClick}>
      {language === 'en' && (
        <InputLeftElement pointerEvents="none" height="full">
          <CalendarIcon color="gray.300" />
        </InputLeftElement>
      )}
      {language === 'ar' && (
        <InputRightElement pointerEvents="none" height="full">
          <CalendarIcon color="gray.300" />
        </InputRightElement>
      )}
      <Input
        sx={{
          paddingInlineStart: 9,
          paddingInlineEnd: 0,
          minHeight: inputSizes[size],
        }}
        placeholder={placeholder ?? t('selectDate')}
        autoComplete="off"
        name={name}
        ref={ref}
        value={value}
        onChange={onChange}
        size={size}
      />
    </InputGroup>
  ));

  const error = errors?.[name];

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <FormControl
      isInvalid={error}
      isRequired={required}
      isDisabled={isDisabled}
    >
      {label && (
        <FormLabel
          // fontSize={size === 'lg' ? 'md' : 'sm'}
          textTransform="capitalize"
          requiredIndicator={false}
          optionalIndicator={<OptionalIndicator />}
        >
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          validate: (value) => {
            if (!required && !value) {
              return true;
            }

            const dateValue = value instanceof Date ? value : new Date(value);

            if (!minDate) {
              return true;
            }

            minDate.setHours(0, 0, 0, 0);

            return dateValue >= minDate || t('pleaseSelectDateInTheFuture');
          },
          required,
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <ReactDatePicker
              selected={value ? new Date(value) : value}
              onChange={(date, event) => {
                if (!showTime) {
                  date && date.setHours(0, 0, 0, 0);
                }

                onChange(date, event);
                parentOnChange?.(date, event);
              }}
              dateFormat={`dd/MM/yyyy${showTime ? ' h:mm aa' : ''}`}
              minDate={
                typeof minDate === 'string' ? new Date(minDate) : minDate
              }
              maxDate={
                typeof maxDate === 'string' ? new Date(maxDate) : maxDate
              }
              customInput={<CustomInput />}
              locale={language === 'en' ? undefined : ar}
              showTimeInput={showTime}
              filterTime={filterPassedTime}
              withPortal={isMobile || withPortal}
              popperContainer={
                appendPopperToParent
                  ? ({ children }) => createPortal(children, document.body)
                  : undefined
              }
            />
          );
        }}
      />

      {!error ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <FormErrorMessage>{t(error.message)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default DatePicker;
