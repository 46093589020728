import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const UrgentModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('urgentOrder')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{t('urgentOrdersSubjectToApproval')}</ModalBody>

        <ModalFooter gap={2}>
          <Button
            onClick={onClose}
            variant="outline"
            colorScheme="red"
            size="lg"
          >
            {t('cancel')}
          </Button>

          <Button variant="primary" size="lg" onClick={onConfirm}>
            {t('confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UrgentModal;
