import { Divider, Flex } from '@chakra-ui/react';
import TitleDivider from 'components/Dashboard/TitleDivider';

const SectionTitle = ({ title, hideDivider }) => {
  return (
    <>
      <Flex alignItems="center" gap={2} marginBottom={hideDivider ? 0 : 5}>
        <TitleDivider />
        <Flex
          fontSize="lg"
          fontWeight={600}
          textTransform="capitalize"
          flex={1}
        >
          {title}
        </Flex>
      </Flex>
      {!hideDivider && <Divider />}
    </>
  );
};

export default SectionTitle;
