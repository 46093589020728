import { createContext, useContext } from 'react';
import { useDisclosure } from '@chakra-ui/react';

const UnverifiedOrganizationAlertContext = createContext();

export const UnverifiedOrganizationAlertProvider = ({ children }) => {
  const {
    isOpen: isUnverifiedOrganizationAlertShown,
    onOpen: showUnverifiedOrganizationAlert,
    onClose: hideUnverifiedOrganizationAlert,
  } = useDisclosure();

  return (
    <UnverifiedOrganizationAlertContext.Provider
      value={{
        isUnverifiedOrganizationAlertShown,
        showUnverifiedOrganizationAlert,
        hideUnverifiedOrganizationAlert,
      }}
    >
      {children}
    </UnverifiedOrganizationAlertContext.Provider>
  );
};

export function useUnverifiedOrganizationAlert() {
  return useContext(UnverifiedOrganizationAlertContext);
}
