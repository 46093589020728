import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { setAmplitudeUserData } from 'utils/amplitude';
import { deleteLocalStorage, getLocalStorage } from 'utils/localStorage';
import { deleteToken } from 'utils/token';
import { updateIntercom } from 'utils/intercom';
import Sentry from 'utils/sentry';

export default function Layout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useAuth();

  useEffect(() => {
    if (location.pathname.includes('/lead-quote')) {
      return;
    }

    const user = getLocalStorage('user');

    if (!token || !user) {
      deleteLocalStorage('user');
      deleteToken();
      navigate('/sign-in');
      return;
    }

    setAmplitudeUserData({
      email: user.email,
      role: user.role,
      company: user.organization.name,
      companyID: user.organization.id,
    });

    updateIntercom({
      id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone,
      organization_id: user.organization.id,
      organization_name: user.organization.name,
      phone_number: user.phone,
      organization_type: user.role === 'lead' ? 'LeadOrg' : 'MerchantOrg',
      user_role: user.role,
      verified: user.organization.verified,
      has_active_quote: user.organization?.has_active_quote,
      org_created_at: user.organization?.created_at,
    });

    Sentry.setUser({
      id: user.id,
      email: user.email,
    });

    if (!user.onboarding_finished) {
      navigate('/warehouses');
      return;
    }
  }, [location.pathname, navigate, token]);

  return (
    <Flex direction="column" width="100%">
      <Outlet />
    </Flex>
  );
}
