import { Select } from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';

const LanguageSelect = ({ size = 'md', ...props }) => {
  const { language, changeLanguage } = useLanguage();
  const handleChange = (e) => {
    const newLang = e.target.value;
    changeLanguage(newLang);
  };
  return (
    <Select
      value={language}
      onChange={handleChange}
      padding="0"
      size={size}
      borderRadius="md"
      cursor="pointer"
      borderColor="gray.50"
      {...props}
    >
      <option value="ar">ع</option>
      <option value="en">EN</option>
    </Select>
  );
};

export default LanguageSelect;
