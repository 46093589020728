import Card from './Card';
import { ReactComponent as MaximizeIcon } from 'assets/icons/maximize.svg';
import { chartBarBaseConfig } from './const';
import { useTranslation } from 'react-i18next';

const TotalSpaceRented = ({ rentedSpaces, rentedSpacesPerWeek }) => {
  const { t } = useTranslation();
  return (
    <Card
      icon={<MaximizeIcon />}
      title={t('totalSpaceRented')}
      hint={t('rentedSpaceTrendsOverLast4Weeks')}
      data={[
        {
          label: `${t('total')} ${t('pallets')}`,
          value: rentedSpaces.pallets,
        },
        {
          label: t('sqM'),
          value: rentedSpaces.sqm,
        },
      ]}
      chartData={{
        labels: ['', '', '', ''],
        datasets: [
          {
            data: rentedSpacesPerWeek,
            ...chartBarBaseConfig,
          },
        ],
      }}
    />
  );
};

export default TotalSpaceRented;
