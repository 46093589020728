import api from 'utils/axios';

export const getSKUGroups = async (searchParams) => {
  try {
    const response = await api(`/merchants/sku_groups?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getSKUGroupSKUs = async (searchParams) => {
  try {
    const response = await api(`/merchants/sku_group_skus?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const createSKUGroup = async (sku_group) => {
  try {
    const response = await api.post('/merchants/sku_groups', { sku_group });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSKUGroup = async (id, sku_group) => {
  try {
    const response = await api.patch(`/merchants/sku_groups/${id}`, {
      sku_group,
    });

    return { data: response.data, payload: sku_group };
  } catch (error) {
    throw error;
  }
};

export const getSKUGroupById = async (id) => {
  try {
    const response = await api(`/merchants/sku_groups/${id}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getSKUGroupUsers = async (searchParams) => {
  try {
    const response = await api(`/merchants/sku_group_users?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};
