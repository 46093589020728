import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

const OptionalIndicator = () => {
  const { t } = useTranslation();
  return (
    <Text as='span' fontSize='sm' color='gray.400' marginInlineStart='1'>
      {t('optionalTag')}
    </Text>
  );
};

export default OptionalIndicator;
