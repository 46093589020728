import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, IconButton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import useLanguage from 'hooks/useLanguage';
import SlickSlider from 'react-slick';
import CurrentSpaceUsed from './CurrentSpaceUsed';
import PendingOrders from './PendingOrders';
import StorageOverview from './StorageOverview';
import TotalMovements from './TotalMovements';
import TotalSpaceRented from './TotalSpaceRented';

const SlickSliderStyled = styled(SlickSlider)`
  #next-button {
    position: absolute;
    top: -50px;

    left: ${(props) => (props.rtl ? '45px' : 'auto')};
    right: ${(props) => (props.rtl ? 'auto' : '8px')};
  }
  #prev-button {
    position: absolute;
    top: -50px;

    left: ${(props) => (props.rtl ? '8px' : 'auto')};
    right: ${(props) => (props.rtl ? 'auto' : '45px')};
  }
`;

const settings = {
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  slidesToShow: 2,
  slidesToScroll: 2,

  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SlideArrowButton = ({ className, style, onClick, isPrev }) => {
  return (
    <IconButton
      colorScheme="gray"
      bg="gray.300"
      size="sm"
      icon={
        isPrev ? (
          <ChevronLeftIcon boxSize="7" />
        ) : (
          <ChevronRightIcon boxSize="7" />
        )
      }
      onClick={onClick}
      id={!isPrev ? 'next-button' : 'prev-button'}
    />
  );
};

const CardSlider = ({ overviewData }) => {
  const { language } = useLanguage();

  const overviewCards = [
    <TotalMovements
      movements={overviewData.movements}
      weeksMovements={overviewData.weeks_movements}
    />,
    <PendingOrders
      pendingOrders={overviewData.pending_orders}
      pendingOrdersPerWeek={overviewData.pending_orders_per_week}
    />,
    <StorageOverview storageOverview={overviewData.storage_overview} />,
    <TotalSpaceRented
      rentedSpaces={overviewData.rented_spaces}
      rentedSpacesPerWeek={overviewData.rented_spaces_per_week}
    />,
    <CurrentSpaceUsed
      utilizedSpaces={overviewData.utilized_spaces}
      utilizedSpacesPercentage={overviewData.utilized_spaces_percentage}
      utilizedSpacesPerWeek={overviewData.utilized_spaces_per_week}
    />,
  ];
  return (
    <SlickSliderStyled
      {...settings}
      rtl={language === 'ar'}
      nextArrow={<SlideArrowButton />}
      prevArrow={<SlideArrowButton isPrev />}
    >
      {overviewCards?.map((card, index) => (
        <Box key={index} paddingX={{ base: 1, sm: 2 }} pb="1">
          {card}
        </Box>
      ))}
    </SlickSliderStyled>
  );
};

export default CardSlider;
