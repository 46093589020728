import { createContext, useState, useContext } from 'react';

const PaymentDueContext = createContext();

export const PAYMENT_DUE_STATES = {
  INVOICE_ALERT: 2,
  INVOICE_MODAL: 28,
  ACCOUNT_LOCKED: 30,
};

export function PaymentDueProvider({ children }) {
  const [paymentDueState, setPaymentDueState] = useState();

  const updatePaymentDue = (status) => {
    setPaymentDueState(status);
  };

  return (
    <PaymentDueContext.Provider value={{ paymentDueState, updatePaymentDue }}>
      {children}
    </PaymentDueContext.Provider>
  );
}

export function usePaymentDue() {
  return useContext(PaymentDueContext);
}
