import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactComponent as TransportationIcon } from 'assets/icons/truck-fast.svg';
import { createColumnHelper } from '@tanstack/react-table';
import OrderStatusTag from 'components/Dashboard/OrderStatusTag';
import StatusTag from 'components/Dashboard/StatusTag';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import ActionsButton from 'components/Dashboard/ActionsButton';
import TablePage from 'components/Dashboard/TablePage';
import useTable from 'hooks/useTable';
import { orderTypeTagColors, transportableTypeEnum } from 'constants/dashboard';
import { getTransportationOrders } from 'api/Dashboard/transportation';
import { Link, useNavigate } from 'react-router-dom';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { ReactComponent as TransportationEmptyTable } from 'assets/images/transportation-empty-table.svg';

const StyledTransportationIcon = styled(TransportationIcon)`
  path {
    stroke: #fff;
  }
`;

const columnHelper = createColumnHelper();

const tripRoutes = {
  Manifest: 'inbounds',
  Outbound: 'outbounds',
  ABTrip: 'transportations',
};

const pageFilterKey = 'q[transportable_type_in][]';

const Transportations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const pageFilter = [
    {
      label: t('transportationPages.allTransportation'),
      value: '',
    },
    ...Object.keys(transportableTypeEnum).map((key) => ({
      label: t(`transportationOrderTypes.${key}`),
      value: transportableTypeEnum[key],
    })),
  ];

  const {
    data,
    isLoading,
    selectedFilter,
    responseFilters,
    onFilterChange,
    onSortingChange,
    onPaginationChange,
    pagination,
    onSearchChange,
  } = useTable({
    fetch: getTransportationOrders,
    fetchKey: 'transportation_orders',
    pageFilterKey,
    // searchKey: 'merchant_name_or_transportable_name_cont',
  });

  const filters = [
    {
      label: t('status'),
      name: 'status',
      options: responseFilters.statuses?.map((status) => ({
        label: t(`orderStatus.${status}`),
        value: status,
      })),
    },
  ];

  const columns = [
    columnHelper.accessor('id', {
      cell: ({ getValue }) => getValue(),
      header: t('id'),
      meta: {
        mobileHeader: 'left',
      },
    }),
    columnHelper.accessor('date', {
      cell: ({ getValue }) => formatDate(getValue()),
      header: t('scheduledAt'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('transportable_type', {
      cell: ({ getValue }) => {
        const type = getValue();
        if (!type) {
          return null;
        }
        return (
          <StatusTag colorScheme={orderTypeTagColors[type]}>
            {t(`transportationOrderTypes.${type}`)}
          </StatusTag>
        );
      },
      header: t('orderType'),
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor('origin_name', {
      cell: ({ getValue }) => getValue(),
      header: t('origin'),
    }),
    columnHelper.accessor('destination_name', {
      cell: ({ getValue }) => getValue(),
      header: t('destination'),
    }),
    columnHelper.accessor('laborers', {
      cell: ({ getValue }) => getValue(),
      header: t('transportationPages.laborers'),
    }),
    columnHelper.accessor('price', {
      cell: ({ getValue }) => getValue(),
      header: t('price'),
    }),
    columnHelper.accessor('status', {
      cell: ({ getValue }) => <OrderStatusTag status={getValue()} />,
      header: t('status'),
      meta: {
        mobileHeader: 'right',
        fitContent: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }) => {
        return (
          <ActionsButton
            onClick={() => {
              navigate(
                `/${tripRoutes[row.original.transportable_type]}/${
                  row.original.transportable_id
                }`
              );
            }}
          >
            {t('manage')}
          </ActionsButton>
        );
      },
      header: t('actions'),
      meta: {
        isAction: true,
      },
      enableSorting: false,
    }),
  ];

  const CreateOrderButton = ({ fullWidth }) => (
    <Button
      leftIcon={<StyledTransportationIcon />}
      colorScheme="primary"
      textTransform="capitalize"
      size="lg"
      as={Link}
      to="create"
      width={fullWidth && 'full'}
    >
      {t('transportationPages.requestNewOrder')}
    </Button>
  );

  return (
    <TablePage
      data={data}
      columns={columns}
      filters={filters}
      selectedFilter={selectedFilter}
      pagination={pagination}
      title={t('transportationPages.transportationOrders')}
      pageFilter={pageFilter}
      pageAction={<CreateOrderButton />}
      onFilterChange={onFilterChange}
      onPageFilterChange={(value) => {
        onFilterChange({
          [pageFilterKey]: value,
        });
      }}
      selectedPageFilter={selectedFilter?.[pageFilterKey]}
      onSortingChange={onSortingChange}
      onPaginationChange={onPaginationChange}
      searchPlaceholder={t('transportationPages.tableSearchPlaceholder')}
      onSearchChange={onSearchChange}
      isLoading={isLoading}
      emptyTable={
        <EmptyTable
          illustration={<TransportationEmptyTable />}
          title={t('emptyStateTitle', {
            value: t('transportationOrders'),
          })}
          subtitle={t('emptyStateSubtitle', {
            value: t('newTransportationOrder'),
          })}
          actions={<CreateOrderButton fullWidth />}
        />
      }
      hideSearch
    />
  );
};

export default Transportations;
