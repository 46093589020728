import { Button } from '@chakra-ui/react';
import EmptyTable from 'components/Dashboard/EmptyTable';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as SuccessIllustration } from 'assets/images/warehouse-success.svg';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';

const Success = () => {
  const { t } = useTranslation();

  return (
    <EmptyTable
      illustration={<SuccessIllustration />}
      title={t('yourWarehouseIsNowReady')}
      subtitle={<>{`${t('thanksForYourTime')}. ${t('anExpertWillCallYou')}`}</>}
      actions={
        <Button
          colorScheme="primary"
          size="lg"
          as={Link}
          to="/warehouses"
          width="full"
          leftIcon={<ArrowBack />}
        >
          {t('backToWarehousesList')}
        </Button>
      }
    />
  );
};

export default Success;
