import { Stack, Text, Card, CardHeader, CardBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import MapWithDirections from 'components/shared/MapWithDirections';

const LocationSection = ({ outboundDetails }) => {
  const { t } = useTranslation();

  const outboundDetailsOrigin = outboundDetails.origin ?? {};
  const outboundDetailsDestination = outboundDetails.destination ?? {};

  const origin = {
    lat: outboundDetailsOrigin.latitude,
    lng: outboundDetailsOrigin.longitude,
  };

  const destination = {
    lat: outboundDetailsDestination.latitude,
    lng: outboundDetailsDestination.longitude,
  };

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('locationDetails')} />
      </CardHeader>
      <CardBody>
        <Stack spacing={5} height="full">
          <Text fontWeight={600}>{t('mapView')}</Text>
          <MapWithDirections
            containerStyle={{
              width: '100%',
              height: '100%',
              minHeight: '322px',
              borderRadius: '16px',
            }}
            origin={origin}
            destination={destination}
          />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default LocationSection;
