import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import SignUpForm from 'components/SignUp/Form';

import useAuth from 'hooks/useAuth';
import { useMutation, useQuery } from 'react-query';
import { getUser, updateLead } from 'api/auth';
import { Spinner } from '@chakra-ui/react';
import { setLocalStorage } from 'utils/localStorage';

const SignUp = () => {
  const { t } = useTranslation();
  const { token, signUp, isSignUpLoading, signUpError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const quoteId = location.state?.quoteId;

  const {
    data: user,
    isLoading,
    refetch: getUserQuery,
  } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      if (data.role !== 'lead') {
        setLocalStorage('user', data);

        navigate({
          pathname: '/',
          search: location.search,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    mutate: updateLeadMutation,
    isLoading: isUpdateLeadLoading,
    error: updateLeadError,
  } = useMutation(updateLead);

  useEffect(() => {
    if (token) {
      getUserQuery();
    }
  }, [getUserQuery, token]);

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  const [firstName, lastName] = (user?.name ?? '').split(' ');

  const defaultValues = {
    firstName,
    lastName,
    email: user?.email,
  };

  const navigateToOTP = (email) => {
    navigate(
      {
        pathname: 'verify-otp',
        search: location.search,
      },
      { state: { email } }
    );
  };

  const handleSubmit = (data) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      phoneNumber,
      companyName,
    } = data;

    const utmTags = Object.fromEntries(new URLSearchParams(location.search));
    delete utmTags.deviceId;

    const payload = {
      firstname: firstName,
      lastname: lastName,
      email,
      password,
      password_confirmation: confirmPassword,
      phone: phoneNumber,
      company_name: companyName,
      onboarding_state: {
        utmTags,
      },
    };

    if (user?.role === 'lead') {
      updateLeadMutation(
        { ...payload, quote_id: +quoteId },
        {
          onSuccess: (data) => {
            setLocalStorage('user', data.data);
            navigateToOTP(email);
          },
        }
      );
    } else {
      signUp(
        { ...payload },
        {
          onSuccess: () => {
            navigateToOTP(email);
          },
        }
      );
    }
  };

  return (
    <RegistrationLayout
      title={t('createAccount')}
      imageTitle={t('signUpImageTitle')}
      imageContent={t('signUpImageContent')}
      content={
        <SignUpForm
          onSubmit={handleSubmit}
          isLoading={isSignUpLoading || isUpdateLeadLoading}
          responseError={
            signUpError?.response.data.error ||
            updateLeadError?.response.data.error
          }
          defaultValues={defaultValues}
        />
      }
    />
  );
};

export default SignUp;
