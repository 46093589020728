import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import InputField from 'components/shared/Inputs/InputField';
import Button from 'components/shared/Button';
import PhoneNumberInput from 'components/shared/Inputs/PhoneNumber/PhoneNumberInput';
import TermsAndConditionsInput from 'components/shared/Registration/TermsAndConditionsInput';
import { passwordValidation } from 'constants/validation';
import { Link, useLocation } from 'react-router-dom';
import ReCaptchaField from 'components/shared/ReCaptchaField';

const Form = ({ onSubmit, isLoading, responseError, defaultValues }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const [confirmPasswordError, setConfirmPasswordError] = useState();

  const submit = (data) => {
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      setConfirmPasswordError({
        confirmPassword: {
          message: 'validation.passwordsNotSame',
        },
      });
      return;
    }

    setConfirmPasswordError(undefined);
    onSubmit(data);
  };

  return (
    <>
      <form className="space-y-4" onSubmit={handleSubmit(submit)} noValidate>
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              id="firstName"
              name="firstName"
              type="text"
              autoComplete="firstName"
              label={t('firstName')}
              placeholder={t('enterYourFirstName')}
              register={register}
              errors={errors}
              required="validation.firstNameRequired"
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              id="lastName"
              name="lastName"
              type="text"
              autoComplete="lastName"
              label={t('lastName')}
              placeholder={t('enterYourLastName')}
              register={register}
              errors={errors}
              required="validation.lastNameRequired"
            />
          </div>
        </div>
        <InputField
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          label={t('email')}
          placeholder="name@sirdab.co"
          register={register}
          errors={errors}
          required="validation.emailRequired"
          pattern={{
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'validation.invalidEmail',
          }}
        />
        <PhoneNumberInput
          name="phoneNumber"
          id="phoneNumber"
          label={t('phoneNumber')}
          control={control}
          errors={errors}
          required="validation.phoneNumberRequired"
        />
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <InputField
              id="password"
              name="password"
              type="password"
              label={t('password')}
              placeholder="••••••••"
              register={register}
              errors={errors}
              required="validation.passwordRequired"
              minLength={passwordValidation.minLength}
              maxLength={passwordValidation.maxLength}
            />
          </div>
          <div className="sm:col-span-3">
            <InputField
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              label={t('confirmPassword')}
              placeholder="••••••••"
              register={register}
              errors={confirmPasswordError ? confirmPasswordError : errors}
              required="validation.confirmPasswordRequired"
              minLength={passwordValidation.minLength}
              maxLength={passwordValidation.maxLength}
            />
          </div>
        </div>
        <InputField
          id="companyName"
          name="companyName"
          type="text"
          label={t('companyName')}
          placeholder="e.g. Sirdab"
          register={register}
          errors={errors}
          required="validation.companyNameRequired"
        />

        <ReCaptchaField control={control} />

        <TermsAndConditionsInput />
        {responseError && (
          <div className="mt-1 text-sm text-red-700">{responseError}</div>
        )}
        <Button
          label={t('createNewAccount')}
          type="submit"
          disabled={errors.agree?.message}
          isLoading={isLoading}
        />
        <p className="mt-2 text-sm leading-6">
          {t('alreadyHaveAccount')}{' '}
          <Link
            to={{
              pathname: '/sign-in',
              search: location.search,
            }}
            className="font-semibold text-primary hover:text-primary/[.85]"
          >
            {t('logIn')}
          </Link>
        </p>
      </form>
      <p className="mt-2 text-sm leading-6 text-gray-500">
        {t('alreadyUsingSirdab')}
      </p>
    </>
  );
};

export default Form;
