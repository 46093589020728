import { generateReport } from 'api/Dashboard/reports';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import PageDrawer from '../PageDrawer';
import { FormControl, FormLabel, Stack, Switch } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import Select from '../Select';
import { getWarehouses } from 'api/Dashboard/warehouses';
import DatePicker from 'components/shared/Inputs/DatePicker';
import { handleMultiSelectChange, selectAllOption } from 'utils/select';
import { handleMultiValuesPayload } from 'utils/reports';
import { adjustToUTC } from 'utils/date';
import RadioCardGroup from 'components/shared/Inputs/RadioCardGroup/RadioCardGroup';
import { exportFormatOptions } from './ReportForm';

const ExpiryReportDrawer = ({ isOpen, onSuccess, onClose, reportType }) => {
  const { t } = useTranslation();

  const isExpiry = reportType === 'expiry';
  const formId = isExpiry
    ? 'generateExpiryReportForm'
    : 'generateNearExpiryReportForm';

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    register,
    setValue,
    watch,
  } = useForm();

  const watchExpiresBy = watch('end_date');

  const { data: warehouses = {} } = useQuery('warehouses', getWarehouses, {
    refetchOnMount: true,
  });

  const warehouseOptions =
    warehouses.data?.map(({ warehouse_id, name }) => ({
      label: name,
      value: warehouse_id,
    })) ?? [];

  const translatedSelectAllOption = {
    ...selectAllOption,
    label: t(selectAllOption.label),
  };

  const {
    mutate: generateSKUReportMutation,
    isLoading: isGenerateSKUReportLoading,
  } = useMutation(generateReport, {
    onSuccess: () => {
      onClose();
      onSuccess();
      reset({});
    },
  });

  const handleGenerateExpiryReport = (reportFilter) => {
    const payload = {
      ...reportFilter,
      report_type: reportType,
      end_date: adjustToUTC(reportFilter.end_date),
      warehouse_id: handleMultiValuesPayload(reportFilter.warehouse_id),
    };

    generateSKUReportMutation(payload);
  };

  return (
    <PageDrawer
      title={t(isExpiry ? 'generateExpiryReport' : 'generateNearExpiryReport')}
      isOpen={isOpen}
      onClose={onClose}
      formId={formId}
      isLoading={isGenerateSKUReportLoading}
    >
      <form
        onSubmit={handleSubmit(handleGenerateExpiryReport)}
        id={formId}
        noValidate
      >
        <Stack spacing={5}>
          <Controller
            control={control}
            name="warehouse_id"
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('warehouse')}
                placeholder={t('warehouse')}
                options={[translatedSelectAllOption, ...warehouseOptions]}
                onChange={(values, actionMeta) => {
                  handleMultiSelectChange({
                    values,
                    actionMeta,
                    onChange,
                  });
                }}
                value={value}
                error={errors.warehouse_id}
                isMulti
              />
            )}
          />

          <DatePicker
            name="end_date"
            label={t('expiresBy')}
            register={register}
            setValue={setValue}
            errors={errors}
            formValue={watchExpiresBy}
            control={control}
            size="lg"
          />

          <FormControl display="flex" gap={2}>
            <Switch
              size="lg"
              colorScheme="primary"
              {...register('include_expired')}
            />
            <FormLabel>{t('addItemsPastTheirExpiryDate')}</FormLabel>
          </FormControl>

          <RadioCardGroup
            name="export_format"
            label={t('exportFormat')}
            options={exportFormatOptions}
            register={register}
            errors={errors}
            defaultValue={exportFormatOptions[0].value}
            columns={2}
          />
        </Stack>
      </form>
    </PageDrawer>
  );
};

export default ExpiryReportDrawer;
