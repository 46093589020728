import React from 'react';
import { useTranslation } from 'react-i18next';
import { adjustToUTC } from 'utils/date';
import { generateReport } from 'api/Dashboard/reports';
import { useMutation } from 'react-query';
import PageDrawer from 'components/Dashboard/PageDrawer';
import ReportForm from './ReportForm';
import { handleMultiValuesPayload } from 'utils/reports';

const ReportDrawer = ({ isOpen, onSuccess, onClose, reportType, title }) => {
  const { t } = useTranslation();

  const { mutate: generateReportMutation, isLoading: isGenerateReportLoading } =
    useMutation(generateReport, {
      onSuccess: () => {
        onClose();
        onSuccess();
      },
    });

  const handleGenerateReport = (reportFilter) => {
    const payload = {
      ...reportFilter,
      report_type: reportType,
      end_date: adjustToUTC(reportFilter.end_date),
      start_date: adjustToUTC(reportFilter.start_date),
      warehouse_id: handleMultiValuesPayload(reportFilter.warehouse_id),
      status: handleMultiValuesPayload(reportFilter.status),
      delivery_option: handleMultiValuesPayload(reportFilter.delivery_option),
      movement_type: handleMultiValuesPayload(reportFilter.movement_type),
    };

    generateReportMutation(payload);
  };

  const formId = `${reportType}ReportForm`;

  return (
    <PageDrawer
      title={t(title)}
      isOpen={isOpen}
      onClose={onClose}
      formId={formId}
      isLoading={isGenerateReportLoading}
    >
      <ReportForm
        onSubmit={handleGenerateReport}
        reportType={reportType}
        id={formId}
      />
    </PageDrawer>
  );
};

export default ReportDrawer;
