import { useTranslation } from 'react-i18next';

import passwordSuccess from 'assets/images/verify_email_success.svg';
import Button from 'components/shared/Button';
import RegistrationHeader from 'components/shared/Registration/RegistrationHeader';
import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import { useNavigate } from 'react-router-dom';

const VerifyOTPSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <RegistrationLayout
      content={
        <div className="text-center">
          <img
            className="h-50 w-50 mb-4 inline"
            src={passwordSuccess}
            alt="Password successfully changed"
          />
          <RegistrationHeader
            title={t('yourEmailHasBeenVerified')}
            subtitle={t('verifyOTPSuccessSubtitle')}
          />
          <Button
            label={t('backToLogin')}
            className="mt-4"
            onClick={() => {
              navigate('/sign-in');
            }}
          />
        </div>
      }
    />
  );
};

export default VerifyOTPSuccess;
