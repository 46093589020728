import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import useLanguage from 'hooks/useLanguage';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Chart = ({ data, options: { hideAxes, hideLegend } = {} }) => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  const fontFamily = isRTL ? 'Almarai' : 'Inter Variable';

  return (
    <Bar
      style={{
        width: '100%',
        height: '100%',
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: !hideAxes,
            reverse: isRTL,
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
            ticks: {
              font: {
                family: fontFamily,
              },
            },
          },
          y: {
            display: !hideAxes,
            position: isRTL ? 'right' : 'left',
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
            display: !hideLegend,
            rtl: isRTL,
            labels: {
              font: {
                family: fontFamily,
              },
              usePointStyle: true,
              pointStyle: 'rectRounded',
            },
          },
          tooltip: {
            displayColors: false,
            backgroundColor: '#1A202C',
            yAlign: 'bottom',
            bodyFont: {
              family: fontFamily,
            },
            rtl: isRTL,
            callbacks: {
              title: () => null,
              label: (item) => {
                return `${item.formattedValue} ${item.dataset.label ?? ''}`;
              },
            },
          },
        },
      }}
      data={data}
    />
  );
};

export default Chart;
