export const openPDF = (data, fileName = 'document') => {
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);

  const downloadLink = document.createElement('a');
  downloadLink.href = fileURL;
  downloadLink.target = '_blank'; // Open in a new tab/window
  downloadLink.download = `${fileName}.pdf`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
