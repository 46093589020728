import Intercom, { update } from '@intercom/messenger-js-sdk';
import { getLocalStorageLanguage } from './i18n';

export const getAlignment = () => {
  const language = getLocalStorageLanguage();
  const isAR = language === 'ar';

  return isAR ? 'left' : 'right';
};

export const initIntercom = () => {
  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    alignment: getAlignment(),
    language_override: getLocalStorageLanguage(),
  });
};

export const updateIntercom = ({
  id,
  name,
  email,
  phone,
  organization_id,
  organization_name,
  phone_number,
  organization_type,
  user_role,
  verified,
  has_active_quote,
  org_created_at,
} = {}) => {
  if (!window.Intercom) {
    return;
  }

  update({
    user_id: id,
    name,
    email,
    phone,
    organization_id,
    organization_name,
    phone_number,
    organization_type,
    user_role,
    verified,
    alignment: getAlignment(),
    language_override: getLocalStorageLanguage(),
    has_active_quote,
    org_created_at,
  });
};
