import api from 'utils/axios';

export const uploadAttachment = async (attachment) => {
  try {
    const response = await api.post('/merchants/attachments', attachment);
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};

export const deleteAttachment = async (id) => {
  try {
    const response = await api.delete(`/merchants/attachments/${id}`);
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};
