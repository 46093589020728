import StatusTag from './StatusTag';

const CounterTag = ({ colorScheme, count, width }) => (
  <StatusTag
    fontSize="sm"
    borderRadius="4px"
    colorScheme={colorScheme}
    color={`${colorScheme}.800`}
    fontWeight="500"
    width={width}
  >
    {count}
  </StatusTag>
);

export default CounterTag;
