import { Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';

const isProduction = process.env.REACT_APP_ENV === 'production';

const ReCaptchaField = ({ control }) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  if (!isProduction) return null;

  const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <Controller
      name="captchaToken"
      control={control}
      rules={{ required: 'thisFieldIsRequired' }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <div className="flex flex-col items-start">
          <div className={`${error ? 'border border-red-500 rounded' : ''}`}>
            <ReCAPTCHA sitekey={sitekey} onChange={onChange} hl={language} />
          </div>

          {error && (
            <span className="mt-1 text-sm text-red-700">
              {t(error.message)}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default ReCaptchaField;
