import api from 'utils/axios';

export const addComment = async (comment) => {
  try {
    const response = await api.post('/merchants/comments', comment);
    const { data } = response;

    return { data };
  } catch (error) {
    throw error;
  }
};
