import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Table,
  TableContainer,
  Tbody,
  Th as ChakraTh,
  Td as ChakraTd,
  Thead,
  Tr,
  Text,
  Button,
  Box,
  Flex,
  Spinner,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import StepperInput from 'components/shared/Inputs/StepperInput';
import { useTranslation } from 'react-i18next';
import useLanguage from 'hooks/useLanguage';
import Tooltip from 'components/shared/Tooltip';
import CollapsibleCard from 'components/Dashboard/CollapsibleCard';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import Highlighted from 'components/shared/Highlighted';
import Select from 'components/Dashboard/Select';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import styled from '@emotion/styled';

const StyledTrashIcon = styled(TrashIcon)`
  path {
    fill: #e53e3e;
  }
`;

const Th = ({ children, ...props }) => (
  <ChakraTh {...props} color="gray.500" bgColor="gray.50" paddingInline={2}>
    {children}
  </ChakraTh>
);

const Td = ({ children, ...props }) => (
  <ChakraTd {...props} paddingInline={2}>
    {children}
  </ChakraTd>
);

const getPriceRangeByValue = (priceRanges, value) => {
  return priceRanges.find(
    (priceRange) => value >= priceRange.min && value <= (priceRange.max ?? 9999)
  );
};

const calculatePriceRange = ({
  apply_to,
  price_ranges,
  price_per_unit_cents,
  getValues,
  ignorePriceRange,
}) => {
  const inputName =
    apply_to[0] === 'pallet' ? 'number_of_pallets' : 'number_of_sqm';

  const value = +getValues(inputName) || 0;

  const currentPriceRange = ignorePriceRange
    ? { price_in_cents: price_per_unit_cents }
    : getPriceRangeByValue(price_ranges, value);

  return currentPriceRange;
};

const ServiceGroupRows = ({
  services,
  getValues,
  ignorePriceRange,
  remove,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return services.map((service, index) => {
    const {
      name,
      ar_name,
      id,
      price_ranges,
      unit_type,
      ar_unit_type,
      description,
      description_ar,
      price_per_unit_cents,
      apply_to,
    } = service;

    const currentPriceRange = calculatePriceRange({
      apply_to,
      price_ranges,
      price_per_unit_cents,
      getValues,
      ignorePriceRange,
    });

    const price = (currentPriceRange?.price_in_cents / 100)?.toLocaleString(
      'en'
    );

    const localUnitType = language === 'ar' ? ar_unit_type : unit_type;

    const priceLabel = `${price} ${t('SAR')} ${
      localUnitType ? `/ ${localUnitType}` : ''
    }`;

    const localName = language === 'ar' ? ar_name : name;

    const localDescription = language === 'ar' ? description_ar : description;

    return (
      <Highlighted as={Tr} value={price} key={id}>
        <Td width="full">
          {localName}
          {localDescription && (
            <Box display="inline-block" marginInlineStart="2">
              <Tooltip
                label={localDescription}
                icon={<InfoIcon boxSize="14px" color="#5B6B93" />}
                placement="top"
              />
            </Box>
          )}
        </Td>
        <Td fontWeight="500">
          {currentPriceRange?.included ? (
            <Text color="primary.500">{t('Included')}</Text>
          ) : (
            priceLabel
          )}
        </Td>
        {remove && (
          <Td>
            <Button
              variant="ghost"
              colorScheme="red"
              size="sm"
              onClick={() => remove(index)}
              leftIcon={<StyledTrashIcon />}
            >
              {t('remove')}
            </Button>
          </Td>
        )}
      </Highlighted>
    );
  });
};

const categories = {
  storage: {
    en: 'Storage',
    ar: 'التخزين',
  },
  movement_services: {
    en: 'Movement Services',
    ar: 'خدمات الحركة',
  },
  overtime: {
    en: 'Overtime',
    ar: 'العمل الإضافي',
  },
  pallet_services: {
    en: 'Pallet Services',
    ar: 'خدمات الطبلیات',
  },
  e_commerce: {
    en: 'E-Commerce',
    ar: 'التجارة الإلكترونية',
  },
  other: {
    en: 'Other',
    ar: 'أخرى',
  },
};

const groupByCategory = (services) => {
  const groupedServices = Object.keys(categories).reduce((acc, key) => {
    const categoryName = categories[key].en;
    const groupServices = services.filter(
      (service) => service.category.en === categoryName
    );

    if (groupServices.length) {
      acc[key] = services.filter(
        (service) => service.category.en === categoryName
      );
    }

    return acc;
  }, {});

  if (!groupedServices.other) {
    groupedServices.other = [];
  }

  return groupedServices;
};

const filterByName = (sourceArray, filterArray) => {
  const filterNames = filterArray.map((item) => item.name);

  return sourceArray.filter((item) => !filterNames.includes(item.name));
};

const MIN_QUANTITY = 10;
const MAX_QUANTITY = 99999;

const quantityInRangeValidation = (valueAsNumber) =>
  valueAsNumber === 0 || valueAsNumber >= 10;

const Quote = ({
  defaultValues,
  services,
  allOtherServices = [],
  isLoading,
  disabled,
  ignorePriceRange,
  noScroll,
  onApproveQuote,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isAR = language === 'ar';

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    getValues,
    setValue,
    trigger,
    control,
    watch,
  } = useForm({
    defaultValues,
  });

  const {
    fields: otherFields,
    append,
    replace,
    remove,
  } = useFieldArray({
    control,
    name: 'quote_line_items_attributes',
  });

  const watchPallets = watch('number_of_pallets');
  const watchSqM = watch('number_of_sqm');

  const watchPalletsAsNumber = +watchPallets;
  const watchSqMAsNumber = +watchSqM;

  const filteredServices = useMemo(
    () =>
      (services ?? []).filter((service) => {
        const isPallet = service.apply_to.some((type) => type === 'pallet');
        const isSqM = service.apply_to.some((type) => type === 'sqm');

        if (isPallet && isSqM) {
          return true;
        }

        if (watchPalletsAsNumber === 0 && isPallet) {
          return false;
        }

        if (watchSqMAsNumber === 0 && isSqM) {
          return false;
        }

        return true;
      }),
    [services, watchPalletsAsNumber, watchSqMAsNumber]
  );

  const groupedServices = useMemo(
    () => groupByCategory(filteredServices),
    [filteredServices]
  );

  const otherServices = useMemo(() => groupedServices.other, [groupedServices]);

  const otherServicesOptions = useMemo(() => {
    return filterByName(allOtherServices, otherFields).map(({ name, id }) => ({
      label: name,
      value: id,
    }));
  }, [allOtherServices, otherFields]);

  useEffect(() => {
    if (!otherServices?.length) {
      return;
    }

    replace(otherServices);
  }, [replace, otherServices]);

  useEffect(() => {
    trigger('number_of_sqm');
  }, [watchPallets, trigger]);

  useEffect(() => {
    trigger('number_of_pallets');
  }, [watchSqM, trigger]);

  if (isLoading) {
    return <Spinner color="primary.500" size="lg" />;
  }

  if (!services) {
    return null;
  }

  const handleQuoteSubmit = (data) => {
    const map = new Map();

    services.forEach((item) => {
      map.set(item.name, { ...item });
    });

    otherServices.forEach((item) => {
      const isDeleted =
        otherFields.findIndex((otherField) => otherField.name === item.name) ===
        -1;

      if (!map.has(item.name) || isDeleted) {
        map.set(item.name, { ...item, _destroy: 1 });
      }
    });

    const newLineItems = Array.from(map.values());

    const newLineItemsPayload = newLineItems.map((newLineItem) => {
      const {
        id,
        lineItemId,
        price_per_unit_cents,
        price_ranges,
        apply_to,
        _destroy,
      } = newLineItem;

      const currentPriceRange = calculatePriceRange({
        apply_to,
        price_ranges,
        price_per_unit_cents,
        getValues,
        ignorePriceRange,
      });

      return {
        id: lineItemId,
        warehouse_service_id: lineItemId ? undefined : id,
        price_per_unit_cents: currentPriceRange?.price_in_cents,
        _destroy,
      };
    });

    onSubmit({ ...data, quote_line_items_attributes: newLineItemsPayload });
  };

  const minimumValidationMessage = `${t('minIs')} ${MIN_QUANTITY}`;

  return (
    <>
      <form
        id="quoteForm"
        onSubmit={handleSubmit(handleQuoteSubmit)}
        noValidate
      >
        <HStack
          as="fieldset"
          disabled={disabled ? 'disabled' : ''}
          paddingTop={{ md: 2 }}
          paddingBottom={6}
          spacing={{ base: 2, md: 10 }}
          flexWrap="wrap"
          alignItems="flex-start"
        >
          <StepperInput
            name="number_of_pallets"
            required="thisFieldIsRequired"
            register={register}
            label={t('numberOfPallets')}
            labelPosition="left"
            defaultValue={defaultValues.number_of_pallets}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
            minErrorMessage="minIs"
            min={watchSqMAsNumber >= MIN_QUANTITY ? 0 : MIN_QUANTITY}
            max={MAX_QUANTITY}
            isDisabled={disabled}
            validate={(value) => {
              const valueAsNumber = +value;

              if (watchSqMAsNumber >= MIN_QUANTITY) {
                return (
                  quantityInRangeValidation(valueAsNumber) ||
                  minimumValidationMessage
                );
              }

              if (valueAsNumber > 0) {
                return (
                  valueAsNumber >= MIN_QUANTITY || minimumValidationMessage
                );
              }
            }}
          />

          <StepperInput
            name="number_of_sqm"
            required="thisFieldIsRequired"
            register={register}
            label={t('SqM')}
            labelPosition="left"
            defaultValue={defaultValues.number_of_sqm}
            setValue={setValue}
            trigger={trigger}
            errors={errors}
            minErrorMessage="minIs"
            min={watchPalletsAsNumber >= MIN_QUANTITY ? 0 : MIN_QUANTITY}
            max={MAX_QUANTITY}
            isDisabled={disabled}
            validate={(value) => {
              const valueAsNumber = +value;

              if (watchPalletsAsNumber >= MIN_QUANTITY) {
                return (
                  quantityInRangeValidation(valueAsNumber) ||
                  minimumValidationMessage
                );
              }

              if (valueAsNumber > 0) {
                return (
                  valueAsNumber >= MIN_QUANTITY || minimumValidationMessage
                );
              }
            }}
          />
        </HStack>
      </form>

      <Stack spacing={4}>
        {Object.keys(groupedServices).map((key) => {
          const serviceGroup = groupedServices[key];

          const isOther = key === 'other';

          return (
            <CollapsibleCard
              key={key}
              title={
                <SectionTitle
                  title={categories[key][isAR ? 'ar' : 'en']}
                  hideDivider
                />
              }
              body={
                <>
                  <TableContainer
                    maxHeight={noScroll ? 'auto' : '370'}
                    overflowY="auto"
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>{t('service')}</Th>
                          <Th>{t('price')}</Th>
                          {isOther && <Th></Th>}
                        </Tr>
                      </Thead>
                      <Tbody>
                        <ServiceGroupRows
                          services={isOther ? otherFields : serviceGroup}
                          getValues={getValues}
                          ignorePriceRange={ignorePriceRange}
                          remove={isOther ? remove : undefined}
                        />
                      </Tbody>
                    </Table>
                  </TableContainer>

                  {isOther && (
                    <Box maxWidth="350px" mt={6}>
                      <Select
                        placeholder={t('selectNewService')}
                        options={otherServicesOptions}
                        isDisabled={disabled}
                        onChange={({ value }) => {
                          const selectedService = allOtherServices.find(
                            (service) => service.id === value
                          );

                          append(selectedService);
                        }}
                      />
                    </Box>
                  )}
                </>
              }
              isOpen
            />
          );
        })}
      </Stack>

      <Flex width="full" justifyContent="flex-end" marginTop={6} gap={2}>
        {onCancel && (
          <Button
            colorScheme="red"
            variant="outline"
            bg="white"
            size="lg"
            isDisabled={disabled}
            onClick={onCancel}
          >
            {t('cancel')}
          </Button>
        )}

        <Button
          colorScheme="primary"
          variant="outline"
          bg="white"
          size="lg"
          onClick={() => onApproveQuote(getValues())}
          isDisabled={disabled}
        >
          {t('approveQuote')}
        </Button>
        <Button
          colorScheme="primary"
          size="lg"
          type="submit"
          form="quoteForm"
          isDisabled={disabled || !isDirty}
        >
          {t('saveChanges')}
        </Button>
      </Flex>
    </>
  );
};

export default Quote;
