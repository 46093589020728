import { Flex, Tag, Text } from '@chakra-ui/react';
import i18n from './i18n';

export const translatePickupTime = (timeOfPickup) => {
  if (!timeOfPickup) {
    return timeOfPickup;
  }

  const [startTime, endTime] = timeOfPickup.split(' - ');
  const startPeriod = startTime?.slice(-2);
  const endPeriod = endTime?.slice(-2);

  return `${startTime?.replace(
    startPeriod,
    i18n.t(startPeriod)
  )} - ${endTime?.replace(endPeriod, i18n.t(endPeriod))}`;
};

export const getPickupTimeSlotsOptions = (pickupTimeSlots) => {
  return pickupTimeSlots?.map(({ slot, urgent }, index) => ({
    label: (
      <Flex
        width="full"
        justifyContent="space-between"
        alignItems="center"
        key={slot + index}
        gap={1}
      >
        <Text key={slot + '-key'}>{translatePickupTime(slot)}</Text>
        {urgent && (
          <Tag
            variant="solid"
            colorScheme="pink"
            size="sm"
            key={slot + '-urgent'}
          >
            {i18n.t('urgent')}
          </Tag>
        )}
      </Flex>
    ),
    value: slot,
    urgent,
  }));
};
