import api from 'utils/axios';

export const updateOrganization = async (id, organization) => {
  try {
    const response = await api.put(`/organizations/${id}`, { organization });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const sendPayment = async (id) => {
  try {
    const response = await api.post(
      `/organizations/${id}/generate_invoice_payment_link`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};
