import { Container as ChakraContainer } from '@chakra-ui/react';

const Container = ({ children, ...rest }) => {
  return (
    <ChakraContainer maxW="container.xl" {...rest}>
      {children}
    </ChakraContainer>
  );
};

export default Container;
