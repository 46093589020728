import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import { ReactComponent as NotFoundIllustration } from 'assets/images/not-found-page-illustration.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" justifyContent="center" height="full">
      <Flex
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        maxWidth="520px"
        bg="white"
        borderRadius="3xl"
        padding={8}
      >
        <NotFoundIllustration />
        <Stack spacing={3}>
          <Text fontSize="2xl" fontWeight={600}>
            {t('pageNotFound')}
          </Text>
          <Text color="gray.500" fontWeight={500}>
            {t('oopsPageNotExist')}
          </Text>
        </Stack>
        <Button
          colorScheme="primary"
          size="lg"
          width="full"
          leftIcon={<ArrowBack />}
          as={Link}
          to="/"
          marginTop={6}
        >
          {t('backToHome')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default NotFound;
