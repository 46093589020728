import {
  confirmOTP as confirmOtpAPI,
  forgetPassword as forgetPasswordAPI,
  passwordlessLogin as passwordlessLoginAPI,
  refreshToken as refreshTokenAPI,
  resendOTP as resendOtpAPI,
  setPassword as setPasswordAPI,
  signIn as signInAPI,
  signOut as signOutAPI,
  signUp as signUpAPI,
} from 'api/auth';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { deleteLocalStorage, setLocalStorage } from 'utils/localStorage';
import { deleteToken, getToken, saveToken } from 'utils/token';
import { shutdown } from '@intercom/messenger-js-sdk';
import { updateIntercom } from 'utils/intercom';

const saveTokenFromHeaders = (headers) => {
  saveToken({
    newToken: headers['access-token'],
    expireAt: headers['expire-at'],
    refreshToken: headers['refresh-token'],
  });
};

const useAuth = () => {
  const navigate = useNavigate();
  const { token } = getToken();

  const {
    mutate: signUp,
    isLoading: isSignUpLoading,
    error: signUpError,
  } = useMutation(signUpAPI, {
    onSuccess: ({ headers, payload, data }) => {
      saveTokenFromHeaders(headers);
      setLocalStorage('user', data.data);
    },
  });

  const {
    mutate: signIn,
    isLoading: isSignInLoading,
    error: signInError,
  } = useMutation(signInAPI, {
    onSuccess: ({ data, headers }) => {
      saveTokenFromHeaders(headers);
      setLocalStorage('user', data.data);
    },
  });

  const {
    mutate: passwordlessLogin,
    isLoading: isPasswordlessLoginLoading,
    error: passwordlessLoginError,
  } = useMutation(passwordlessLoginAPI, {
    onSuccess: ({ data, headers }) => {
      saveTokenFromHeaders(headers);
      setLocalStorage('user', data.data);
    },
  });

  const { mutate: signOut } = useMutation(signOutAPI, {
    onSuccess: () => {
      deleteToken();
      deleteLocalStorage('user');
      // logout intercom user
      shutdown();
      updateIntercom();

      navigate('/sign-in');
    },
  });

  const { mutate: refreshToken } = useMutation(refreshTokenAPI, {
    onSuccess: ({ headers }) => {
      saveTokenFromHeaders(headers);
    },
    onError: (error) => {
      console.error('Refresh token failed with error', error);
    },
  });

  const {
    mutate: forgetPassword,
    isLoading: isForgetPasswordLoading,
    error: forgetPasswordError,
  } = useMutation(forgetPasswordAPI);

  const { mutate: setPassword, isLoading: isSetPasswordLoading } =
    useMutation(setPasswordAPI);

  const { refetch: resendOTP } = useQuery('resend-otp', resendOtpAPI, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    mutate: confirmOTP,
    isLoading: isConfirmOtpLoading,
    error: confirmOtpError,
  } = useMutation(confirmOtpAPI);

  return {
    token,
    signOut,
    refreshToken,
    resendOTP,

    signUp,
    isSignUpLoading,
    signUpError,

    signIn,
    isSignInLoading,
    signInError,

    passwordlessLogin,
    isPasswordlessLoginLoading,
    passwordlessLoginError,

    forgetPassword,
    isForgetPasswordLoading,
    forgetPasswordError,

    setPassword,
    isSetPasswordLoading,

    confirmOTP,
    isConfirmOtpLoading,
    confirmOtpError,
  };
};

export default useAuth;
