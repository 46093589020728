import { useState } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';

const Highlighted = ({ value, children, as, ...rest }) => {
  const [highlight, setHighlight] = useState(false);
  const highlightColor = useColorModeValue('yellow.100', 'yellow.500');

  useDidUpdateEffect(() => {
    setHighlight(true);
    const timer = setTimeout(() => setHighlight(false), 1000);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <Box
      as={as}
      bg={highlight ? highlightColor : 'transparent'}
      transition="background-color 0.5s ease"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Highlighted;
