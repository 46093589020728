import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import ar from 'react-phone-number-input/locale/ar';
import en from 'react-phone-number-input/locale/en';
import { CountrySelectWithIcon } from './CountrySelect';
import useLanguage from 'hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

const ContainerComponent = ({ children }) => (
  <div className="relative mt-2 rounded-md shadow-sm">{children}</div>
);

function PhoneNumber({ name, id, label, required, register, errors, control }) {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const error = errors[name];

  return (
    <div>
      <label htmlFor={id} className="block font-medium leading-6 text-gray-900">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) =>
            isValidPhoneNumber(value) ? true : t('validation.phoneNumberType'),
          required,
        }}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            value={value}
            onChange={onChange}
            defaultCountry="SA"
            numberInputProps={{
              className: `block w-full rounded-md border-0 py-2 ltr:pl-24 rtl:pr-24 bg-gray-50 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6 ${
                error
                  ? 'ring-red-700 bg-red-50 text-red-700 focus:ring-red-700'
                  : ''
              }`,
              placeholder: '148479797',
            }}
            containerComponent={ContainerComponent}
            countrySelectComponent={CountrySelectWithIcon}
            labels={language === 'ar' ? ar : en}
            id={id}
          />
        )}
      />
      {error && <p className="text-red-70 text-sm mt-1">{t(error.message)}</p>}
    </div>
  );
}

export default PhoneNumber;
