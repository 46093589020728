import { Box, IconButton, Image } from '@chakra-ui/react';
import useLanguage from 'hooks/useLanguage';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { FileInput } from './AttachmentUpload';

const StyledTrashIcon = styled(TrashIcon)`
  path {
    fill: #e53e3e;
  }
`;

const ImageInput = ({ watchImage, setValue, control }) => {
  const { language } = useLanguage();
  const isRTL = language === 'ar';

  return (
    <>
      {watchImage && !watchImage._destroy && (
        <Box position="relative" width="fit-content">
          <Image
            borderRadius="xl"
            src={watchImage?.base64}
            mb={4}
            maxH="150px"
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: '-15px',
              right: isRTL ? 'auto' : '-10px',
              left: isRTL ? '-10px' : 'auto',
            }}
            variant="outline"
            colorScheme="red"
            background="red.50"
            size="xs"
            rounded="full"
            onClick={() => setValue('image', { _destroy: true })}
            icon={<StyledTrashIcon width="18px" height="18px" />}
          />
        </Box>
      )}
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange, value, name } }) => (
          <FileInput
            accept="image/jpg, image/jpeg, image/png"
            name="image"
            multiple={false}
            onChange={(files) => onChange(files[0])}
          />
        )}
      />
    </>
  );
};

export default ImageInput;
