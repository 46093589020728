import { memo, useEffect, useState } from 'react';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';

const DebouncedNumberInput = memo(
  ({ name, onChange, defaultValue, isDisabled, placeholder, min, max }) => {
    const [value, setValue] = useState(defaultValue);
    const [debouncedValue, setDebouncedValue] = useState(defaultValue);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }, [value]);

    useDidUpdateEffect(() => {
      onChange(debouncedValue);
    }, [debouncedValue]);

    return (
      <NumberInput
        min={min}
        max={max}
        size="lg"
        borderRadius="2xl"
        onChange={(_, value) => {
          if (isNaN(value)) {
            setValue(0);
            return;
          }

          setValue(value);
        }}
        value={value}
        defaultValue={defaultValue}
        clampValueOnBlur={false}
        isDisabled={isDisabled}
      >
        <NumberInputField placeholder={placeholder} name={name} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    );
  }
);

export default DebouncedNumberInput;
