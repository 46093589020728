import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PaymentSuccessAlert = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Alert status="success" alignItems="start" justifyContent="space-between">
      <Flex>
        <AlertIcon />

        <Flex gap={1} flexDirection="column">
          <AlertTitle>{t('paymentSucceeded')}</AlertTitle>
          <AlertDescription>
            {t('thankYouYourPaymentWasSuccessful')}
          </AlertDescription>
        </Flex>
      </Flex>

      <CloseButton onClick={onClose} />
    </Alert>
  );
};

export default PaymentSuccessAlert;
