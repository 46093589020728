import { Card, CardHeader, CardBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SectionTitle from 'components/Layouts/DashboardLayout/SectionTitle';
import OrderStatusDetails from '../OrderStatusDetails';

const outboundDetailsStatuses = [
  { value: 'created', label: 'created' },
  { value: 'reserved', label: 'orderStatus.reserved' },
  { value: 'picking', label: 'orderStatus.picking' },
  { value: 'ready_for_loading', label: 'orderStatus.ready_for_loading' },
  { value: 'loading', label: 'orderStatus.loading' },
  { value: 'in_transit', label: 'in_transit' },
  { value: 'completed', label: 'completed' },
];

const StatusSection = ({ activeStatus }) => {
  const { t } = useTranslation();

  return (
    <Card size="lg">
      <CardHeader>
        <SectionTitle title={t('outboundStatus')} />
      </CardHeader>
      <CardBody>
        <OrderStatusDetails
          statuses={outboundDetailsStatuses}
          activeStatus={activeStatus}
        />
      </CardBody>
    </Card>
  );
};
export default StatusSection;
